import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { ContentWrapper } from '@styles/globalStyles';
import { ChartContainer } from '@containers/Users/styles';
import Filters from '@components/Filters';
import {
  counters,
  defaultSearchQuery,
  fields,
  filtersFunc,
} from '@containers/Users/constants';
import BarChart from '@components/BarChart';
import useBreakpoint from '@root/hooks/useBreakpoint';
import StatsIcons from '@components/StatsIcons';
import List from '@components/List';
import Preloader from '@components/Preloader';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';

const Users = ({
  usersStore: {
    fetchEvents,
    fetchBarChart,
    fetchCounters,
    countersValues,
    events,
    chartData,
    eventsCount,
    isLoadingEvents,
    isLoadingCounters,
    isLoadingBarChart,
    fetchUserEmails,
    fetchSubscriptions,
    fetchMediums,
    fetchEventTypes,
    eventTypes,
    isLoadingEventTypes,
    mediums,
    isLoadingMediums,
  },
}) => {
  const [page, setPage] = useState(1);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [searchQuery, setSearchQuery] =
    useCustomSearchParams(defaultSearchQuery);

  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  const search = async (newPage = 1, clear = false) => {
    const query = clear ? defaultSearchQuery : searchQuery;
    if (clear) {
      setSearchQuery(query);
    }
    setPage(newPage);
    const extendedQuery = {
      subscriptions: query.subscription,
      ...query,
    };
    await fetchEvents({
      page: newPage,
      searchQuery: extendedQuery,
    });
    await fetchBarChart({
      searchQuery: extendedQuery,
    });
    await fetchCounters({
      searchQuery: extendedQuery,
    });
  };

  useEffect(() => {
    search(1);
    fetchMediums();
    fetchEventTypes();
  }, []);

  const filters = filtersFunc(
    fetchUserEmails,
    fetchSubscriptions,
    eventTypes,
    isLoadingEventTypes,
    mediums,
    isLoadingMediums,
  );

  return (
    <ContentWrapper>
      {isLoadingBarChart || isLoadingEvents || isLoadingCounters ? (
        <Preloader overlay />
      ) : null}
      <Filters
        clear={() => search(1, true)}
        search={search}
        searchQuery={searchQuery}
        setSearchQuery={(valueObj) =>
          setSearchQuery({ ...searchQuery, ...valueObj })
        }
        isOpen={isFiltersOpen}
        setIsOpen={setIsFiltersOpen}
        filters={filters}
        containerClass="no-margin"
      />
      <ChartContainer>
        <BarChart
          isMobile={isMobile}
          dataKey="date"
          keys={[{ name: 'total' }]}
          yAxisLabel="Number of events"
          data={chartData}
          dateFormatTooltip="YYYY-MM-DD"
        />
      </ChartContainer>
      <StatsIcons counters={counters} data={countersValues} />
      <List
        isMobile={isMobile}
        data={events}
        fields={fields}
        currentPage={page}
        dataCount={eventsCount}
        pageSize={20}
        onPageChange={search}
        listTopOffset={610}
      />
    </ContentWrapper>
  );
};

export default inject('usersStore')(observer(Users));
