import { makeAutoObservable } from 'mobx';
import API from '@app/api';
import apiRoutes from '@app/apiRoutes';
import { log, TYPE } from '@utils/logger';

export class HealthCheckForSourcesStore {
  isLoadingTable = false;

  tableData = [];

  constructor() {
    makeAutoObservable(this);
  }

  fetchTable = async () => {
    this.isLoadingTable = true;

    try {
      const response = await API.get(apiRoutes.healthCheckForSources.index);

      this.tableData = response.data.health_check_for_sources;
    } catch (e) {
      log(e.message, e, TYPE.ERROR);
      this.tableData = [];
    } finally {
      this.isLoadingTable = false;
    }
  };

  clearTableData = () => {
    this.tableData = [];
  };
}

export default new HealthCheckForSourcesStore();
