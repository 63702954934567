import styled from '@emotion/styled';

export const TabsList = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 30px;
`;
export const StyledTab = styled.div`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.font.default};
  padding: 10px 0;
  width: ${({ tabWidth }) => tabWidth}%;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.icons.hover};
  }

  &.selected {
    border-bottom: 1px solid ${({ theme }) => theme.font.default};
  }
`;
