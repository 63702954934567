import React from 'react';

import {
  Archive,
  FileLike,
  LayoutDashboard,
  List,
  Logout,
  Users,
  Mail,
  ChartInfographic,
  GridDots,
  Affiliate,
  UserCheck,
  Urgent,
  Folder,
  News,
} from 'tabler-icons-react';
import DesktopSideBar from '@components/SideBar/components/DesktopSideBar';
import useBreakpoint from '@root/hooks/useBreakpoint';
import MobileSideBar from '@components/SideBar/components/MobileSideBar';
import routes from '@routes';
import DarkModeSwitch from '@components/DarkModeSwitch';

const MENU_ITEMS = [
  {
    label: 'Dashboard',
    key: 'dashboard',
    path: routes.dashboard,
    icon: <LayoutDashboard strokeWidth={1} size={32} color="white" />,
    // submenuItemsKey: 'myNews', use to create toggable list under menu item
  },
  {
    label: 'Users',
    key: 'users',
    path: routes.users,
    icon: <Users color="white" strokeWidth={1} size={32} />,
  },
  {
    label: 'Emails',
    key: 'emails',
    path: routes.emails,
    icon: <Mail strokeWidth={1} size={32} color="white" />,
  },
  {
    label: 'Sentiments',
    key: 'Sentiments',
    path: routes.sentiments,
    icon: <FileLike color="white" strokeWidth={1} size={32} />,
  },
  {
    label: 'Archived articles',
    key: 'Archived articles',
    path: routes.archivedArticles,
    icon: <Archive color="white" strokeWidth={1} size={32} />,
  },
  {
    label: 'Reports',
    key: 'reports',
    submenuItemsKey: 'reports',
    icon: <List strokeWidth={1} size={32} color="white" />,
  },
  {
    label: 'Article aggregates',
    key: 'articleAggregates',
    path: routes.articleAggregates,
    icon: <Folder strokeWidth={1} size={32} color="white" />,
  },
  {
    label: 'Article sources matrix',
    key: 'articlesSourcesMatrix',
    path: routes.articleSourcesMatrix,
    icon: <GridDots strokeWidth={1} size={32} color="white" />,
  },
  {
    label: 'Newspapers',
    key: 'newspapers',
    path: routes.newspapers,
    icon: <News strokeWidth={1} size={32} color="white" />,
  },
  {
    label: 'Articles and clicks',
    key: 'articlesAndClicks',
    path: routes.articlesAndClicks,
    icon: <ChartInfographic strokeWidth={1} size={32} color="white" />,
  },
  {
    label: 'Companies and peers',
    key: 'companiesRelations',
    path: routes.companiesRelations,
    icon: <Affiliate strokeWidth={1} size={32} color="white" />,
  },
  {
    label: 'Statistics for analysts',
    key: 'analystsStatistics',
    path: routes.analystsStatistics,
    icon: <UserCheck strokeWidth={1} size={32} color="white" />,
  },
  {
    label: 'Alerts statistics',
    key: 'alertsStatistics',
    path: routes.alertsStatistics,
    icon: <Urgent strokeWidth={1} size={32} color="white" />,
  },
  {
    label: 'Logout',
    key: 'logout',
    path: routes.auth.logout,
    icon: <Logout strokeWidth={1} size={32} color="white" />,
  },
  {
    label: 'Dark Mode',
    key: 'dark-mode',
    icon: <DarkModeSwitch />,
  },
];

const SideBar = ({ mobileLabel }) => {
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  if (isMobile) {
    return <MobileSideBar mobileLabel={mobileLabel} menuItems={MENU_ITEMS} />;
  }
  return <DesktopSideBar menuItems={MENU_ITEMS} />;
};

export default SideBar;
