import React, { useEffect, useMemo, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { ContentWrapper } from '@styles/globalStyles';
import Table from '@components/Table';
import Preloader from '@components/Preloader';
import Filters from '@components/Filters';
import {
  filtersFunc,
  tableColumnsFunc,
  defaultSearchQuery,
} from '@containers/Newspapers/constants';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';

const Newspapers = ({
  newspapersStore: {
    fetchTable,
    tableData,
    isLoadingTable,
    tableDataCount,
    isLoadingArticleSources,
    fetchArticleSources,
    articleSources,
    articleSourcesObj,
    excelData: rawExcelData,
    isLoadingExcel,
  },
}) => {
  const [page, setPage] = useState(0);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [searchQuery, setSearchQuery, getCleanSearchQuery] =
    useCustomSearchParams({
      order: 'creation_date_desc',
    });
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    handleChangePage(0);
    fetchTable({
      limit: rowsPerPage,
      page,
      searchQuery,
    });
    fetchArticleSources();
  }, []);

  const handleChangePage = async (newPage) => {
    setPage(newPage);
    await fetchTable({
      limit: rowsPerPage,
      page: newPage,
      searchQuery: getCleanSearchQuery(),
    });
  };

  const handleChangeRowsPerPage = async (value) => {
    setRowsPerPage(value);
    setPage(0);
    await fetchTable({
      limit: value,
      page: 0,
      searchQuery: getCleanSearchQuery(),
    });
  };

  const clear = async () => {
    setSearchQuery(defaultSearchQuery);
    setPage(0);
    await fetchTable({
      limit: rowsPerPage,
      page: 0,
      searchQuery: defaultSearchQuery,
    });
  };
  const filters = filtersFunc(articleSources, isLoadingArticleSources);
  const tableColumns = tableColumnsFunc(articleSourcesObj);

  const excelData = useMemo(
    () =>
      rawExcelData.map((item) => {
        const newItem = { ...item };
        tableColumns.forEach(({ id, format }) => {
          if (format) {
            newItem[id] = format(newItem[id]);
          }
        });
        return newItem;
      }),
    [rawExcelData],
  );

  return (
    <ContentWrapper>
      {isLoadingTable && <Preloader overlay />}
      <Filters
        clear={clear}
        search={() => handleChangePage(0)}
        searchQuery={searchQuery}
        setSearchQuery={(valueObj) =>
          setSearchQuery({ ...searchQuery, ...valueObj })
        }
        isOpen={isFiltersOpen}
        setIsOpen={setIsFiltersOpen}
        filters={filters}
        containerClass="no-margin"
      />
      <Table
        count={tableDataCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        columns={tableColumns}
        data={tableData}
        withExcel
        excelProps={{
          excelData,
          isLoading: isLoadingExcel,
          excelFileName: 'Newspapers',
          onExcelDownload: () => {
            fetchTable({
              searchQuery,
              excel: true,
            });
          },
        }}
      />
    </ContentWrapper>
  );
};

export default inject('newspapersStore')(observer(Newspapers));
