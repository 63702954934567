import React from 'react';
import { usePagination, DOTS } from '@root/hooks/usePagination';
import { PaginationContainer, PaginationItem } from '@components/List/styles';

const DOTS_CODE = '\u2026';
const Pagination = (props) => {
  const {
    onPageChange: parentOnPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    listTopOffset = 0,
  } = props;

  const onPageChange = (page) => {
    parentOnPageChange(page);
    window.scrollTo({ top: listTopOffset, behavior: 'smooth' });
  };

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  return (
    <PaginationContainer>
      <PaginationItem
        key="pagination-prev"
        role="button"
        onKeyDown={() => {}}
        className={currentPage === 1 ? 'disabled' : ''}
        onClick={onPrevious}
      >
        <div className="arrow left" />
      </PaginationItem>
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return (
            <PaginationItem key={`pagination-dots-${index}`} className="dots">
              {DOTS_CODE}
            </PaginationItem>
          );
        }

        return (
          <PaginationItem
            key={`pagination-page-${index}`}
            role="button"
            onKeyDown={() => {}}
            className={currentPage === pageNumber ? 'selected' : ''}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </PaginationItem>
        );
      })}
      <PaginationItem
        key="pagination-next"
        role="button"
        onKeyDown={() => {}}
        className={currentPage === lastPage ? 'disabled' : ''}
        onClick={onNext}
      >
        <div className="arrow right" />
      </PaginationItem>
    </PaginationContainer>
  );
};

export default Pagination;
