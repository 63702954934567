import React, { useEffect, useState } from 'react';
import {
  PieChart as Chart,
  Pie,
  Legend,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from 'recharts';
import { CustomLegend } from '@components/LineChart/components/Legend';
import PieChartTooltip from '@components/PieChartTooltip';

const PieChart = ({ data, keys, width = '100%' }) => {
  const [series, setSeries] = useState(data.map(({ name }) => name));

  useEffect(() => {
    setSeries(keys.map(({ name }) => name));
  }, [data]);

  const labels = {};
  const colors = {};
  keys.forEach((key) => {
    labels[key.key] = key.label;
    colors[key.key] = key.color;
  });

  return (
    <ResponsiveContainer width={width} height={330}>
      <Chart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          outerRadius={80}
          label
          dataKey="total"
          paddingAngle={1}
          minAngle={7}
        >
          {data.map((entry, index) => (
            <Cell key={`pie-chart-cell-${index}`} fill={colors[entry.name]} />
          ))}
        </Pie>
        <Legend
          content={
            <CustomLegend
              series={series}
              setSeries={setSeries}
              labels={labels}
            />
          }
        />
        <Tooltip
          wrapperStyle={{ zIndex: 9, outline: 'none' }}
          labels={labels}
          content={
            <PieChartTooltip chartData={data} colors={colors} labels={labels} />
          }
        />
      </Chart>
    </ResponsiveContainer>
  );
};

export default PieChart;
