import styled from '@emotion/styled';

export const CountersContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const Title = styled.div`
  text-align: center;
  width: 100%;
  font-size: ${({ theme }) => theme.defaults.fontSizeXXL}px;
  color: ${({ theme }) => theme.font.default};
  max-width: max-content;

  &.right-margin-auto {
    margin-right: auto;
  }

  &.margin-auto {
    margin-right: auto;
    margin-left: auto;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-evenly;
  align-items: center;
`;

export const ExportContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-start;
  align-items: center;
`;
