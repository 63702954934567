export const KEYS = {
  AUTH_TOKEN: 'token',
  EMAIL: 'email',
  DARKMODE: 'darkmode',
  APP_SIZE: 'appsize',
};

const keyPrefix = 'popcorn';

const prepareKey = (key) => `${keyPrefix}:${key}`;

export const setItem = (key, value) =>
  localStorage.setItem(prepareKey(key), value);

export const getItem = (key, value) =>
  localStorage.getItem(prepareKey(key), value);

export const removeItem = (key) => {
  localStorage.removeItem(prepareKey(key));
};

export const clearCache = () => {
  Object.keys(KEYS).forEach((key) => removeItem(key));
};
