import React, { useEffect, useRef, useState } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment/moment';
import Table from '@components/Table';
import Preloader from '@components/Preloader';
import ExportExcel from '@containers/ArticlesAndClicks/components/ExportExcel';
// eslint-disable-next-line import/no-cycle
import {
  tabsConstants,
  MONTH_COLLECTION,
  HALF_YEAR_COLLECTION,
  EXCEL_COLLECTION,
} from '@containers/ArticlesAndClicks/constants';
import { ColumnContainer } from '@containers/ArticlesAndClicks/styles';

const UsersTableTab = ({
  articlesAndClicksStore: {
    fetchUserEvents,
    userEvents,
    userEventsCount,
    isLoading,
    isLoadingExcel,
  },
}) => {
  const isMounted = useRef(false);
  const [page, setPage] = useState({
    [MONTH_COLLECTION]: 0,
    [HALF_YEAR_COLLECTION]: 0,
  });
  const [rowsPerPage, setRowsPerPage] = useState({
    [MONTH_COLLECTION]: 10,
    [HALF_YEAR_COLLECTION]: 10,
  });
  const [searchQuery, setSearchQuery] = useState({
    startDate: moment().startOf('day').subtract(180, 'days').toDate(),
    endDate: moment().endOf('day').toDate(),
  });

  useEffect(() => {
    if (isMounted.current) {
      fetchUserEvents({
        collection: EXCEL_COLLECTION,
        from: searchQuery.startDate,
        to: searchQuery.endDate,
      });
    }
  }, [searchQuery]);

  useEffect(() => {
    isMounted.current = true;
    fetchUserEvents({ collection: MONTH_COLLECTION });
    fetchUserEvents({ collection: HALF_YEAR_COLLECTION });
    fetchUserEvents({
      collection: EXCEL_COLLECTION,
      from: searchQuery.startDate,
      to: searchQuery.endDate,
    });
  }, []);

  const handleChangePage = async (newPage, collection) => {
    setPage({ ...page, [collection]: newPage });
    await fetchUserEvents({
      limit: rowsPerPage[collection],
      page: newPage,
      collection,
    });
  };

  const handleChangeRowsPerPage = async (value, collection) => {
    setRowsPerPage({ ...rowsPerPage, [collection]: value });
    setPage({ ...page, [collection]: 0 });
    await fetchUserEvents({
      limit: value,
      page: 0,
      collection,
    });
  };

  const { columns, excelColumns } = tabsConstants.usersTable;

  return (
    <ColumnContainer>
      {isLoading && <Preloader overlay />}
      <Table
        count={userEventsCount[MONTH_COLLECTION]}
        rowsPerPage={rowsPerPage[MONTH_COLLECTION]}
        page={page[MONTH_COLLECTION]}
        onChangePage={(value) => handleChangePage(value, MONTH_COLLECTION)}
        onChangeRowsPerPage={(value) =>
          handleChangeRowsPerPage(value, MONTH_COLLECTION)
        }
        columns={columns(MONTH_COLLECTION)}
        data={userEvents[MONTH_COLLECTION]}
      />
      <Table
        count={userEventsCount[HALF_YEAR_COLLECTION]}
        rowsPerPage={rowsPerPage[HALF_YEAR_COLLECTION]}
        page={page[HALF_YEAR_COLLECTION]}
        onChangePage={(value) => handleChangePage(value, HALF_YEAR_COLLECTION)}
        onChangeRowsPerPage={(value) =>
          handleChangeRowsPerPage(value, HALF_YEAR_COLLECTION)
        }
        columns={columns(HALF_YEAR_COLLECTION)}
        data={userEvents[HALF_YEAR_COLLECTION]}
      />
      <ExportExcel
        isLoading={isLoadingExcel}
        searchQuery={searchQuery}
        setSearchQuery={(value) => setSearchQuery({ ...searchQuery, ...value })}
        data={userEvents[EXCEL_COLLECTION]}
        keys={excelColumns(searchQuery.startDate, searchQuery.endDate)}
        fileName="ArticlesClicks - Count per user"
      />
    </ColumnContainer>
  );
};

export default inject('articlesAndClicksStore')(observer(UsersTableTab));
