import { makeAutoObservable } from 'mobx';
import API from '@app/api';
import apiRoutes from '@app/apiRoutes';
import { log, TYPE } from '@utils/logger';

export class SourcesAttachedToPhrasesStore {
  isLoadingTable = false;

  tableData = [];

  tableDataCount = 0;

  resultText = 'Type search phrase Id to show data';

  constructor() {
    makeAutoObservable(this);
  }

  fetchTable = async (query) => {
    this.isLoadingTable = true;

    try {
      const response = await API.get(apiRoutes.sourcesAttachedtoPhrases.index, {
        params: query,
      });

      this.tableData = response.data.article_sources.map((x) => ({
        ...x,
        disabled: x.disabled ? 'True' : 'False',
      }));
      this.tableDataCount = response.data.article_sources_count;

      if (this.tableDataCount > 0) {
        this.resultText = 'Type search phrase Id to show data';
      } else {
        this.resultText =
          'No matching data was found for the provided phrase ID';
      }
    } catch (e) {
      log(e.message, e, TYPE.ERROR);
      this.tableData = [];
      this.resultText = 'Something went wrong';
    } finally {
      this.isLoadingTable = false;
    }
  };

  clearTableData = () => {
    this.tableData = [];
    this.tableDataCount = 0;
  };
}

export default new SourcesAttachedToPhrasesStore();
