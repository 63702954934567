import React from 'react';
import moment from 'moment';
import SentimentField from './components/SentimentField';

const orders = [
  { value: 'desc', label: 'Descending' },
  { value: 'asc', label: 'Ascending' },
];

export const filtersFunc = (fetchCompanies, fetchArticleSources) => [
  {
    startDateId: 'from',
    endDateId: 'to',
    showTime: false,
    label: 'Date range',
    type: 'dateRange',
  },
  {
    id: 'order',
    label: 'Order',
    type: 'select',
    options: orders,
  },
  {
    id: 'company_name',
    label: 'Company',
    type: 'asyncSelect',
    loadFunc: fetchCompanies,
    className: 'big',
    isClearable: true,
  },
  {
    id: 'article_source_name',
    label: 'Article Source',
    type: 'asyncSelect',
    loadFunc: fetchArticleSources,
    className: 'big',
    isClearable: true,
  },
];

export const defaultSearchQuery = {
  from: moment().startOf('day').subtract(1, 'month').toDate(),
  to: moment().endOf('day').toDate(),
  order: 'desc',
  order_select_option: orders[0],
};

const dateFormat = (value) => moment(value).format('D/M/YYYY h:mm:ss A');

export const fields = [
  { id: 'company_name', label: 'Name:' },
  { id: 'sentiment', format: (value) => <SentimentField value={value} /> },
  { id: 'publication_type', label: 'Type:' },
  { id: 'title' },
  { id: 'content_type' },
  { id: 'article_source' },
  { id: 'published_at', format: dateFormat },
  { id: 'created_at', format: dateFormat },
  { id: 'body' },
];
