import React from 'react';
import { ChartHeader } from '@containers/AlertsStatistics/styles';
import Text, { TAGS } from '@components/Text';
import ExcelDownload from '@components/ExcelDownload';
import { ChartContainer } from '@containers/Users/styles';
import LineChart from '@components/LineChart';
import { dateTickFormatter } from '@utils/chartHelpers';

const Chart = ({
  companiesNames,
  keys,
  data,
  excelKeys,
  dateFormat = 'DD-MM-YYYY',
  label,
}) => (
  <>
    <ChartHeader>
      <Text tag={TAGS.H1}>{label}</Text>
      <ExcelDownload
        name={`${companiesNames}-alerts-stats`}
        data={data}
        keys={excelKeys}
      />
    </ChartHeader>
    <ChartContainer>
      <LineChart
        dateFormatTooltip={dateFormat}
        tickFormatter={(value) => dateTickFormatter(value, 'date', dateFormat)}
        data={data}
        keys={keys}
        xAxisKey="date"
      />
    </ChartContainer>
  </>
);

export default Chart;
