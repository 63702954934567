import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const ChartContainer = styled.div`
  width: 100%;
  margin-bottom: 22px;
  margin-top: 22px;
  box-shadow: rgb(0 0 0 / 16%) 0 3px 6px 0;
  background-color: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 3px;
  &.empty {
    display: flex;
    justify-content: center;
    box-shadow: unset;
    height: 400px;
    line-height: 400px;
    text-align: center;
    font-size: ${({ theme }) => theme.defaults.fontSizeXL}px;
    color: ${({ theme }) => theme.colors.primary};
  }

  &.half {
    width: calc(50% - 8px);
    @media screen and ${breakpoint.lg} {
      width: 100%;
    }
  }

  &.flex {
    display: flex;
    flex-wrap: wrap;
  }

  @media screen and ${breakpoint.md} {
    box-shadow: unset;
    background-color: unset;
    padding: 0 10px 10px 0;
  }
`;
