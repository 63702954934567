import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { ContentWrapper } from '@styles/globalStyles';
import Preloader from '@components/Preloader';
import Filters from '@components/Filters';
import {
  defaultSearchQuery,
  fields,
  filtersFunc,
} from '@containers/ClientCoverages/constants';
import List from '@components/List';
import useBreakpoint from '@root/hooks/useBreakpoint';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';
import routes from '@routes';

const ClientCoverages = ({
  clientCoveragesStore: {
    fetchTable,
    tableData,
    isLoadingTable,
    tableDataCount,
    fetchLists,
    isLoadingLists,
    publishers,
    editors,
    analysts,
    excelData,
    isLoadingExcel,
  },
}) => {
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [searchQuery, setSearchQuery, getCleanSearchQuery] =
    useCustomSearchParams(defaultSearchQuery);

  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  const search = async (newPage = 0, clear = false) => {
    const query = clear ? defaultSearchQuery : getCleanSearchQuery();
    if (clear) {
      setSearchQuery(query);
    }
    setPage(newPage);
    await fetchTable({
      page: newPage,
      searchQuery: query,
    });
  };

  useEffect(() => {
    fetchLists();
    search(0);
  }, []);

  const filters = filtersFunc(analysts, editors, publishers, isLoadingLists);

  const columnActions = {
    id: (row) => navigate(routes.clientCoverages.show(row.id)),
  };

  return (
    <ContentWrapper>
      {isLoadingTable && <Preloader overlay />}
      <Filters
        clear={() => search(0, true)}
        search={search}
        searchQuery={searchQuery}
        setSearchQuery={(valueObj) => {
          setSearchQuery({ ...searchQuery, ...valueObj });
        }}
        isOpen={isFiltersOpen}
        setIsOpen={setIsFiltersOpen}
        filters={filters}
        containerClass="small-margin"
      />
      <List
        isMobile={isMobile}
        columnActions={columnActions}
        data={tableData}
        fields={fields}
        currentPage={page + 1}
        dataCount={tableDataCount}
        pageSize={20}
        onPageChange={(value) => search(value - 1)}
        withExcel
        excelProps={{
          excelData,
          isLoading: isLoadingExcel,
          excelFileName: 'ClientCoveragesReport',
          onExcelDownload: () => {
            fetchTable({
              searchQuery,
              excel: true,
            });
          },
        }}
      />
    </ContentWrapper>
  );
};

export default inject('clientCoveragesStore')(observer(ClientCoverages));
