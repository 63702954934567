import React from 'react';
import { Article, Eye } from 'tabler-icons-react';
import { Paper } from '@styles/globalStyles';
import {
  CountersContainer,
  ColumnContainer,
  RowContainer,
  Title,
} from '@containers/ArticlesAndClicks/styles';
import IconWithText from '@components/IconWithText';

const Counters = ({ articleViews, articlePublications }) => (
  <CountersContainer>
    <Paper>
      <ColumnContainer>
        <Title className="margin-auto">Article views</Title>
        <RowContainer>
          <IconWithText
            icon={Eye}
            mainText={articleViews[30]}
            text="for last 30 days"
          />
          <IconWithText
            icon={Eye}
            mainText={articleViews[180]}
            text="for last 180 days"
          />
        </RowContainer>
      </ColumnContainer>
    </Paper>
    <Paper>
      <ColumnContainer>
        <Title className="margin-auto">Article publications</Title>
        <RowContainer>
          <IconWithText
            icon={Article}
            mainText={articlePublications[30]}
            text="for last 30 days"
          />
          <IconWithText
            icon={Article}
            mainText={articlePublications[180]}
            text="for last 180 days"
          />
        </RowContainer>
      </ColumnContainer>
    </Paper>
  </CountersContainer>
);

export default Counters;
