import PropTypes from 'prop-types';
import React from 'react';
import { ExternalLink } from 'tabler-icons-react';
import { StyledWebLink } from './styles';

const WebLink = ({ url, label, checkUrl }) =>
  /^https?:\/\//.test(url) || !checkUrl ? (
    <StyledWebLink
      href={url}
      target="_blank"
      onClick={() => window.open(url, '_blank', 'noopener,noreferrer')}
    >
      {label || url}
      <ExternalLink />
    </StyledWebLink>
  ) : (
    <>{label ? `${label} (${url})` : url}</>
  );

WebLink.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string,
  checkUrl: PropTypes.bool,
};

WebLink.defaultProps = {
  label: null,
  checkUrl: true,
};

export default WebLink;
