import styled from '@emotion/styled';

export const DateRangeSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.div`
  font-family: ${({ theme }) => theme.defaults.fontFamily.primary};
  font-size: ${({ theme }) => theme.defaults.smallFontSize}px;
  color: ${({ theme }) => theme.colors.inputLabel};
  margin-bottom: 9px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;

  .react-datepicker {
    border: 1px solid #29235c;
  }

  .react-datepicker__time-container {
    border-left: 1px solid #29235c;
  }

  .react-datepicker__triangle::after {
    border-bottom-color: ${({ theme }) => theme.colors.primary} !important;
  }

  .react-datepicker__header {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom: unset;
  }

  .react-datepicker-time__header {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
  }

  .react-datepicker__time {
    background-color: ${({ theme }) => theme.colors.mainBackground};
  }

  li.react-datepicker__time-list-item {
    color: ${({ theme }) => theme.font.default};
    border-right: 1px solid #29235c;
    :hover {
      background-color: ${({ theme }) => theme.colors.quinary} !important;
      color: ${({ theme }) => theme.font.default};
    }
  }

  .react-datepicker__day {
    color: ${({ theme }) => theme.font.default};
    :hover {
      background-color: ${({ theme }) => theme.colors.quinary};
      color: ${({ theme }) => theme.colors.mainBackground};
    }
  }

  .react-datepicker__day--disabled {
    color: ${({ theme }) => theme.colors.disabled};
  }

  .react-datepicker__month-container {
    background-color: ${({ theme }) => theme.colors.mainBackground};
    border-radius: ${({ showTime }) => (showTime ? '0' : '0.3em')};
  }

  .react-datepicker__current-month,
  .react-datepicker__day-name {
    color: ${({ theme }) => theme.colors.white};
  }

  .react-datepicker__header {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
  }
  .datepicker__navigation-icon::before {
    border-color: ${({ theme }) => theme.colors.white};
  }

  .react-datepicker__time-list-item--selected,
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    background-color: ${({ theme }) => theme.colors.primary} !important;
    color: ${({ theme }) => theme.colors.white} !important;
    :hover {
      font-weight: 600;
    }
  }

  .react-datepicker-wrapper:first-of-type {
    margin-right: 16px;
  }

  .react-datepicker__input-container {
    height: 40px;

    input {
      height: 40px;
      background-color: ${({ theme }) => theme.inputs.secondary.default};
      font-family: ${({ theme }) => theme.defaults.fontFamily.primary};
      font-weight: 300;
      border: 1px solid;
      border-color: ${({ theme }) => theme.colors.secondary};
      padding: 8px 16px;
      color: ${({ theme }) => theme.inputs.secondary.text};
      border-radius: 3px;
      width: ${({ showTime }) => (showTime ? '150px' : '115px')};
    }
  }
`;
