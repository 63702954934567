import moment from 'moment';

export const defaultSearchQuery = {
  from_date: moment().subtract(4, 'weeks').toDate(),
  to_date: moment().toDate(),
};

export const filtersFunc = (requestReport) => [
  {
    startDateId: 'from_date',
    endDateId: 'to_date',
    label: 'Date range',
    showTime: false,
    type: 'dateRange',
  },
  {
    label: 'Send CSV via email',
    type: 'button',
    onClick: requestReport,
  },
];
