import axios from 'axios';
import Cookie from 'js-cookie';

// eslint-disable-next-line import/no-cycle
import authStore from '@root/stores/authStore';

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const ERROR_CODES = [400, 401];

API.interceptors.request.use((config) => {
  if (config.params) {
    const paramsKeys = Object.keys(config.params);
    const params = {};
    paramsKeys
      .filter((key) => !key.includes('_select_option'))
      .forEach((key) => {
        params[key] = config.params[key];
      });
    config.params = params;
    return config;
  }
  return config;
});

API.interceptors.response.use(
  (response) => response,
  (error) => {
    if (ERROR_CODES.indexOf(error.response.status) > -1) {
      authStore.logout();
    }
    if (error.response.status === 403) {
      if (process.env.REACT_APP_ENABLE_SSO === 'true') {
        window.location.href = `${process.env.REACT_APP_SSO_URL}/forbidden`;
      }
    }
    return Promise.reject(error.response.data);
  },
);

export function setApiHeaders(analystToken, analystEmail) {
  const suffix = process.env.REACT_APP_COOKIE_SUFFIX;

  Cookie.set(`analyst_email${suffix}`, analystEmail, {
    domain: '.briefcase.news',
  });

  Cookie.set(`analyst_token${suffix}`, analystToken, {
    domain: '.briefcase.news',
  });

  if (API) {
    API.defaults.headers.common = {
      'X-ANALYST-EMAIL': analystEmail,
      'X-ANALYST-TOKEN': analystToken,
    };
  }
}

export default API;
