import moment from 'moment';

export const dateTickFormatter = (tick, dataKey, format = 'YYYY-MM-DD') => {
  if (dataKey === 'date') {
    return moment(tick).format(format).toString();
  }
  return tick;
};

export const nonZeroKeys = ({ keys, data }) => {
  const keyNamesArray = keys.map((key) => key.name);
  const keyNames = [];

  if (data) {
    data.forEach((item) => {
      Object.entries(item).forEach(([key, value]) => {
        if (
          value &&
          value !== 0 &&
          keyNames.indexOf(key) < 0 &&
          keyNamesArray.indexOf(key) >= 0
        ) {
          keyNames.push(key);
        }
      });
    });

    return keys.filter((key) => keyNames.indexOf(key.name) >= 0);
  }
  return [];
};

export const titleCase = (str) => {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i += 1) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
};

export const formatName = (value) => titleCase(value.replace('_', ' '));
