/* eslint-disable import/no-cycle */
import React from 'react';
import moment from 'moment';
import ArticlesAndClicksTab from '@containers/ArticlesAndClicks/components/ArticlesAndClicksTab';
import UsersTableTab from '@containers/ArticlesAndClicks/components/UsersTableTab';

export const tabs = [
  { label: 'Totals', content: <ArticlesAndClicksTab name="total" /> },
  {
    label: 'Totals per source',
    content: <ArticlesAndClicksTab name="totalPerSource" withArticleSources />,
  },
  {
    label: 'Unique articles',
    content: (
      <ArticlesAndClicksTab name="uniqueArticles" distinctKey="article_id" />
    ),
  },
  {
    label: 'Unique users',
    content: (
      <ArticlesAndClicksTab name="uniqueUsers" distinctKey="user_email" />
    ),
  },
  {
    label: 'Users table',
    content: <UsersTableTab />,
  },
];

export const tabsConstants = {
  total: {
    fileName: 'Totals',
    keys: [
      {
        key: 'eventsCount',
        label: 'Number of article views',
        color: '#000000',
        show: true,
      },
      {
        key: 'publicationsCount',
        label: 'Number of publications with articles',
        color: '#A14515',
        show: true,
      },
    ],
  },
  totalPerSource: {
    fileName: '',
    keys: [
      {
        key: 'eventsCount',
        label: 'Number of article views',
        color: '#000000',
        show: true,
      },
      {
        key: 'publicationsCount',
        label: 'Number of publications with articles',
        color: '#A14515',
        show: true,
      },
    ],
  },
  uniqueArticles: {
    fileName: 'Unique articles',
    keys: [
      {
        key: 'eventsCount',
        label: 'Number of article views',
        color: '#000000',
        show: true,
      },
      {
        key: 'publicationsCount',
        label: 'Number of publications with articles',
        color: '#A14515',
        show: true,
      },
    ],
  },
  uniqueUsers: {
    fileName: 'Unique users',
    keys: [
      {
        key: 'eventsCount',
        label: 'Number of article views',
        color: '#000000',
        show: true,
      },
      {
        key: 'publicationsCount',
        label: 'Number of publications with articles',
        color: '#A14515',
        show: true,
      },
    ],
  },
  usersTable: {
    columns: (collection) => [
      {
        id: '_id',
        label: 'User email',
      },
      {
        id: 'count',
        label: `last ${collection} days`,
      },
    ],
    excelColumns: (startDate, endDate) => [
      {
        key: '_id',
        label: 'User email',
      },
      {
        key: 'count',
        label: `Count from ${formatDate(startDate)} to ${formatDate(endDate)}`,
      },
    ],
  },
};

export const MONTH_COLLECTION = 30;
export const HALF_YEAR_COLLECTION = 180;
export const EXCEL_COLLECTION = 'excel';

const formatDate = (date) => moment(date).format('LL').toString();
