import styled from '@emotion/styled';

export const TooltipContainer = styled.div`
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  margin: 0;
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.white};
  white-space: nowrap;
  border-radius: 10px;
  border-color: unset;
`;

export const List = styled.ul`
  margin: 0;
`;

export const Label = styled.li`
  list-style-type: none;
  margin-bottom: 2px;
`;

export const ListItem = styled.li`
  color: ${({ color }) => color};
  text-decoration: none;
  list-style-type: none;
  margin-bottom: 2px;
`;

export const TextListItem = styled.li`
  list-style-type: none;
  margin-top: 10px;
`;
