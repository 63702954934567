import moment from 'moment';

export const defaultSearchQuery = {
  created_at_from: moment().subtract(3, 'month').toDate(),
  created_at_to: moment().toDate(),
};

export const tableColumns = [
  {
    id: 'id',
    label: 'Id',
    maxWidth: 80,
  },
  {
    id: 'created_at',
    label: 'Created at',
    format: (value) => moment(value).format('LLL').toString(),
    hide: true,
    excelFormat: 'dd-mm-yyyy hh:mm',
  },
  {
    id: 'published_at',
    label: 'Summary publication date',
    format: (value) => (value ? moment(value).format('LLL').toString() : '-'),
    excelFormat: 'dd-mm-yyyy hh:mm',
  },
  {
    id: 'briefing_type_name',
    label: 'Briefing Type',
  },
  {
    id: 'article_source_title',
    label: 'Source',
  },
  {
    id: 'analyst_email',
    label: 'Analyst',
    hide: true,
  },
  {
    id: 'editor_email',
    label: 'Editor',
    hide: true,
  },
  {
    id: 'publisher_email',
    label: 'Published by',
    format: (value) => value || '-',
    hide: true,
  },
  {
    id: 'content_title',
    label: 'Content Title',
    className: 'text-cut',
    toolTip: true,
  },
  {
    id: 'url',
    label: 'Url',
    className: 'text-cut',
  },
];

export const detailsFields = [
  { id: 'id' },
  { id: 'content' },
  { id: 'content_container' },
  { id: 'publication_type' },
  { id: 'status' },
  { id: 'position' },
  { id: 'published_at' },
  { id: 'url' },
  { id: 'article' },
  { id: 'analyst' },
  { id: 'url_published', format: (value) => (value === true ? 'Yes' : 'No') },
  { id: 'publisher' },
  { id: 'editor' },
  { id: 'article_source' },
  { id: 'created_at' },
  { id: 'updated_at' },
  { id: 'read_more_url' },
  { id: 'nla_link', format: (value) => (value === true ? 'Yes' : 'No') },
  { id: 'briefing_section' },
  { id: 'sentiment' },
  {
    id: 'spokesperson_mention',
    format: (value) => (value === true ? 'Yes' : 'No'),
  },
  {
    id: 'headline_mention',
    format: (value) => (value === true ? 'Yes' : 'No'),
  },
];

export const filtersFunc = (briefings, isLoadingBriefings) => [
  {
    startDateId: 'created_at_from',
    endDateId: 'created_at_to',
    label: 'Creation date',
    showTime: false,
    type: 'dateRange',
  },
  {
    startDateId: 'published_at_from',
    endDateId: 'published_at_to',
    label: 'Publication date',
    showTime: false,
    type: 'dateRange',
  },
  {
    id: 'briefing_type_id',
    label: 'Briefing type',
    type: 'select',
    options: briefings,
    isLoading: isLoadingBriefings,
    isClearable: false,
  },
];
