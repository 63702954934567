import { makeAutoObservable } from 'mobx';
import moment from 'moment';
import API from '@app/api';
import apiRoutes from '@app/apiRoutes';

export class EmailStore {
  all = [];

  isLoadingAll = false;

  totalCount = 0;

  totalPages = 0;

  chartData = [];

  isLoadingBarChart = false;

  countersValues = {};

  isLoadingCounters = false;

  constructor() {
    makeAutoObservable(this);
  }

  fetchAll = async ({ page, searchQuery }) => {
    this.isLoadingAll = true;
    const query = {
      limit: 20,
      page: parseInt(page ? page.toString() : 1, 10),
      ...searchQuery,
    };

    try {
      const {
        data: { data, itemsTotal, pagesTotal },
      } = await API.get(apiRoutes.emails.all, {
        params: query,
      });
      this.all = data;
      this.totalCount = itemsTotal;
      this.totalPages = pagesTotal;
    } catch (e) {
      this.error = e;
    } finally {
      this.isLoadingAll = false;
    }
  };

  fetchBarChart = async ({ searchQuery }) => {
    this.isLoadingBarChart = true;
    const localSearchQuery = {
      ...searchQuery,
      from: moment(searchQuery.from).format('LLL'),
      to: moment(searchQuery.to).format('LLL'),
    };
    try {
      const { data } = await API.get(apiRoutes.emails.chart, {
        params: localSearchQuery,
      });
      this.chartData = data.map(({ total, name }) => ({ total, date: name }));
    } catch (e) {
      this.error = e;
    } finally {
      this.isLoadingBarChart = false;
    }
  };

  fetchCounters = async ({ searchQuery }) => {
    this.isLoadingCounters = true;
    const localSearchQuery = {
      ...searchQuery,
      from: moment(searchQuery.from).format('LLL'),
      to: moment(searchQuery.to).format('LLL'),
    };
    try {
      const { data } = await API.get(apiRoutes.emails.counters, {
        params: localSearchQuery,
      });
      data.uniqueEmailsOpen = `${data.uniqueEmailsOpen} (${
        data.emailsSent !== 0
          ? `${((data.uniqueEmailsOpen / data.emailsSent) * 100).toFixed(2)}%`
          : 'N/A'
      })`;
      this.countersValues = data;
    } catch (e) {
      this.error = e;
    } finally {
      this.isLoadingCounters = false;
    }
  };

  fetchUserEmails = async (email) => {
    try {
      const { data } = await API.get(apiRoutes.users.emails, {
        params: { email },
      });
      return data.map((value) => ({ value, label: value }));
    } catch (e) {
      this.error = e;
    }
    return [];
  };
}

export default new EmailStore();
