import moment from 'moment/moment';

export const defaultSearchQuery = {
  created_at_from: moment().subtract(3, 'month').toDate(),
  created_at_to: moment().toDate(),
};

export const appTypes = [
  { value: 'all', label: 'FTSE and Corporate' },
  { value: 'ftse', label: 'FTSE only' },
  { value: 'corporate', label: 'Corporate only' },
];
export const detailsFields = [
  { id: 'id' },
  { id: 'media_name' },
  { id: 'media_code' },
  { id: 'copy_type' },
  { id: 'organization_name' },
  { id: 'customer_id' },
  { id: 'permitted_users' },
  { id: 'no_of_cuttings' },
  { id: 'client_id' },
  {
    id: 'created_at',
    format: (value) => (value ? moment(value).format('LLL').toString() : '-'),
  },
  {
    id: 'updated_at',
    format: (value) => (value ? moment(value).format('LLL').toString() : '-'),
  },
  { id: 'sections' },
  { id: 'content_container_id' },
  {
    id: 'ftse',
  },
];

export const tableColumns = [
  {
    id: 'id',
    label: 'Id',
    maxWidth: 70,
  },
  {
    id: 'media_name',
    label: 'Media name',
    // className: 'text-cut',
    // toolTip: true,
  },
  {
    id: 'media_code',
    label: 'Media code',
    maxWidth: 100,
  },
  {
    id: 'copy_type',
    label: 'Copy type',
    hide: true,
  },
  {
    id: 'organization_name',
    label: 'Organization name',
    className: 'text-cut',
    toolTip: true,
  },
  {
    id: 'customer_id',
    label: 'Customer ID',
    hide: true,
  },
  {
    id: 'permitted_users',
    label: 'Permitted users',
    maxWidth: 90,
  },
  {
    id: 'no_of_cuttings',
    label: 'Cuttings amount',
    maxWidth: 90,
  },
  {
    id: 'client_id',
    label: 'Client ID',
    hide: true,
  },
  {
    id: 'created_at',
    label: 'Creation date',
    format: (value) => (value ? moment(value).format('LL').toString() : '-'),
    excelFormat: 'dd-mm-yyyy hh:mm',
  },
  {
    id: 'updated_at',
    label: 'Update date',
    format: (value) => (value ? moment(value).format('LL').toString() : '-'),
    excelFormat: 'dd-mm-yyyy hh:mm',
    hide: true,
  },
  {
    id: 'sections',
    label: 'Sections',
    className: 'text-cut',
    toolTip: true,
  },
  {
    id: 'content_container_id',
    label: 'Content container',
    maxWidth: 90,
  },
  {
    id: 'ftse',
    label: 'App type',
    maxWidth: 90,
  },
];

export const filters = [
  {
    startDateId: 'created_at_from',
    endDateId: 'created_at_to',
    label: 'Date range',
    showTime: false,
    type: 'dateRange',
  },
  {
    id: 'media_name',
    label: 'Media name',
    type: 'input',
  },
  {
    id: 'media_code',
    label: 'Media code',
    type: 'input',
  },
  {
    id: 'organization_name',
    label: 'Organization',
    type: 'input',
  },
  {
    id: 'ftse',
    label: 'App type',
    type: 'select',
    options: appTypes,
    isClearable: false,
  },
  {
    id: 'customer_id',
    label: 'Customer',
    type: 'input',
  },
];

export const defaultQueryOptions = { ftse: 'all' };
