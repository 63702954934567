import React from 'react';
import Details from '@components/Details';
import DetailsMobile from '@components/DetailsMobile';
import Pagination from '@components/List/components/Pagination';
import { ExcelButtonContainer } from '@components/Table/style';
import ExcelDownload from '@components/ExcelDownload';

const List = ({
  isMobile,
  fields,
  data,
  currentPage,
  dataCount,
  onPageChange,
  pageSize,
  withExcel,
  excelProps,
  listTopOffset,
  columnActions,
}) => (
  <>
    {data.map((item, index) =>
      isMobile ? (
        <DetailsMobile
          key={`item-details-${index}`}
          data={item}
          fields={fields}
          omitEmpty
          list
          columnActions={columnActions}
        />
      ) : (
        <Details
          key={`item-details-${index}`}
          data={item}
          fields={fields}
          omitEmpty
          list
          columnActions={columnActions}
        />
      ),
    )}
    {withExcel && excelProps.excelFileName ? (
      <ExcelButtonContainer className="left">
        <ExcelDownload
          onExcelDownload={excelProps.onExcelDownload}
          name={excelProps.excelFileName}
          data={excelProps.excelData}
          isLoading={excelProps.isLoading}
          keys={excelProps.useExcelColumns ? excelProps.excelColumns : fields}
          forTable
          valueKey="id"
        />
      </ExcelButtonContainer>
    ) : null}
    <Pagination
      currentPage={currentPage}
      totalCount={dataCount}
      pageSize={pageSize}
      onPageChange={onPageChange}
      listTopOffset={listTopOffset}
    />
  </>
);

export default List;
