import React, { useRef, useLayoutEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { SentimentFieldContent } from '../styles';

const SentimentField = ({ value, authStore: { darkMode } }) => {
  const ref = useRef(null);

  useLayoutEffect(() => {
    let details = ref.current;
    while (
      details &&
      !details.classList.contains('details') &&
      details.parentNode
    ) {
      details = details.parentNode;
    }
    if (!details) {
      return;
    }
    const oldSentimentClass = [...details.classList].find((className) =>
      className.startsWith('sentiment-'),
    );
    if (oldSentimentClass) {
      details.classList.remove(oldSentimentClass);
    }
    details.classList.add(`sentiment-${value.replaceAll('_', '-')}`);
  }, [darkMode]);

  return <SentimentFieldContent ref={ref}>{value}</SentimentFieldContent>;
};

export default inject('authStore')(observer(SentimentField));
