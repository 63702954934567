import React from 'react';
import { ContentWrapper } from '@styles/globalStyles';
import Tabs from '@components/Tabs';
import { tabs } from '@containers/AlertsStatistics/constants';

const AlertsStatistics = () => (
  <ContentWrapper>
    <Tabs tabs={tabs} />
  </ContentWrapper>
);

export default AlertsStatistics;
