import moment from 'moment/moment';

const ascSymbol = '\u2191';
const descSymbol = '\u2193';
export const orders = [
  { label: `${descSymbol} Creation`, value: 'creation_date_desc' },
  { label: `${ascSymbol} Creation`, value: 'creation_date_asc' },
  { label: `${descSymbol} Publication`, value: 'publication_date_desc' },
  { label: `${ascSymbol} Publication`, value: 'publication_date_asc' },
];

export const defaultSearchQuery = {
  creation_date_from: moment().subtract(1, 'month').toDate(),
  creation_date_to: moment().toDate(),
  order: 'creation_date_desc',
  order_select_option: orders[0],
  company_name: '',
  source: '',
  newspaper: '',
  analyst: '',
  editor: '',
  published_by: '',
  for_analyst: false,
};

export const versions = [
  { label: `For analyst`, value: true },
  { label: `Standard`, value: false },
];

export const filtersFunc = (analyst, editors, publishers, isLoading) => [
  {
    id: 'for_analyst',
    label: 'Version',
    type: 'select',
    options: versions,
    isClearable: false,
  },
  {
    startDateId: 'creation_date_from',
    endDateId: 'creation_date_to',
    label: 'Creation date',
    showTime: false,
    type: 'dateRange',
  },
  {
    startDateId: 'publication_date_from',
    endDateId: 'publication_date_to',
    label: 'Publication date',
    showTime: false,
    type: 'dateRange',
  },
  {
    id: 'order',
    label: 'Order',
    type: 'select',
    options: orders,
    isClearable: false,
  },
  {
    id: 'company_name',
    label: 'Company name',
    type: 'input',
  },
  {
    id: 'source',
    label: 'Article source',
    type: 'input',
  },
  {
    id: 'newspaper',
    label: 'Newspaper',
    type: 'input',
  },
  {
    id: 'analyst',
    label: 'Analyst',
    type: 'select',
    options: analyst,
    className: 'big',
    isLoading,
    isClearable: false,
  },
  {
    id: 'editor',
    label: 'Editor',
    type: 'select',
    options: editors,
    className: 'big',
    isLoading,
    isClearable: false,
  },
  {
    id: 'published_by',
    label: 'Publisher',
    type: 'select',
    options: publishers,
    className: 'big',
    isLoading,
    isClearable: false,
  },
];

export const fields = [
  {
    id: 'id',
  },
  {
    id: 'creation_date',
  },
  {
    id: 'content_publication_date',
    format: (value) => (value ? moment(value).format('LLL').toString() : '-'),
    excelFormat: 'dd-mm-yyyy hh:mm',
  },
  { id: 'company_name' },
  {
    id: 'company_disabled',
    format: (value) => (value === true ? 'Yes' : 'No'),
    align: 'center',
  },
  { id: 'company_type' },
  { id: 'sector_name' },
  { id: 'section_type' },
  { id: 'newspaper' },
  { id: 'source_raw_title', label: 'Article Source Raw Title' },
  { id: 'source', label: 'Article Source' },
  { id: 'analyst' },
  { id: 'editor' },
  { id: 'published_by' },
  { id: 'content_title' },
  { id: 'content_body' },
  { id: 'article_url', label: 'URL' },
  { id: 'article_sentiment_score', align: 'center' },
  { id: 'analyst_sentiment', align: 'center' },
  {
    id: 'spokesperson_mention',
    format: (value) => (value === true ? 'Yes' : 'No'),
  },
  {
    id: 'headline_mention',
    format: (value) => (value === true ? 'Yes' : 'No'),
  },
  {
    id: 'article_type',
    // format: value => value === 0 ? 'straight' : value === 1 ? 'opinion' : formatEmpty()(value),
  },
  { id: 'article_published_by_source' },
  { id: 'article_received_from_source' },
  { id: 'source_delay' },
  {
    id: 'estimate_alert_arrived',
    format: (value) => (value === '[nonexistent]' ? '(none)' : value),
  },
  {
    id: 'estimate_email_arrived',
    format: (value) => (value === '[nonexistent]' ? '(none)' : value),
  },
  {
    id: 'alert_delay',
    format: (value) => (value === '[nonexistent]' ? '(none)' : value),
  },
  {
    id: 'reaction_time',
    format: (value) => (value === '[nonexistent]' ? '(none)' : value),
  },
  {
    id: 'time_to_publish',
    format: (value) => (value === '[nonexistent]' ? '(none)' : value),
  },
  {
    id: 'article_alert_generated',
    format: (value) => (value === '[nonexistent]' ? '(none)' : value),
  },
  { id: 'journalist' },
  { id: 'author' },
];
