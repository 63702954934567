import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { breakpoint } from '@styles/breakpoints';

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 32, 45, 0.4);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  &.alert-enter {
    opacity: 0;
  }
  &.alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  &.alert-exit {
    opacity: 1;
  }
  &.alert-exit-active {
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
  }

  &.html-backdrop .modal,
  &.email-backdrop .modal {
    width: auto;
    overflow: hidden;
  }

  &.email-backdrop .modal {
    width: 667px;
  }
`;

export const Modal = styled.div`
  position: relative;
  width: 90vw;
  height: 90vh;
  padding: 0px 16px 16px;
  background-color: #fff;
  //overflow: auto;

  @media screen and ${breakpoint.md} {
    width: 100vw;
    top: ${({ theme }) => theme.defaults.mobileMenuHeight}px;
    height: ${({ height }) => height}px;
    padding: 0 10px;
  }

  ${({ smallModal }) =>
    smallModal === true
      ? css`
          padding: 32px;
          width: fit-content;
          height: fit-content;

          @media only screen and ${breakpoint.lg} {
            width: fit-content;
            height: 140px;
            margin: 10px;
          }
        `
      : null}
`;

export const ModalInner = styled.div`
  width: 100%;
  margin: auto;
  height: 660px;
  padding-bottom: 40px;

  @media only screen and ${breakpoint.md} {
    width: unset;
    margin-top: 20px;
  }

  ${({ smallModal }) =>
    smallModal === true
      ? css`
          height: 100%;
          padding-bottom: 0;

          @media only screen and ${breakpoint.md} {
            width: unset;
            margin-top: 0;
            padding: 10px;
          }
        `
      : null}
`;

export const ContentTitle = styled.div`
  font-size: 22px;
  padding-bottom: 12px;
  margin: 16px 0 12px 0;
  border-bottom: solid #29235c 1px;
  color: #29235c;

  & > svg {
    margin-left: auto;
    float: right;
    margin-bottom: -4px;
    cursor: pointer;
  }
`;

export const ContentBox = styled.div`
  position: relative;
  overflow: scroll;
  max-height: calc(90vh - 68px);
  max-width: calc(90vw - 32px);

  &.text {
    max-width: 60em;
    line-height: 1.5;
  }
`;
