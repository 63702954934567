import styled from '@emotion/styled';

export const PaginationContainer = styled.ul`
  display: flex;
  list-style-type: none;
  width: 100%;
  justify-content: center;
  margin-top: 10px;
`;

export const PaginationItem = styled.li`
  padding: 0 12px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  color: ${({ theme }) => theme.font.default};
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 16px;
  line-height: 1.43;
  font-size: 13px;
  min-width: 32px;

  &.dots:hover {
    background-color: transparent;
    cursor: default;
  }
  &:hover {
    background-color: ${({ theme }) => theme.icons.hover};
    cursor: pointer;
  }

  &.selected {
    background-color: ${({ theme }) => theme.colors.sidebar};
    color: ${({ theme }) => theme.colors.white};
  }

  .arrow {
    &::before {
      position: relative;
      /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
      content: '';
      /* By using an em scale, the arrows will size with the font */
      display: inline-block;
      width: 0.4em;
      height: 0.4em;
      border-right: 0.12em solid ${({ theme }) => theme.font.default};
      border-top: 0.12em solid ${({ theme }) => theme.font.default};
    }

    &.left {
      transform: rotate(-135deg) translate(-50%);
    }

    &.right {
      transform: rotate(45deg);
    }
  }

  &.disabled {
    pointer-events: none;

    .arrow::before {
      border-right: 0.12em solid ${({ theme }) => theme.colors.disabled};
      border-top: 0.12em solid ${({ theme }) => theme.colors.disabled};
    }

    &:hover {
      background-color: transparent;
      cursor: default;
    }
  }
`;
