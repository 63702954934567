import React from 'react';
import { HeatMapGrid } from 'react-grid-heatmap';
import { useTheme } from '@emotion/react';
import {
  MatrixContainer,
  MatrixLabel,
} from '@containers/ArticleSourcesMatrix/styles';
import { StyledTooltip } from '@styles/globalStyles';
import HeatMapLegend from './HeatMapLegend';

export const HOURS = 24;

const BaseHeatMap = ({ data, colorsForCells, label, isLegend }) => {
  const yLabels = new Array(HOURS)
    .fill(0)
    .map((_, idx) => (idx < 10 ? `0${idx}` : `${idx}`));

  const tooltipLabels = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];
  const xLabels = tooltipLabels.map((day) => day[0]);

  const theme = useTheme();

  return (
    <MatrixContainer>
      <StyledTooltip />
      <MatrixLabel>{label}</MatrixLabel>
      <HeatMapGrid
        data={data}
        xLabels={xLabels}
        yLabels={yLabels}
        cellRender={(x, y, value) => (
          <div
            style={{ color: 'transparent' }}
            data-tip={`${tooltipLabels[y]} (${x}): ${value}`}
            data-place="left"
          >
            o
          </div>
        )}
        cellHeight="1rem"
        square
        xLabelsStyle={() => ({
          color: theme.font.default,
          fontSize: theme.defaults.fontSize,
          margin: '0 0.13rem',
        })}
        yLabelsStyle={() => ({
          color: theme.font.default,
          fontSize: theme.defaults.fontSize,
          margin: '0.13rem 0',
        })}
        cellStyle={(x, y) => ({
          borderStyle: 'solid',
          borderWidth: '0.0625rem',
          borderColor: 'rgb(119, 119, 119, 0.5)',
          background: colorsForCells(x, y),
          margin: '0.13rem',
          borderRadius: 2,
        })}
      />
      {isLegend && <HeatMapLegend />}
    </MatrixContainer>
  );
};

export default BaseHeatMap;
