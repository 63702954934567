export const tableColumns = [
  {
    id: 'id',
    label: 'Id',
    maxWidth: 80,
  },
  {
    id: 'title',
    label: 'Title',
  },
  {
    id: 'display_title',
    label: 'Display Title',
  },
  {
    id: 'newspaper_title',
    label: 'Newspaper Title',
  },
  {
    id: 'disabled',
    label: 'Disabled',
  },
];

export const defaultSearchQuery = {
  search_phrase_id: '',
};

export const filtersFunc = () => [
  {
    id: 'search_phrase_id',
    label: 'Search Phrase Id',
    type: 'input',
    inputType: 'number',
  },
];
