import { makeAutoObservable } from 'mobx';
import moment from 'moment';
import API from '@app/api';
import apiRoutes from '@app/apiRoutes';
import { log, TYPE } from '@utils/logger';

export class ClientCoveragesStore {
  isLoadingTable = false;

  tableData = [];

  tableDataCount = 0;

  isLoadingClientCoverage = false;

  clientCoverage = {};

  analysts = [];

  editors = [];

  publishers = [];

  isLoadingLists = false;

  excelData = [];

  isLoadingExcel = false;

  constructor() {
    makeAutoObservable(this);
  }

  fetchClientCoverage = async (id) => {
    this.isLoadingClientCoverage = true;
    try {
      const {
        data: { report },
      } = await API.get(apiRoutes.clientCoverages.show, {
        params: { id },
      });

      [this.clientCoverage] = report;
    } catch (e) {
      log(e.message, e, TYPE.ERROR);
    } finally {
      this.isLoadingClientCoverage = false;
    }
  };

  fetchLists = async () => {
    try {
      this.isLoadingLists = true;
      const {
        data: {
          data: { analysts, editors, publishers },
        },
      } = await API.get(apiRoutes.clientCoverages.lists);
      this.analysts = [].concat(
        analysts.map((item) => ({ label: item.email, value: item.id })),
      );
      this.editors = [].concat(
        editors.map((item) => ({ label: item.email, value: item.id })),
      );
      this.publishers = [].concat(
        publishers.map((item) => ({ label: item.email, value: item.id })),
      );
    } catch (e) {
      this.error = e;
    } finally {
      this.isLoadingLists = false;
    }
  };

  fetchTable = async ({ page, searchQuery, excel }) => {
    this.isLoadingTable = !excel;
    this.isLoadingExcel = excel;
    const extendedQuery = {
      publication_date_from: searchQuery.creation_date_from,
      publication_date_to: moment().endOf('day').toDate(),
      ...searchQuery,
    };

    if (!extendedQuery.analyst) extendedQuery.analyst = -1;
    if (!extendedQuery.editor) extendedQuery.editor = -1;
    if (!extendedQuery.publisher) extendedQuery.publisher = -1;

    let query;
    if (excel) {
      query = {
        limit: 1000,
        skip: 0,
        ...extendedQuery,
      };
    } else {
      query = {
        limit: 20,
        skip: parseInt(page ? (page * 20).toString() : 0, 10),
        ...extendedQuery,
      };
    }

    try {
      const {
        data: {
          data: { count, report },
        },
      } = await API.get(apiRoutes.clientCoverages.index, {
        params: query,
      });

      if (excel) {
        this.excelData = report;
      } else {
        this.tableData = report;
        this.tableDataCount = count;
        this.excelData = [];
      }
    } catch (e) {
      log(e.message, e, TYPE.ERROR);
    } finally {
      this.isLoadingTable = false;
      this.isLoadingExcel = false;
    }
  };

  clearClientCoverage = () => {
    this.clientCoverage = {};
  };
}

export default new ClientCoveragesStore();
