import moment from 'moment';

export const filtersFunc = (analysts, isLoadingAnalysts) => [
  {
    startDateId: 'startDate',
    endDateId: 'endDate',
    showTime: false,
    label: 'Date range',
    type: 'dateRange',
  },
  {
    id: 'analystId',
    label: 'Analyst',
    type: 'select',
    options: analysts,
    isLoading: isLoadingAnalysts,
  },
];

export const keys = [
  {
    key: 'publicationsCount',
    label: 'Number of publications for analyst',
    color: '#29235C',
    show: true,
  },
];

export const defaultSearchQuery = {
  startDate: moment().subtract(365, 'days').toDate(),
  endDate: moment().toDate(),
};

export const excelColumns = [
  {
    key: 'date',
    label: 'Date',
  },
  {
    key: 'publicationsCount',
    label: 'Publications Count',
  },
];
