import { makeAutoObservable } from 'mobx';
import moment from 'moment';
import API from '@app/api';
import apiRoutes from '@app/apiRoutes';
import { log, TYPE } from '@utils/logger';

export class ContentReportsStore {
  isLoadingContentReport = false;

  isLoadingTable = false;

  isLoadingAnalysts = false;

  analysts = [];

  tableData = [];

  tableDataCount = 0;

  contentReport = {};

  excelData = [];

  isLoadingExcel = false;

  constructor() {
    makeAutoObservable(this);
  }

  fetchAnalysts = async () => {
    this.isLoadingAnalysts = true;
    try {
      const response = await API.get(apiRoutes.contentReports.analysts);

      this.analysts = response.data.analysts.map(
        ({ id, email, first_name: firstName, last_name: lastName }) => ({
          label: firstName && lastName ? `${firstName} ${lastName}` : email,
          value: id,
        }),
      );
    } catch (e) {
      log(null, e, TYPE.ERROR);
    } finally {
      this.isLoadingAnalysts = false;
    }
  };

  fetchTable = async ({ limit, page, searchQuery, excel }) => {
    this.isLoadingTable = !excel;
    this.isLoadingExcel = excel;
    const extendedQuery = {
      published_at_from: searchQuery.created_at_from,
      published_to: moment().endOf('day').toDate(),
      ...searchQuery,
    };

    try {
      let query;
      if (excel) {
        query = {
          limit: 1000,
          skip: 0,
          ...extendedQuery,
        };
      } else {
        query = {
          limit: parseInt(limit ? limit.toString() : 10, 10),
          skip: parseInt(page ? (page * limit).toString() : 0, 10),
          ...extendedQuery,
        };
      }

      const response = await API.get(apiRoutes.contentReports.index, {
        params: query,
      });

      if (excel) {
        this.excelData = response.data.contents;
      } else {
        this.tableData = response.data.contents;
        this.tableDataCount = response.data.contents_count;
        this.excelData = [];
      }
    } catch (e) {
      log(null, e, TYPE.ERROR);
    } finally {
      this.isLoadingTable = false;
      this.isLoadingExcel = false;
    }
  };

  fetchContentReport = async (id) => {
    this.isLoadingContentReport = true;
    try {
      const response = await API.get(apiRoutes.contentReports.show, {
        params: { id },
      });
      this.contentReport = response.data;
    } catch (e) {
      log(null, e, TYPE.ERROR);
    } finally {
      this.isLoadingContentReport = false;
    }
  };

  clearContentReport = () => {
    this.contentReport = {};
  };
}

export default new ContentReportsStore();
