import { makeAutoObservable } from 'mobx';
import moment from 'moment/moment';
import { log, TYPE } from '@utils/logger';
import apiRoutes from '@app/apiRoutes';
import API from '@app/api';

export class DashboardStore {
  trafficData = {};

  isLoading = false;

  recentEvents = [];

  constructor() {
    makeAutoObservable(this);
  }

  fetchTraffic = async (query) => {
    this.trafficData = {};
    query.date_from = moment(query.date_from).format('L');
    query.date_to = moment(query.date_to).format('L');

    try {
      const { data } = await API.get(apiRoutes.dashboard.traffic, {
        params: query,
      });
      this.trafficData = data;
      this.trafficData.traffic = this.trafficData.traffic.map(
        ({ name, total }) => ({
          date: name,
          traffic: total,
        }),
      );
    } catch (e) {
      log(e.message, TYPE.ERROR);
    }
  };

  fetchRecentEvents = async (searchQuery) => {
    this.isLoading = true;
    this.recentEvents = [];

    const query = {
      limit: 5,
      order: 'desc',
      ...searchQuery,
    };

    try {
      const {
        data: { data },
      } = await API.get(apiRoutes.users.events, { params: query });
      this.recentEvents = data;
    } catch (e) {
      log(e.message, TYPE.ERROR);
    } finally {
      this.isLoading = false;
    }
  };
}

export default new DashboardStore();
