import { makeAutoObservable, runInAction } from 'mobx';
import API from '@app/api';
import apiRoutes from '@app/apiRoutes';
import { log, TYPE } from '@utils/logger';

export class SentimentsStore {
  routes = apiRoutes.sentiments;

  all = [];

  isLoadingAll = false;

  totalCount = 0;

  totalPages = 0;

  error = null;

  constructor() {
    makeAutoObservable(this);
  }

  fetchAll = async ({ page, searchQuery }) => {
    runInAction(() => {
      this.isLoadingAll = true;
    });
    const query = {
      limit: 20,
      page: parseInt(page ? page.toString() : 1, 10),
      ...searchQuery,
    };

    try {
      const {
        data: { data, itemsTotal, pagesTotal },
      } = await API.get(this.routes.all, {
        params: query,
      });
      runInAction(() => {
        this.all = data;
        this.totalCount = itemsTotal;
        this.totalPages = pagesTotal;
      });
    } catch (e) {
      runInAction(() => {
        this.error = e;
      });
      log(e.message, e, TYPE.ERROR);
    } finally {
      runInAction(() => {
        this.isLoadingAll = false;
      });
    }
  };

  fetchCompanies = async (fragment) => {
    try {
      const { data } = await API.get(apiRoutes.companies.search(fragment));
      return (data?.companies || []).map((value) => ({ value, label: value }));
    } catch (e) {
      runInAction(() => {
        this.error = e;
      });
      log(e.message, e, TYPE.ERROR);
    }
    return [];
  };

  fetchArticleSources = async (fragment) => {
    try {
      const { data } = await API.get(apiRoutes.articleSources.search(fragment));
      return (data?.article_sources || []).map((value) => ({
        value: value.name,
        label: value.name,
      }));
    } catch (e) {
      runInAction(() => {
        this.error = e;
      });
      log(e.message, e, TYPE.ERROR);
    }
    return [];
  };
}

export default new SentimentsStore();
