import { makeAutoObservable } from 'mobx';
import API from '@app/api';
import apiRoutes from '@app/apiRoutes';
import { log, TYPE } from '@utils/logger';

export class CompaniesRelationsStore {
  isLoading = false;

  isLoadingTable = false;

  isLoadingExcel = false;

  nodes = [];

  groups = {};

  edges = [];

  tableData = [];

  totalRowsNumber = 0;

  excelData = [];

  maxCompaniesCount = 0;

  constructor() {
    makeAutoObservable(this);
  }

  fetchGraph = async () => {
    this.isLoading = true;
    try {
      const {
        data: {
          data: { nodes, edges, groups },
        },
      } = await API.get(apiRoutes.companiesRelations.graph);
      this.nodes = nodes;
      this.edges = edges;
      this.groups = groups;
    } catch (e) {
      this.error = e;
      log(e.message, e, TYPE.ERROR);
    } finally {
      this.isLoading = false;
    }
  };

  fetchTable = async ({ limit, page, searchQuery, excel }) => {
    this.isLoadingExcel = excel;
    this.isLoadingTable = !excel;
    try {
      let query;
      if (excel) {
        query = {
          skip: 0,
          limit: 4000,
          ...searchQuery,
        };
      } else {
        query = {
          limit: parseInt(limit ? limit.toString() : 10, 10),
          skip: parseInt(page ? ((page - 1) * limit).toString() : 0, 10),
          ...searchQuery,
        };
      }

      const {
        data: {
          data: { content_orders: contentOrders, content_orders_count: count },
        },
      } = await API.get(apiRoutes.companiesRelations.table, {
        params: query,
      });

      if (excel) {
        this.prepareExcelData(contentOrders);
      } else {
        this.excelData = [];
        this.maxCompaniesCount = 0;
        this.tableData = contentOrders;
        this.totalRowsNumber = count;
      }
    } catch (e) {
      log(e.message, e, TYPE.ERROR);
    } finally {
      this.isLoadingTable = false;
      this.isLoadingExcel = false;
    }
  };

  prepareExcelData = (contentOrders) => {
    this.excelData = contentOrders.map(
      ({
        subscription_name: subscriptionName,
        name,
        internal_users: internalUsers,
        external_users: externalUsers,
        primary_companies: primaryCompanies,
        secondary_companies: secondaryCompanies,
        peer_companies: peerCompanies,
        portfolio_companies: portfolioCompanies,
      }) => {
        const item = {
          subscription_name: subscriptionName,
          name,
          internal_users: internalUsers,
          external_users: externalUsers,
        };
        let currentIndex;
        const companies = [
          ...primaryCompanies,
          ...secondaryCompanies,
          ...peerCompanies,
          ...portfolioCompanies,
        ].sort();

        companies.forEach((company, index) => {
          currentIndex = index;
          item[`Company ${currentIndex}`] = company;
        });

        if (currentIndex > this.maxCompaniesCount) {
          this.maxCompaniesCount = currentIndex;
        }
        return item;
      },
    );
  };
}

export default new CompaniesRelationsStore();
