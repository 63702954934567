import moment from 'moment';

export const subtitles = {
  article_source:
    'Percentage of used articles in coverages per month for chosen article sources',
  author:
    'Percentage of used articles in coverages per month for chosen authors',
};

export const filtersFunc = (isType, keys, isLoadingKeys) =>
  isType
    ? [
        {
          startDateId: 'fromDate',
          endDateId: 'toDate',
          showTime: false,
          label: 'Date range',
          type: 'dateRange',
        },
        {
          id: 'type',
          label: 'Type of aggregate',
          type: 'select',
          options: types,
        },
        {
          id: 'keys',
          label: 'Keys',
          type: 'select',
          options: keys,
          isLoading: isLoadingKeys,
          isMulti: true,
        },
      ]
    : [
        {
          startDateId: 'fromDate',
          endDateId: 'toDate',
          showTime: false,
          label: 'Date range',
          type: 'dateRange',
        },
        {
          id: 'type',
          label: 'Type of aggregate',
          type: 'select',
          options: types,
        },
      ];

const types = [
  { value: 'article_source', label: 'Article source' },
  { value: 'author', label: 'Author' },
];

export const defaultSearchQuery = {
  fromDate: moment().subtract(1, 'year').toDate(),
  toDate: moment().toDate(),
};
