import React, { useEffect, useRef, useState } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import LineChart from '@components/LineChart';
import { ChartContainer } from '@containers/Users/styles';
import Counters from '@containers/ArticlesAndClicks/components/Counters';
import ExportExcel from '@containers/ArticlesAndClicks/components/ExportExcel';
import Preloader from '@components/Preloader';
import Select from '@components/Select';
import { SelectContainer } from '@components/Filters';
// eslint-disable-next-line import/no-cycle
import { tabsConstants } from '@containers/ArticlesAndClicks/constants';

const ArticlesAndClicksTab = ({
  articlesAndClicksStore: {
    fetchData,
    chartData,
    excelData,
    articleViews,
    articlePublications,
    isLoading,
    isLoadingExcel,
    fetchArticleSources,
    articleSources,
    isLoadingArticleSources,
  },
  withArticleSources,
  distinctKey,
  name,
}) => {
  const [searchQuery, setSearchQuery] = useState({
    startDate: moment().startOf('day').subtract(1, 'month').toDate(),
    endDate: moment().endOf('day').toDate(),
  });

  const { fileName, keys } = tabsConstants[name];
  const fullFileName = withArticleSources
    ? `ArticlesClicks - ${
        searchQuery.articleSourceId
          ? `Source ${searchQuery.articleSourceId}`
          : 'Totals'
      }`
    : `ArticlesClicks - ${fileName}`;

  const isMounted = useRef(false);

  useEffect(() => {
    fetchData({
      startDate: moment().subtract(180, 'days').toDate(),
      articleSourceId: searchQuery.articleSourceId,
      distinctKey,
    });
    fetchData({
      ...searchQuery,
      distinctKey,
      excel: true,
    });
    if (withArticleSources) {
      fetchArticleSources();
    }
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      fetchData({
        ...searchQuery,
        distinctKey,
        excel: true,
      });
      if (withArticleSources) {
        fetchData({
          startDate: moment().subtract(180, 'days').toDate(),
          articleSourceId: searchQuery.articleSourceId,
          distinctKey,
        });
      }
    }
  }, [searchQuery]);

  useEffect(() => {
    isMounted.current = true;
  }, []);

  return (
    <div>
      {isLoading && <Preloader overlay />}
      {withArticleSources ? (
        <SelectContainer className="big">
          <Select
            isLoading={isLoadingArticleSources}
            isClearable
            label="Article source"
            placeholder="Article source..."
            onChange={(option) =>
              setSearchQuery({
                ...searchQuery,
                articleSourceId: option && option.value,
              })
            }
            value={
              articleSources
                ? articleSources.filter(
                    (option) => option.value === searchQuery.articleSourceId,
                  )
                : null
            }
            options={articleSources}
          />
        </SelectContainer>
      ) : null}
      <ChartContainer>
        <LineChart data={chartData} keys={keys} xAxisKey="date" />
      </ChartContainer>
      <Counters
        articleViews={articleViews}
        articlePublications={articlePublications}
      />
      <ExportExcel
        isLoading={isLoadingExcel}
        searchQuery={searchQuery}
        setSearchQuery={(value) => setSearchQuery({ ...searchQuery, ...value })}
        data={excelData}
        keys={[{ key: 'date', label: 'Date' }, ...keys]}
        fileName={fullFileName}
      />
    </div>
  );
};

export default inject('articlesAndClicksStore')(observer(ArticlesAndClicksTab));
