import React from 'react';
// eslint-disable-next-line import/no-named-default
import { default as Select } from 'react-select/async';
import { Container, Label, ReactSelectStyles } from '@components/Select/styles';

const AsyncSelect = ({
  classes,
  error,
  collapsed,
  onMenuOpen,
  label,
  name,
  id,
  value,
  loadFunc,
  placeholder,
  onChange,
  isLoading,
  isClearable,
}) => {
  const selectId = `dropdown_${name}-${id}`;

  return (
    <Container
      className={`custom-select ${classes}`}
      error={error}
      collapsed={collapsed}
    >
      <Label htmlFor={selectId}>{label}</Label>
      <Select
        isLoading={isLoading}
        classNamePrefix="react-select"
        isClearable={isClearable}
        placeholder={placeholder}
        onChange={onChange}
        onMenuOpen={onMenuOpen}
        styles={ReactSelectStyles}
        menuPlacement="auto"
        value={value}
        loadOptions={loadFunc}
        key={`select_key__${selectId}_${value}`}
      />
    </Container>
  );
};

export default AsyncSelect;
