import { makeAutoObservable } from 'mobx';
import moment from 'moment';
import API from '@app/api';
import apiRoutes from '@app/apiRoutes';
import { log, TYPE } from '@utils/logger';

export class ArticleSourcesMatrixStore {
  isLoadingSourcesMatrix = false;

  isLoadingSourcesMatrixAvg = false;

  isLoadingArticleSources = false;

  avgArticleSourcesMatrixData = [];

  articleSourcesMatrixData = [];

  articleSources = [];

  dateTimeFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

  constructor() {
    makeAutoObservable(this);
  }

  fetchArticleSourcesMatrix = async (articleSourceId) => {
    this.isLoadingSourcesMatrix = true;
    const query = {
      articleSourceId,
      timeFrom: moment().startOf('isoWeek').toDate(),
      timeTo: moment().endOf('isoWeek').startOf('hour').toDate(),
    };

    try {
      const {
        data: { data },
      } = await API.get(apiRoutes.articleSourcesMatrix.stats, {
        params: query,
      });

      this.articleSourcesMatrixData = data.map((art) => ({
        numberOfArticles: art.numberOfArticles.toFixed(2),
        time: {
          hour: moment(art.timeFrom, this.dateTimeFormat).hours(),
          // -1 to fit array
          day: moment(art.timeFrom, this.dateTimeFormat).isoWeekday() - 1,
        },
      }));
      this.isLoadingSourcesMatrix = false;
    } catch (e) {
      log(e.message, e, TYPE.ERROR);
    }
  };

  fetchArticleSources = async () => {
    this.isLoadingArticleSources = true;
    try {
      const {
        data: { data },
      } = await API.get(apiRoutes.articleSourcesMatrix.articleSources);
      this.articleSources = data.map(({ id, title }) => ({
        label: `${title} (${id})`,
        value: id,
      }));
      this.isLoadingArticleSources = false;
    } catch (e) {
      log(e.message, e, TYPE.ERROR);
    }
  };

  fetchAvgArticleSourcesMatrix = async (articleSourceId) => {
    this.isLoadingSourcesMatrixAvg = true;
    const query = {
      articleSourceId,
      timeFrom: moment().startOf('isoWeek').subtract(4, 'weeks').toDate(),
      timeTo: moment().endOf('isoWeek').subtract(1, 'week').toDate(),
    };
    try {
      const {
        data: { data },
      } = await API.get(apiRoutes.articleSourcesMatrix.avg, {
        params: query,
      });

      this.avgArticleSourcesMatrixData = data.map((avg) => ({
        numberOfArticles: avg.avg.toFixed(2),
        time: {
          hour: avg.hour,
          // -1 to fit array
          day: avg.dayOfWeek - 1,
        },
      }));
      this.isLoadingSourcesMatrixAvg = false;
    } catch (e) {
      log(e.message, e, TYPE.ERROR);
    }
  };

  clear = () => {
    this.avgArticleSourcesMatrixData = [];
    this.articleSourcesMatrixData = [];
  };
}

export default new ArticleSourcesMatrixStore();
