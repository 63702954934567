import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { ContentWrapper } from '@styles/globalStyles';
import Preloader from '@components/Preloader';
import { HeatMapsContainer } from '@containers/ArticleSourcesMatrix/styles';
import HeatMap from '@containers/ArticleSourcesMatrix/components/HeatMap';
import SubtractHeatMap from '@containers/ArticleSourcesMatrix/components/SubstractHeatMap';
import Select from '@components/Select';
import { SelectContainer } from '@components/Filters';
import HeatMapLegend from '@containers/ArticleSourcesMatrix/components/HeatMapLegend';
import useBreakpoint from '@root/hooks/useBreakpoint';
import Text, { TAGS } from '@components/Text';

const ArticleSourcesMatrix = ({
  articleSourcesMatrixStore: {
    fetchAvgArticleSourcesMatrix,
    fetchArticleSourcesMatrix,
    fetchArticleSources,
    isLoadingSourcesMatrix,
    isLoadingSourcesMatrixAvg,
    avgArticleSourcesMatrixData,
    articleSourcesMatrixData,
    articleSources,
    isLoadingArticleSources,
    clear,
  },
}) => {
  const [articleSourceId, setArticleSourceId] = useState(null);
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.SM);

  useEffect(() => {
    clear();
    if (articleSources.length === 0) {
      fetchArticleSources();
    }
  }, []);

  useEffect(() => {
    if (articleSourceId) {
      fetchArticleSourcesMatrix(articleSourceId);
      fetchAvgArticleSourcesMatrix(articleSourceId);
    }
  }, [articleSourceId]);

  const handleArticleSourceChange = (option) => {
    if (option?.value) {
      setArticleSourceId(option.value);
    } else {
      clear();
      setArticleSourceId(null);
    }
  };

  return (
    <ContentWrapper>
      {isLoadingSourcesMatrix || isLoadingSourcesMatrixAvg ? (
        <Preloader overlay />
      ) : null}
      <SelectContainer className="big">
        <Select
          isLoading={isLoadingArticleSources}
          label="Article source"
          placeholder="Article source..."
          onChange={handleArticleSourceChange}
          value={
            articleSources
              ? articleSources.filter(
                  (option) => option.value === articleSourceId,
                )
              : null
          }
          options={articleSources}
        />
      </SelectContainer>
      {!articleSourcesMatrixData?.length && articleSourceId ? (
        <Text tag={TAGS.H1}>No data for given filters</Text>
      ) : null}
      {articleSourcesMatrixData?.length &&
      avgArticleSourcesMatrixData?.length ? (
        <HeatMapsContainer>
          <HeatMap data={articleSourcesMatrixData} label="Current Week" />
          {isMobile ? <HeatMapLegend /> : null}
          <HeatMap
            data={avgArticleSourcesMatrixData}
            label="Average from previous 4 weeks"
          />
          <SubtractHeatMap
            data={articleSourcesMatrixData}
            avgData={avgArticleSourcesMatrixData}
            label="Alarming Discrepancies"
          />
          {!isMobile ? <HeatMapLegend /> : null}
        </HeatMapsContainer>
      ) : null}
    </ContentWrapper>
  );
};

export default inject('articleSourcesMatrixStore')(
  observer(ArticleSourcesMatrix),
);
