import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5px 0;
  gap: 4px;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.font.default};
  &.main {
    font-size: 24px;
    @media only screen and ${breakpoint.md} {
      font-size: 18px;
    }
  }

  @media only screen and ${breakpoint.md} {
    font-size: 14px;
  }
`;
