import React, { useEffect, useRef, useState } from 'react';
import { ChevronDown, ChevronUp, Search, TrashX } from 'tabler-icons-react';
import {
  ChevronContainer,
  CollapsibleFiltersContainer,
  InnerCollapsibleFiltersContainer,
} from '@components/CollapsibleFilters/styles';
import Button from '@components/Button';
import useBreakpoint from '@root/hooks/useBreakpoint';

export const CollapsibleFilters = ({
  children,
  search,
  clear,
  isOpen,
  setIsOpen,
  containerClass,
  hideButtons = false,
}) => {
  const ref = useRef();
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);
  const [showChevron, setShowChevron] = useState(true);
  useEffect(() => {
    if (ref.current) {
      setShowChevron(ref.current.scrollHeight !== ref.current.clientHeight);
    }
  }, []);

  React.useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        setShowChevron(ref.current.scrollHeight !== ref.current.clientHeight);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  });

  return (
    <CollapsibleFiltersContainer className={containerClass}>
      <InnerCollapsibleFiltersContainer
        ref={ref}
        className={isOpen ? 'open' : null}
      >
        {children}
      </InnerCollapsibleFiltersContainer>
      <ChevronContainer>
        {hideButtons ? null : (
          <>
            <Button classes="no-min" onClick={() => search()}>
              {isMobile ? <Search strokeWidth={1} size={24} /> : 'Filter'}
            </Button>
            <Button classes="no-min" onClick={() => clear()}>
              {isMobile ? <TrashX strokeWidth={1} size={24} /> : 'Clear'}
            </Button>
          </>
        )}
        {showChevron ? (
          <>
            {isOpen ? (
              <ChevronUp onClick={() => setIsOpen(false)} />
            ) : (
              <ChevronDown onClick={() => setIsOpen(true)} />
            )}
          </>
        ) : null}
      </ChevronContainer>
    </CollapsibleFiltersContainer>
  );
};
