import { makeAutoObservable } from 'mobx';
import moment from 'moment';
import API from '@app/api';
import apiRoutes from '@app/apiRoutes';
import { log, TYPE } from '@utils/logger';

export class SummaryReportsStore {
  isLoadingSummaryReport = false;

  isLoadingTable = false;

  isLoadingBriefings = false;

  briefings = [];

  tableData = [];

  tableDataCount = 0;

  summaryReport = {};

  excelData = [];

  isLoadingExcel = false;

  constructor() {
    makeAutoObservable(this);
  }

  fetchBriefings = async () => {
    this.isLoadingBrefings = true;
    try {
      const response = await API.get(apiRoutes.summaryReports.briefings);

      this.briefings = response.data.data.briefing_types.map(
        ({ id, name }) => ({
          label: name,
          value: id,
        }),
      );
    } catch (e) {
      log(null, e, TYPE.ERROR);
    } finally {
      this.isLoadingBrefings = false;
    }
  };

  fetchTable = async ({ limit, page, searchQuery, excel }) => {
    this.isLoadingTable = !excel;
    this.isLoadingExcel = excel;
    const extendedQuery = {
      published_at_from: searchQuery.creation_date_from,
      published_at_to: moment().endOf('day').toDate(),
      ...searchQuery,
    };

    let query;
    if (excel) {
      query = {
        limit: 1000,
        skip: 0,
        ...extendedQuery,
      };
    } else {
      query = {
        limit: parseInt(limit ? limit.toString() : 10, 10),
        skip: parseInt(page ? (page * limit).toString() : 0, 10),
        ...extendedQuery,
      };
    }
    try {
      const response = await API.get(apiRoutes.summaryReports.index, {
        params: query,
      });

      if (excel) {
        this.excelData = response.data.content_publications;
      } else {
        this.tableData = response.data.content_publications;
        this.tableDataCount = response.data.content_publications_count;
        this.excelData = [];
      }
    } catch (e) {
      log(null, e, TYPE.ERROR);
    } finally {
      this.isLoadingTable = false;
      this.isLoadingExcel = false;
    }
  };

  fetchSummaryReport = async (id) => {
    this.isLoadingSummaryReport = true;
    try {
      const response = await API.get(apiRoutes.summaryReports.show, {
        params: { id },
      });
      this.summaryReport = response.data;
    } catch (e) {
      log(null, e, TYPE.ERROR);
    } finally {
      this.isLoadingSummaryReport = false;
    }
  };

  clearSummaryReport = () => {
    this.summaryReport = {};
  };
}

export default new SummaryReportsStore();
