import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { ContentWrapper } from '@styles/globalStyles';
import Table from '@components/Table';
import Preloader from '@components/Preloader';
import Filters from '@components/Filters';
import routes from '@routes';
import {
  defaultSearchQuery,
  filtersFunc,
  tableColumns,
} from '@containers/ContentPublications/constants';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';

const ContentPublications = ({
  contentPublicationsStore: {
    fetchContentPublications,
    contentPublications,
    isLoading,
    contentPublicationsCount,
    isLoadingContentContainers,
    fetchContentContainers,
    filterContentContainers,
    excelData,
    isLoadingExcel,
  },
}) => {
  const [page, setPage] = useState(0);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [searchQuery, setSearchQuery] =
    useCustomSearchParams(defaultSearchQuery);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();

  const columnActions = {
    id: (row) => navigate(routes.contentPublication.show(row.id)),
  };

  useEffect(() => {
    handleChangePage(0);
    fetchContentPublications({
      limit: rowsPerPage,
      page,
      searchQuery,
    });
    fetchContentContainers();
  }, []);

  const handleChangePage = async (newPage) => {
    setPage(newPage);
    await fetchContentPublications({
      limit: rowsPerPage,
      page: newPage,
      searchQuery,
    });
  };

  const handleChangeRowsPerPage = async (value) => {
    setRowsPerPage(value);
    setPage(0);
    await fetchContentPublications({
      limit: value,
      page: 0,
      searchQuery,
    });
  };

  const clear = async () => {
    setSearchQuery(defaultSearchQuery);
    setPage(0);
    await fetchContentPublications({
      limit: rowsPerPage,
      page: 0,
      searchQuery: {},
    });
  };
  const filters = filtersFunc(
    filterContentContainers,
    isLoadingContentContainers,
  );

  return (
    <ContentWrapper>
      {isLoading && <Preloader overlay />}
      <Filters
        clear={clear}
        search={() => handleChangePage(0)}
        searchQuery={searchQuery}
        setSearchQuery={(valueObj) =>
          setSearchQuery({ ...searchQuery, ...valueObj })
        }
        isOpen={isFiltersOpen}
        setIsOpen={setIsFiltersOpen}
        filters={filters}
        containerClass="no-margin"
      />
      <Table
        count={contentPublicationsCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        columns={tableColumns}
        data={contentPublications}
        columnActions={columnActions}
        withExcel
        excelProps={{
          excelData,
          isLoading: isLoadingExcel,
          excelFileName: 'ContentPublicationsReport',
          onExcelDownload: () =>
            fetchContentPublications({ searchQuery, excel: true }),
        }}
      />
    </ContentWrapper>
  );
};

export default inject('contentPublicationsStore')(
  observer(ContentPublications),
);
