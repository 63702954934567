import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Container = styled.div`
  position: relative;
  .react-select__single-value {
    color: ${({ theme }) => theme.inputs.secondary.text};
  }

  .react-select__input {
    color: ${({ theme }) => theme.inputs.secondary.text} !important;
  }

  .react-select__menu-list {
    color: ${({ theme }) => theme.inputs.secondary.text};
    background-color: ${({ theme }) => theme.colors.navbar};
  }

  .react-select__option {
    :hover {
      background-color: ${({ theme }) => theme.colors.secondary};
    }
  }

  .react-select__option--is-focused {
    background-color: transparent;
  }

  .react-select__option--is-selected {
    background-color: ${({ theme }) => theme.colors.primary};
  }

  &.no-margin {
    margin-top: 30px;

    .react-select__control {
      border: unset;
      border-bottom: 1px solid #6793ff;
      border-color: ${({ theme, error }) =>
        error ? theme.colors.error : '#6793ff'};
    }
  }

  .react-select__control {
    background-color: ${({ theme }) => theme.inputs.secondary.default};
    ${({ collapsed }) =>
      collapsed
        ? css`
            max-height: 150px;
            overflow: scroll;
          `
        : null}
  }

  ${({ error, theme }) =>
    error
      ? css`
          .react-select__control {
            border-color: ${theme.colors.error};
          }

          .react-select__indicator {
            color: ${theme.colors.error};
          }
        `
      : null}
`;

export const SelectWrapper = styled.div`
  position: relative;
`;

export const Label = styled.div`
  font-family: ${({ theme }) => theme.defaults.fontFamily.primary};
  font-size: ${({ theme }) => theme.defaults.smallFontSize}px;
  line-height: 1.67;
  color: ${({ theme }) => theme.colors.inputLabel};
  position: relative;
  margin-bottom: 5px;
  margin-top: -3px;
`;

export const ErrorText = styled.p`
  font-size: ${({ theme }) => theme.defaults.smallFontSize}px;
  line-height: 1.67;
  color: ${({ theme }) => theme.colors.error};
  position: absolute;
  top: 40px;
`;

export const ReactSelectStyles = {
  container: (provided) => ({
    ...provided,
    marginBottom: 30,
    marginTop: 0,
    textAlign: 'left',
    borderColor: '#6793FF',
    fontSize: 14,
  }),
  valueContainer: (provided) => ({
    ...provided,
    overflow: 'visible',
  }),
  dropdownIndicator: (provided, state) => ({
    color: '#6793FF',
    marginRight: 10,
    transition: 'all 0.3s ease-in-out',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'none',
  }),
  option: (provided) => ({
    ...provided,
    padding: 10,
    width: '100%',
  }),
  placeholder: (provided) => ({
    ...provided,
    position: 'absolute',
  }),
  control: (base) => ({
    ...base,
    border: '1px solid #6793FF',
    minHeight: '40px',
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid #6793FF',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#000000',
    height: '100%',
  }),
  input: (provided) => ({
    ...provided,
    margin: '-1px',
    paddingBottom: 0,
    paddingTop: 0,
  }),
};
