import { makeAutoObservable } from 'mobx';
import API from '@app/api';
import apiRoutes from '@app/apiRoutes';
import { log, TYPE } from '@utils/logger';

export class BriefingTypesReportStore {

  isLoadingTable = false;

  tableData = [];

  constructor() {
    makeAutoObservable(this);
  }

  fetchTable = async ( ) => {
    this.isLoadingTable = true;

    try {
      const response = await API.get(apiRoutes.briefingTypesReport);
      this.tableData = response.data.briefing_type_stats;
      this.tableDataCount = response.data.briefing_type_stats.length;
    } catch (e) {
      log(e.message, e, TYPE.ERROR);
    } finally {
      this.isLoadingTable = false;
    }
  };
}

export default new BriefingTypesReportStore();
