import { makeAutoObservable } from 'mobx';
import moment from 'moment';
import apiRoutes from '@app/apiRoutes';
import API from '@app/api';
import { log, TYPE } from '@utils/logger';

export class ArticlesAndClicksStore {
  isLoading = false;

  chartData = [];

  articleViews = {
    30: 0,
    180: 0,
  };

  articlePublications = {
    30: 0,
    180: 0,
  };

  excelData = [];

  isLoadingExcel = false;

  articleSources = [];

  isLoadingArticleSources = false;

  userEvents = {};

  userEventsCount = {};

  constructor() {
    makeAutoObservable(this);
  }

  fetchData = async ({
    startDate,
    articleSourceId,
    endDate,
    excel,
    distinctKey,
  }) => {
    if (excel) {
      this.excelData = [];
      this.isLoadingExcel = true;
    } else {
      this.isLoading = true;
      this.chartData = [];
      this.articleViews = {
        30: 0,
        180: 0,
      };
      this.articlePublications = {
        30: 0,
        180: 0,
      };
    }

    const query = {
      from: startDate,
      to: endDate,
      articleSourceId,
      distinctKey,
    };

    try {
      let statrafEvents;
      let countOfPublicationsWithArticles;
      if (distinctKey) {
        [statrafEvents, countOfPublicationsWithArticles] =
          await this.fetchUniques(query);
      } else {
        [statrafEvents, countOfPublicationsWithArticles] =
          await this.fetchTotals(query);
      }

      const { data, articleViews, articlePublications } = this.mergeData(
        statrafEvents,
        countOfPublicationsWithArticles,
        startDate,
        endDate,
      );

      if (excel) {
        this.excelData = data;
        this.isLoadingExcel = false;
      } else {
        this.chartData = data;
        this.articleViews = articleViews;
        this.articlePublications = articlePublications;
        this.isLoading = false;
      }
    } catch (e) {
      log(e.message, e, TYPE.ERROR);
    }
  };

  // eslint-disable-next-line class-methods-use-this
  fetchTotals = async (query) => {
    const {
      data: { data: statrafEvents },
    } = await API.get(
      apiRoutes.articlesAndClicks.dateRangeArticlesCountPerDay,
      { params: query },
    );

    const {
      data: {
        data: { data: countOfPublicationsWithArticles },
      },
    } = await API.get(
      apiRoutes.articlesAndClicks.contentPublicationsWithArticlesCountPerDay,
      { params: query },
    );

    return [statrafEvents, countOfPublicationsWithArticles];
  };

  // eslint-disable-next-line class-methods-use-this
  fetchUniques = async (query) => {
    const {
      data: { data: statrafEvents },
    } = await API.get(
      apiRoutes.articlesAndClicks.dateRangeUniqueArticlesCountPerDay,
      { params: query },
    );

    const {
      data: {
        data: { data: countOfPublicationsWithArticles },
      },
    } = await API.get(
      apiRoutes.articlesAndClicks.uniqueArticlesInPublicationsCountPerDay,
      { params: query },
    );

    return [statrafEvents, countOfPublicationsWithArticles];
  };

  fetchArticleSources = async () => {
    this.isLoadingArticleSources = true;
    try {
      const response = await API.get(apiRoutes.articleSources.search(''));

      this.articleSources = response.data.article_sources.map(
        ({ id, name }) => ({ label: name, value: id }),
      );
      this.isLoadingArticleSources = false;
    } catch (e) {
      log(e.message, e, TYPE.ERROR);
    }
  };

  fetchUserEvents = async ({ page, limit, collection, from, to }) => {
    if (collection === 'excel') {
      this.isLoadingExcel = true;
    } else {
      this.isLoading = true;
    }
    this.userEvents[collection] = [];
    this.userEventsCount[collection] = 0;

    const skip = page && limit ? page * limit : 0;

    const query = {
      from: from || moment().subtract(collection, 'days').toISOString(),
      to: to || moment().toISOString(),
      limit: limit || 10,
      skip,
    };

    try {
      const {
        data: { data: response },
      } = await API.get(apiRoutes.articlesAndClicks.dateRangeUserEventsCount, {
        params: query,
      });

      this.userEvents[collection] = response[0].paginatedResults.filter(
        ({ _id: id }) => id !== null,
      );
      this.userEventsCount[collection] = response[0].totalCount[0]?.count;

      this.isLoading = false;
      this.isLoadingExcel = false;
    } catch (e) {
      log(e.message, e, TYPE.ERROR);
    }
  };

  // eslint-disable-next-line class-methods-use-this
  mergeData = (
    statrafEvents,
    countOfPublicationsWithArticles,
    startDate,
    endDate = moment(),
  ) => {
    const statrafEventsObj = {};
    statrafEvents.forEach(({ date, count }) => {
      statrafEventsObj[moment(date).format('YYYY-MM-DD').toString()] = count;
    });

    const data = [];
    const articleViews = {
      30: 0,
      180: 0,
    };

    const articlePublications = {
      30: 0,
      180: 0,
    };

    let count = moment(endDate).diff(startDate, 'days');

    while (count >= 0) {
      const date = moment(endDate)
        .subtract(count, 'days')
        .format('YYYY-MM-DD')
        .toString();
      const eventsCount = statrafEventsObj[date] || 0;
      const publicationsCount = countOfPublicationsWithArticles[date] || 0;
      const percentage =
        publicationsCount > 0
          ? ((100 * eventsCount) / publicationsCount).toFixed(0)
          : 0;
      data.push({
        date,
        eventsCount,
        publicationsCount,
        percentage,
      });

      if (count <= 180) {
        articleViews['180'] += eventsCount;
        articlePublications['180'] += publicationsCount;
      }

      if (count <= 30) {
        articleViews['30'] += eventsCount;
        articlePublications['30'] += publicationsCount;
      }

      count -= 1;
    }
    return { data, articleViews, articlePublications };
  };
}

export default new ArticlesAndClicksStore();
