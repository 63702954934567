import moment from 'moment';
import {
  DeviceDesktop,
  FileDescription,
  Mail,
  MailOpened,
} from 'tabler-icons-react';

const orders = [
  { value: 'desc', label: 'Descending' },
  { value: 'asc', label: 'Ascending' },
];

export const filtersFunc = (
  fetchEmails,
  fetchSubscriptions,
  eventTypes,
  isLoadingEventTypes,
  mediums,
  isLoadingMediums,
) => [
  {
    startDateId: 'from',
    endDateId: 'to',
    showTime: false,
    label: 'Date range',
    type: 'dateRange',
  },
  {
    id: 'order',
    label: 'Order',
    type: 'select',
    options: orders,
  },
  {
    id: 'user_email',
    label: 'Email',
    type: 'asyncSelect',
    loadFunc: fetchEmails,
    className: 'big',
  },
  {
    id: 'subscription',
    label: 'Subscription',
    type: 'asyncSelect',
    loadFunc: fetchSubscriptions,
    className: 'big',
  },
  {
    id: 'type',
    label: 'Event type',
    type: 'select',
    options: eventTypes,
    isLoading: isLoadingEventTypes,
  },
  {
    id: 'medium',
    label: 'Medium',
    type: 'select',
    options: mediums,
    isLoading: isLoadingMediums,
  },
];

export const defaultSearchQuery = {
  from: moment().startOf('day').subtract(1, 'month').toDate(),
  to: moment().endOf('day').toDate(),
  order: 'desc',
  order_select_option: orders[0],
};

export const counters = [
  { id: 'emailsSent', label: 'Emails sent', icon: Mail },
  { id: 'emailsOpened', label: 'Emails opened', icon: MailOpened },
  { id: 'uniqueEmailsOpen', label: 'Unique emails opened', icon: MailOpened },
  { id: 'webAppOpened', label: 'Web app opened', icon: DeviceDesktop },
  { id: 'articleRead', label: 'Articles read', icon: FileDescription },
];

export const fields = [
  { id: 'action' },
  { id: 'category' },
  { id: 'medium' },
  { id: 'type' },
  { id: 'user_email' },
  { id: 'source_name' },
  { id: 'cid' },
  { id: 'content_publication_id' },
  { id: 'object_name' },
  { id: 'path' },
  { id: 'title' },
  { id: 'template' },
  { id: 'timestamp' },
  {
    id: 'subscriptions',
    format: (value) => (value.length ? value.join(', ') : '(none)'),
  },
  { id: 'location' },
  { id: 'time' },
  { id: 'user_agent' },
  { id: 'version' },
  { id: 'screen_viewport' },
  { id: 'screen_resolution' },
];
