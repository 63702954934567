import { makeAutoObservable } from 'mobx';
import API from '@app/api';
import apiRoutes from '@app/apiRoutes';

export class ArchivedArticlesStore {
  routes = apiRoutes.archivedArticles;

  all = [];

  isLoadingAll = false;

  totalCount = 0;

  totalPages = 0;

  constructor() {
    makeAutoObservable(this);
  }

  fetchAll = async ({ page, searchQuery }) => {
    this.isLoadingAll = true;
    const query = {
      limit: 10,
      page: parseInt(page ? page.toString() : 1, 10),
      ...searchQuery,
    };

    try {
      const {
        data: { data, itemsTotal, pagesTotal },
      } = await API.get(this.routes.all, {
        params: query,
      });
      this.all = data;
      this.totalCount = itemsTotal;
      this.totalPages = pagesTotal;
    } catch (e) {
      this.error = e;
    } finally {
      this.isLoadingAll = false;
    }
  };
}

export default new ArchivedArticlesStore();
