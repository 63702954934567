import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { ContentWrapper } from '@styles/globalStyles';
import Filters from '@components/Filters';
import BarChart from '@components/BarChart';
import useBreakpoint from '@root/hooks/useBreakpoint';
import StatsIcons from '@components/StatsIcons';
import List from '@components/List';
import Preloader from '@components/Preloader';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';
import { counters, defaultSearchQuery, fields, filtersFunc } from './constants';
import { ChartContainer } from './styles';

const Emails = ({
  emailStore: {
    all,
    isLoadingAll,
    fetchAll,
    totalCount,
    chartData,
    isLoadingBarChart,
    fetchBarChart,
    countersValues,
    isLoadingCounters,
    fetchCounters,
    fetchUserEmails,
  },
}) => {
  const [page, setPage] = useState(1);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [searchQuery, setSearchQuery, getCleanSearchQuery] =
    useCustomSearchParams(defaultSearchQuery);

  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  const search = async (newPage = 1, clear = false) => {
    const query = clear ? defaultSearchQuery : getCleanSearchQuery();
    if (clear) {
      setSearchQuery(query);
    }
    setPage(newPage);
    await fetchAll({
      page: newPage,
      searchQuery: query,
    });
    await fetchBarChart({
      searchQuery: query,
    });
    await fetchCounters({
      searchQuery: query,
    });
  };

  useEffect(() => {
    search(1, true);
  }, []);

  const filters = filtersFunc(fetchUserEmails);

  return (
    <ContentWrapper>
      {isLoadingAll || isLoadingBarChart || isLoadingCounters ? (
        <Preloader overlay />
      ) : null}
      <Filters
        clear={() => search(1, true)}
        search={search}
        searchQuery={searchQuery}
        setSearchQuery={(valueObj) =>
          setSearchQuery({ ...searchQuery, ...valueObj })
        }
        isOpen={isFiltersOpen}
        setIsOpen={setIsFiltersOpen}
        filters={filters}
        containerClass="no-margin"
      />
      <ChartContainer>
        <BarChart
          isMobile={isMobile}
          dataKey="date"
          keys={[{ name: 'total' }]}
          yAxisLabel="Number of events"
          data={chartData}
          dateFormatTooltip="YYYY-MM-DD"
        />
      </ChartContainer>
      <StatsIcons counters={counters} data={countersValues} />
      <List
        isMobile={isMobile}
        data={all}
        fields={fields}
        currentPage={page}
        dataCount={totalCount}
        pageSize={20}
        onPageChange={search}
        listTopOffset={610}
      />
    </ContentWrapper>
  );
};

export default inject('emailStore')(observer(Emails));
