import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { ContentWrapper } from '@styles/globalStyles';
import Table from '@components/Table';
import Preloader from '@components/Preloader';
import Filters from '@components/Filters';
import routes from '@routes';
import {
  filtersFunc,
  tableColumns,
  defaultSearchQuery,
} from '@containers/ContentReports/constants';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';

const ContentReports = ({
  contentReportsStore: {
    fetchTable,
    tableData,
    isLoadingTable,
    tableDataCount,
    isLoadingAnalysts,
    fetchAnalysts,
    analysts,
    excelData,
    isLoadingExcel,
  },
}) => {
  const [page, setPage] = useState(0);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [searchQuery, setSearchQuery] =
    useCustomSearchParams(defaultSearchQuery);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();

  const columnActions = {
    id: (row) => navigate(routes.contentReports.show(row.id)),
  };

  useEffect(() => {
    handleChangePage(0);
    fetchTable({
      limit: rowsPerPage,
      page,
      searchQuery,
    });
    fetchAnalysts();
  }, []);

  const handleChangePage = async (newPage) => {
    setPage(newPage);
    await fetchTable({
      limit: rowsPerPage,
      page: newPage,
      searchQuery,
    });
  };

  const handleChangeRowsPerPage = async (value) => {
    setRowsPerPage(value);
    setPage(0);
    await fetchTable({
      limit: value,
      page: 0,
      searchQuery,
    });
  };

  const clear = async () => {
    setSearchQuery(defaultSearchQuery);
    setPage(0);
    await fetchTable({
      limit: rowsPerPage,
      page: 0,
      searchQuery: defaultSearchQuery,
    });
  };
  const filters = filtersFunc(analysts, isLoadingAnalysts);

  return (
    <ContentWrapper>
      {isLoadingTable && <Preloader overlay />}
      <Filters
        clear={clear}
        search={() => handleChangePage(0)}
        searchQuery={searchQuery}
        setSearchQuery={(valueObj) =>
          setSearchQuery({ ...searchQuery, ...valueObj })
        }
        isOpen={isFiltersOpen}
        setIsOpen={setIsFiltersOpen}
        filters={filters}
        containerClass="no-margin"
      />
      <Table
        count={tableDataCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        columns={tableColumns}
        data={tableData}
        columnActions={columnActions}
        excelProps={{
          excelData,
          isLoading: isLoadingExcel,
          excelFileName: 'ContentReport',
          onExcelDownload: () => fetchTable({ searchQuery, excel: true }),
        }}
        withExcel
      />
    </ContentWrapper>
  );
};

export default inject('contentReportsStore')(observer(ContentReports));
