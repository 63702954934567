import React from 'react';
import { inject, observer } from 'mobx-react';
import Swipe from 'react-easy-swipe';

import { CSSTransition } from 'react-transition-group';
import useBreakpoint from '@root/hooks/useBreakpoint';
import {
  MobileLogoContainer,
  LogoIcon,
  LogoText,
  MobileContainer,
  TopBar,
  TopBarContent,
  TopBarLabel,
  MobileScrollableContent,
} from '@components/SideBar/styles';
import logo from '@app/assets/logo.svg';
import MenuItem from '@components/SideBar/components/MenuItem';
import MenuItemToggable from '@components/SideBar/components/MenuItemToggable';
import OpenCloseMenuMobile from '@components/SideBar/components/OpenCloseMenuMobile';
import { UserIconContainer } from '@components/UserMenu/style';
import Preloader, { POSITIONS, SIZES } from '@components/Preloader';
import UserMenu from '@components/UserMenu';

const MobileSideBar = ({
  menuItems,
  mobileLabel,
  sidebarStore: { mobileMenuOpened, setMobileMenuOpened },
  authStore: { isLoadingUser, user },
}) => {
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  const swipeStyle = {
    width: '100%',
    overflowY: 'scroll',
    height: isMobile ? '100%' : 'inherit',
  };

  return (
    <TopBar>
      <OpenCloseMenuMobile
        setMenuOpen={setMobileMenuOpened}
        menuOpen={mobileMenuOpened}
      />
      <TopBarContent>
        <TopBarLabel>{mobileLabel}</TopBarLabel>
        <UserIconContainer>
          {isLoadingUser || !user?.fullName ? (
            <Preloader display={POSITIONS.HORIZONRAL} size={SIZES.SMALL} />
          ) : (
            <UserMenu
              fullName={user.fullName}
              firstName={user.firstName}
              lastName={user.lastName}
              userEmail={user.email}
            />
          )}
        </UserIconContainer>
      </TopBarContent>

      <CSSTransition
        timeout={300}
        in={mobileMenuOpened}
        classNames="slidein"
        unmountOnExit
      >
        <MobileContainer>
          <Swipe
            onSwipeLeft={() => isMobile && setMobileMenuOpened(false)}
            style={swipeStyle}
            tolerance={70}
          >
            <MobileLogoContainer>
              <LogoIcon>
                <img alt="logo" src={logo} style={{ width: 50, height: 32 }} />
              </LogoIcon>
              <LogoText>Popcorn</LogoText>
            </MobileLogoContainer>
            <MobileScrollableContent>
              {menuItems.map(({ label, icon, path, submenuItemsKey, key }) =>
                path ? (
                  <MenuItem
                    key={`menu-item_${key}`}
                    path={path}
                    label={label}
                    icon={icon}
                    parent={key}
                  />
                ) : (
                  <MenuItemToggable
                    key={`menu-item_${key}`}
                    label={label}
                    submenuItemsKey={submenuItemsKey}
                    icon={icon}
                    parent={key}
                  />
                ),
              )}
            </MobileScrollableContent>
          </Swipe>
        </MobileContainer>
      </CSSTransition>
    </TopBar>
  );
};

export default inject('sidebarStore', 'authStore')(observer(MobileSideBar));
