import styled from '@emotion/styled';

export const Label = styled.div`
  display: flex;
  text-align: center;
  font-weight: bold;
  min-width: 250px;
  width: 250px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.font.default};
`;

export const Value = styled.div`
  min-height: 22px;
  max-height: 66px;
  overflow-y: auto;
  overflow-x: hidden;
  font-weight: bold;
  color: ${({ theme }) => theme.font.default};
  text-decoration: ${({ clickable }) => (clickable ? `underline` : '')};
`;

export const Field = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  cursor: ${({ clickable }) => (clickable ? `pointer` : '')};
  line-height: 26px;

  &.list {
    margin: 2px 0;
    line-height: 22px;
  }
`;
