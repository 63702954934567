import React from 'react';
import {
  LegendButton,
  LegendContainer,
  List,
  ListItem,
  Svg,
} from '@components/LineChart/styles';

export const CustomLegend = ({ payload, series, setSeries, labels }) => (
  <LegendContainer>
    <List>
      {payload.map((data, index) => (
        <LegendButton
          onKeyDown={() => {}}
          role="button"
          tabIndex="0"
          key={`custom-legend-list-item_${index}`}
          onClick={() => {
            if (series.indexOf(data.value) >= 0) {
              setSeries(series.filter((key) => key !== data.value));
            } else {
              setSeries([data.value.trim(), ...series]);
            }
          }}
        >
          <ListItem>
            <Svg width="14" height="14" viewBox="0 0 32 32" version="1.1">
              <circle cx="12" cy="12" r="12" stroke="none" fill={data.color} />
            </Svg>
            <span>{labels[data.value?.trim()]}</span>
          </ListItem>
        </LegendButton>
      ))}
    </List>
  </LegendContainer>
);
