/* eslint-disable import/no-cycle */
import React from 'react';
import GraphTab from '@containers/CompaniesRelations/components/GraphTab';
import TableTab from '@containers/CompaniesRelations/components/TableTab';

export const relations = [
  { label: 'Secondary', color: 'red' },
  { label: 'Portfolio', color: 'green' },
  { label: 'Peer', color: 'blue' },
];

export const graphOptions = (groups) => ({
  nodes: {
    shape: 'dot',
    size: 16,
  },
  height: '100%',
  physics: {
    maxVelocity: 50,
    timestep: 0.5,
  },
  edges: {
    width: 0.15,
    color: { inherit: 'from' },
    smooth: {
      type: 'continuous',
    },
    chosen: {
      edge: true,
    },
  },
  interaction: {
    tooltipDelay: 200,
    navigationButtons: true,
  },
  groups,
});

export const tabs = [
  { label: 'Graphic relations', content: <GraphTab /> },
  { label: 'Companies', content: <TableTab /> },
];

export const tableColumns = [
  {
    id: 'name',
    label: 'Content Order',
  },
  {
    id: 'subscription_name',
    label: 'Subscription',
  },
  {
    id: 'internal_users',
    label: 'Internal users',
  },
  {
    id: 'external_users',
    label: 'External users',
  },
  {
    id: 'primary_companies',
    label: 'Primary companies',
    format: (value) => value.join(', '),
  },
  {
    id: 'secondary_companies',
    label: 'Secondary companies',
    format: (value) => value.join(', '),
  },
  {
    id: 'peer_companies',
    label: 'Peer companies',
    format: (value) => value.join(', '),
  },
  {
    id: 'portfolio_companies',
    label: 'Portfolio companies',
    format: (value) => value.join(', '),
  },
];

export const boolOptions = [
  { label: `All`, value: 'all' },
  { label: `Yes`, value: true },
  { label: `No`, value: false },
];

export const filters = [
  {
    id: 'content_order_name',
    label: 'Content order name',
    type: 'input',
  },
  {
    id: 'active',
    label: 'Active',
    type: 'select',
    options: boolOptions,
  },
];

export const defaultSearchQuery = {
  active: true,
  active_select_option: { label: 'Yes', value: true },
};

export const basicExcelColumns = [
  {
    id: 'name',
    label: 'Content Order',
  },
  {
    id: 'subscription_name',
    label: 'Subscription',
  },
  {
    id: 'internal_users',
    label: 'Internal users',
  },
  {
    id: 'external_users',
    label: 'External users',
  },
];
