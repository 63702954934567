import styled from '@emotion/styled';

export const SENTIMENTS_PALETTE = {
  positive: '#07BF24',
  slightlyPositive: '#67C575',
  neutral: '#FB8C00',
  slightlyNegative: '#EC8D70',
  negative: '#E64A19',
  unset: '#29235C',
};

export const ShowContent = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

export const SentimentalListWrapper = styled.div`
  & .details {
    border-left: 6px solid ${SENTIMENTS_PALETTE.unset};
    border-radius: 3px 0 0 3px;
  }

  & .details.sentiment-positive {
    border-color: ${SENTIMENTS_PALETTE.positive};
  }

  & .details.sentiment-slightly-positive {
    border-color: ${SENTIMENTS_PALETTE.slightlyPositive};
  }

  & .details.sentiment-neutral {
    border-color: ${SENTIMENTS_PALETTE.neutral};
  }

  & .details.sentiment-slightly-negative {
    border-color: ${SENTIMENTS_PALETTE.slightlyNegative};
  }

  & .details.sentiment-negative {
    border-color: ${SENTIMENTS_PALETTE.negative};
  }
`;

export const SentimentFieldContent = styled.span``;
