import React, { useEffect, useState } from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import ChartTooltip from '@components/ChartTooltip';
import { CustomLegend } from './components/Legend';

const CustomLineChart = ({
  data,
  xAxisKey,
  keys,
  dot = false,
  connectNulls = false,
  tickFormatter,
  dateFormatTooltip,
  yTickFormatter,
  width = '100%',
}) => {
  const [series, setSeries] = useState(keys.map(({ key }) => key));

  useEffect(() => {
    setSeries(keys.map(({ key }) => key));
  }, [keys]);

  const labels = {};
  keys.forEach((key) => {
    labels[key.key] = key.label;
  });

  return (
    <ResponsiveContainer width={width} height={375}>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="4 3" stroke="rgba(0,0,0,.1)" />
        <XAxis
          tick={{ fill: 'black' }}
          tickFormatter={tickFormatter}
          dataKey={xAxisKey}
        />
        <YAxis tick={{ fill: 'black' }} tickFormatter={yTickFormatter} />
        <Tooltip
          wrapperStyle={{ zIndex: 9, outline: 'none' }}
          labels={labels}
          content={
            <ChartTooltip
              valueFormatter={yTickFormatter}
              dateFormat={xAxisKey === 'date' && dateFormatTooltip}
            />
          }
        />
        <Legend
          content={
            <CustomLegend
              series={series}
              setSeries={setSeries}
              labels={labels}
            />
          }
        />
        {keys.map(
          ({ key, color, show }) =>
            show && (
              <Line
                strokeWidth={4}
                connectNulls={connectNulls}
                dot={dot}
                key={`line-${key}`}
                dataKey={series.indexOf(key) >= 0 ? key : `${key} `}
                stroke={color}
              />
            ),
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default CustomLineChart;
