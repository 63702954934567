/* eslint-disable import/no-cycle */
import React, { useEffect, useMemo, useState } from 'react';
import { inject, observer } from 'mobx-react';
import Preloader from '@components/Preloader';
import {
  tableColumns,
  filters,
  defaultSearchQuery,
  basicExcelColumns,
} from '@containers/CompaniesRelations/constants';
import Filters from '@components/Filters';
import List from '@components/List';
import useBreakpoint from '@root/hooks/useBreakpoint';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';

const TableTab = ({
  companiesRelationsStore: {
    tableData,
    fetchTable,
    isLoadingTable,
    totalRowsNumber,
    excelData,
    maxCompaniesCount,
    isLoadingExcel,
  },
}) => {
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  const [searchQuery, setSearchQuery, getCleanSearchQuery] =
    useCustomSearchParams(defaultSearchQuery);
  const [isFiltersOpen, setIsFiltersOpen] = useState();
  const [page, setPage] = useState(1);

  useEffect(() => {
    fetchTable({ limit: 20, page: 0, searchQuery });
  }, []);

  const handleChangePage = async (newPage, shouldClear) => {
    const query = shouldClear ? defaultSearchQuery : getCleanSearchQuery();
    if (shouldClear) {
      setSearchQuery(query);
    }
    setPage(newPage);
    await fetchTable({
      limit: 20,
      page: newPage,
      searchQuery: query,
    });
  };

  const excelColumns = useMemo(() => {
    const columns = [...basicExcelColumns];
    for (let i = 0; i <= maxCompaniesCount; i += 1) {
      columns.push({
        id: `Company ${i}`,
        label: `Company ${i}`,
      });
    }
    return columns;
  }, [maxCompaniesCount]);

  return (
    <>
      {isLoadingTable ? <Preloader overlay /> : null}
      <Filters
        clear={() => handleChangePage(1, true)}
        search={() => handleChangePage(1)}
        searchQuery={searchQuery}
        setSearchQuery={(valueObj) =>
          setSearchQuery({ ...searchQuery, ...valueObj })
        }
        isOpen={isFiltersOpen}
        setIsOpen={setIsFiltersOpen}
        filters={filters}
        containerClass="medium-margin"
      />
      <List
        isMobile={isMobile}
        data={tableData}
        fields={tableColumns}
        currentPage={page}
        dataCount={totalRowsNumber}
        pageSize={20}
        onPageChange={handleChangePage}
        withExcel
        excelProps={{
          useExcelColumns: true,
          excelData,
          excelColumns,
          isLoading: isLoadingExcel,
          excelFileName: 'CompaniesAndPeers',
          onExcelDownload: () => {
            fetchTable({
              searchQuery,
              excel: true,
            });
          },
        }}
      />
    </>
  );
};

export default inject('companiesRelationsStore')(observer(TableTab));
