import moment from 'moment';

export const getDatesFromTimeframe = (timeframe) => {
  let startDate;
  let endDate;

  switch (timeframe) {
    case 'week':
      startDate = moment().startOf('isoWeek');
      endDate = moment().endOf('isoWeek');
      break;
    case 'last_week':
      startDate = moment().subtract(1, 'week').startOf('isoWeek');
      endDate = moment().subtract(1, 'week').endOf('isoWeek');
      break;
    case 'month':
      startDate = moment().subtract(4, 'week').startOf('isoWeek');
      endDate = moment().subtract(1, 'week').endOf('isoWeek');
      break;
    default:
      startDate = moment().isoWeekday(-6);
      endDate = startDate.endOf('isoWeek');
      break;
  }

  return {
    timeFrom: startDate.toDate(),
    timeTo: endDate.toDate(),
  };
};
