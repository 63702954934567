import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useParams } from 'react-router';
import { SubTitle, ContentWrapper } from '@styles/globalStyles';
import Text, { TAGS } from '@components/Text';
import DetailsMobile from '@components/DetailsMobile';
import Details from '@components/Details';
import useBreakpoint from '@root/hooks/useBreakpoint';
import Preloader from '@components/Preloader';
import { fields } from '@containers/ClientCoverages/constants';

const ClientCoverage = ({
  clientCoveragesStore: {
    clientCoverage,
    fetchClientCoverage,
    isLoadingClientCoverage,
    clearClientCoverage,
  },
}) => {
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchClientCoverage(id);
    }
    return () => clearClientCoverage();
  }, []);

  if (isLoadingClientCoverage) {
    return <Preloader overlay />;
  }

  return (
    <ContentWrapper>
      <SubTitle>
        <Text tag={TAGS.H2}>Client Coverage: {clientCoverage.id}</Text>
      </SubTitle>
      {isMobile ? (
        <DetailsMobile data={clientCoverage} fields={fields} big />
      ) : (
        <Details data={clientCoverage} fields={fields} />
      )}
    </ContentWrapper>
  );
};

export default inject('clientCoveragesStore')(observer(ClientCoverage));
