import { makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';
import API from '@app/api';
import apiRoutes from '@app/apiRoutes';
import { log, TYPE } from '@utils/logger';

export class NotificationReportsStore {
  isLoadingTable = false;

  isLoadingEmailStatistics = false;

  tableData = [];

  constructor() {
    makeAutoObservable(this);
  }

  // eslint-disable-next-line class-methods-use-this
  fetchSubscriptions = async (subscription) => {
    try {
      const { data } = await API.get(
        apiRoutes.notificationReports.subscriptions('all'),
        {
          params: { subscription },
        },
      );
      return (data?.contracts || []).map(({ id: value, name: label }) => ({
        value,
        label,
      }));
    } catch (e) {
      log(e.message, e, TYPE.ERROR);
    }
    return [];
  };

  fetchTable = async ({ searchQuery }) => {
    const { subscriptionId, ...searchQueryRest } = searchQuery;
    if (!subscriptionId) {
      return;
    }
    this.isLoadingTable = true;
    try {
      const response = await API.get(
        apiRoutes.notificationReports.subscriptions(subscriptionId),
        {
          params: searchQueryRest,
        },
      );

      this.tableData = response.data.users;
    } catch (e) {
      log(e.message, e, TYPE.ERROR);
    } finally {
      this.isLoadingTable = false;
    }
  };

  emailContractsStatistics = async (searchQuery) => {
    this.isLoadingEmailStatistics = true;
    try {
      await API.get(apiRoutes.notificationReports.emailContractsStatistics, {
        params: searchQuery,
      });

      toast.success('Statistics will be provided via email!');
    } catch (e) {
      log(e.message, e, TYPE.ERROR);
      toast.error('Something went wrong');
    } finally {
      this.isLoadingEmailStatistics = false;
    }
  };
}

export default new NotificationReportsStore();
