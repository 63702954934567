import styled from '@emotion/styled';

export const StyledWebLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;

  & svg {
    height: 2ex;
    margin-bottom: -1px;
  }
`;
