import moment from 'moment';
import { CHART_COLORS } from '@root/constants/common';

export const filters = [
  {
    startDateId: 'date_from',
    endDateId: 'date_to',
    showTime: false,
    label: 'Date range',
    type: 'dateRange',
  },
];

export const defaultSearchQuery = {
  date_from: moment().startOf('day').subtract(1, 'week').toDate(),
  date_to: moment().endOf('day').add(1, 'day').toDate(),
};

export const tableColumns = [
  {
    id: 'timestamp',
    label: 'Date',
    format: (value) => moment(value).format('LLL').toString(),
  },
  {
    id: 'user_email',
    label: 'User email',
  },
  {
    id: 'medium',
    label: 'Medium',
  },
  {
    id: 'action',
    label: 'Action',
  },
];

export const chartKeys = [
  { key: 'traffic', label: 'Traffic', show: true, color: CHART_COLORS[0] },
];

export const pieChartKeys = [
  { key: 'web', label: 'Web', show: true, color: CHART_COLORS[8] },
  { key: 'ios', label: 'iOS', show: true, color: CHART_COLORS[15] },
  { key: 'android', label: 'Android', show: true, color: CHART_COLORS[0] },
  { key: 'email', label: 'Email', show: true, color: CHART_COLORS[22] },
];
