import React from 'react';
import {
  LegendTable,
  LegendTableData,
  LegendTableHead,
} from '@containers/ArticleSourcesMatrix/styles';

export const legendValue = [
  { label: null, val: 1, color: 'transparent' },
  { label: '< 2', val: 2, color: 'rgba(245,204,205,255)' },
  { label: '2-5', val: 5, color: 'rgba(255,243,204,255)' },
  { label: '5-10', val: 10, color: 'rgba(183,215,168,255)' },
  { label: '10-15', val: 15, color: 'rgba(147,197,125,255)' },
  { label: '15-20', val: 20, color: 'rgba(106,169,80,255)' },
  { label: '20-25', val: 25, color: 'rgba(57,118,29,255)' },
  { label: '> 25', val: Infinity, color: 'rgba(39,78,19,255)' },
];

export const subLegendValue = [
  { label: '< 0', val: 0, color: 'rgb(255,0,0)' },
  { label: null, val: Infinity, color: 'transparent' },
];

const HeatMapLegend = () => (
  <LegendTable>
    <thead>
      <tr>
        <LegendTableHead>Articles per hour</LegendTableHead>
      </tr>
    </thead>
    <tbody>
      {legendValue
        .filter(({ val }) => val !== 1)
        .map(({ color, label }) => (
          <tr key={`legend_val_${label}`}>
            <LegendTableData bg={color}>{label}</LegendTableData>
          </tr>
        ))}
    </tbody>
  </LegendTable>
);

export default HeatMapLegend;
