import React, { useMemo } from 'react';
import {
  Bar,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
  BarChart as Chart,
  ResponsiveContainer,
  Rectangle,
} from 'recharts';
import styled from '@emotion/styled';
import { dateTickFormatter, nonZeroKeys } from '@utils/chartHelpers';
import ChartTooltip from '@components/ChartTooltip';
import { CHART_COLORS } from '@root/constants/common';

const ChartContainer = styled.div`
  width: 100%;
  padding-bottom: 10px;
  height: 400px;
`;

const BarChart = ({
  isMobile,
  data,
  keys,
  dataKey,
  xAxisLabel,
  dateFormat,
  yAxisLabel,
  dateFormatTooltip,
  maxBarWidth,
}) => {
  const filteredKeys = useMemo(
    () => nonZeroKeys({ keys, data }) || [],
    [keys, data],
  );

  return (
    <ChartContainer>
      <ResponsiveContainer width="100%" height={400}>
        <Chart
          margin={{
            left: isMobile ? -5 : 10,
          }}
          data={data}
        >
          <CartesianGrid strokeDasharray="4 3" stroke="rgba(0,0,0,.1)" />
          <XAxis
            tick={{ fill: 'black' }}
            height={50}
            dataKey={dataKey}
            stroke="#aaaaaa"
            tickFormatter={(tick) =>
              dateTickFormatter(tick, dataKey, dateFormat)
            }
            label={{
              value: xAxisLabel,
              offset: 10,
              position: 'insideBottom',
              fill: '#aaaaaa',
              dx: -10,
            }}
          />
          <YAxis
            tick={{ fill: 'black' }}
            stroke="#aaaaaa"
            label={{
              value: yAxisLabel,
              angle: -90,
              offset: 10,
              position: 'center',
              fill: '#000000',
              dx: -35,
            }}
            allowDecimals={false}
            domain={[0, 'auto']}
            allowDataOverflow
            type="number"
          />
          <Tooltip
            wrapperStyle={{ zIndex: 9, outline: 'none' }}
            content={
              <ChartTooltip
                dateFormat={dataKey === 'date' && dateFormatTooltip}
              />
            }
            cursor={{ fill: '#e0e0e0aa' }}
          />
          {filteredKeys.map((key, index) => (
            <Bar
              key={`bar-chart-bar_${key.name}`}
              dataKey={key.name}
              fill={CHART_COLORS[index % 33]}
              shape={(props) => {
                const newProps = props;
                if (newProps.width > maxBarWidth) {
                  newProps.x += newProps.width / 2 - maxBarWidth / 2;
                  newProps.width = maxBarWidth;
                }
                return <Rectangle {...newProps} />;
              }}
            />
          ))}
        </Chart>
      </ResponsiveContainer>
    </ChartContainer>
  );
};

BarChart.propTypes = {};

export default BarChart;
