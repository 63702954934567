import React, { useState } from 'react';
import moment from 'moment';
import WebLink from '@components/WebLink';
import ContentDialog from '@components/Modal/ContentDialog';
import { ShowContent } from './styles';

const orders = [
  { value: 'desc', label: 'Descending' },
  { value: 'asc', label: 'Ascending' },
];

export const filtersFunc = () => [
  {
    startDateId: 'pubdate_from',
    endDateId: 'pubdate_to',
    showTime: false,
    label: 'Publication date range',
    type: 'dateRange',
  },
  {
    id: 'order',
    label: 'Order',
    type: 'select',
    options: orders,
  },
  {
    id: 'title',
    label: 'Title',
    type: 'input',
    className: 'big',
  },
  {
    id: 'summary',
    label: 'Summary',
    type: 'input',
    className: 'big',
  },
  {
    id: 'author',
    label: 'Author',
    type: 'input',
    className: 'big',
  },
  {
    id: 'url',
    label: 'URL',
    type: 'input',
    className: 'big',
  },
  {
    id: 'orig_guid',
    label: 'Original GUID',
    type: 'input',
    className: 'big',
  },
  {
    id: 'article_source_id',
    label: 'Article Source ID',
    type: 'input',
    className: 'big',
  },
  {
    id: 'content',
    label: 'Content',
    type: 'input',
    className: 'big',
  },
  {
    id: 'nla_link',
    label: 'NLA Link',
    type: 'input',
    className: 'big',
  },
];

export const defaultSearchQuery = {
  pubdate_from: moment().startOf('day').subtract(1, 'month').toDate(),
  pubdate_to: moment().endOf('day').toDate(),
  order: 'desc',
  order_select_option: orders[0],
};

const dateFormat = (value) => moment(value).format('D/M/YYYY h:mm:ss A');

export const fields = [
  { id: '_id', format: (value) => value.$oid || '(none)' },
  { id: 'url', label: 'URL:', format: (value) => <WebLink url={value} /> },
  { id: 'title' },
  { id: 'summary' },
  { id: 'orig_guid', label: 'Original GUID:' },
  { id: 'article_source_id' },
  { id: 'author' },
  { id: 'sentiment_score' },
  { id: 'lang', label: 'Language:' },
  { id: 'pubdate', label: 'Published at:', format: dateFormat },
  { id: 'created_at', format: dateFormat },
  { id: 'updated_at', format: dateFormat },
  { id: 'processed_at', format: dateFormat },
  { id: 'version' },
  {
    id: 'content',
    format: (value) => {
      const [dialogOpen, setDialogOpen] = useState(false);

      return (
        <ShowContent onClick={() => setDialogOpen(true)}>
          Show article content
          <ContentDialog
            value={value}
            title="Article content"
            isOpen={dialogOpen}
            closeHandler={() => setDialogOpen(false)}
          />
        </ShowContent>
      );
    },
  },
];
