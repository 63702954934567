import React from 'react';
import styled from '@emotion/styled';
import { ContentWrapper } from '@styles/globalStyles';
import useApi from '@root/hooks/useApi';
import Preloader from '@components/Preloader';
import apiRoutes from '@app/apiRoutes';

const Status = styled.div`
  color: ${({ theme }) => theme.colors.primary};

  &.error {
    color: ${({ theme }) => theme.colors.error};
  }
`;

const HealthCheck = () => {
  const { data, loading } = useApi(apiRoutes.healthCheck);
  return (
    <ContentWrapper>
      {loading && <Preloader overlay />}
      <Status className={ data?.status === 500 && 'error' }>
        {data?.message || '(no info)'}
      </Status>
    </ContentWrapper>
  );
};

export default HealthCheck;
