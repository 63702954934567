import styled from '@emotion/styled';

export const LinkButton = styled.div`
  color: ${({ theme }) => theme.font.default};
  font-size: ${({ theme }) => theme.defaults.fontSizeXL}px;
  cursor: pointer;
`;

export const LinkContainer = styled.div`
  margin: 15px 15px;
`;
