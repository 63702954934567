import moment from 'moment';

const HOURS = 24;
const DAYS = 7;

export const createMatrix = (data) => {
  const prepareMatrix = new Array(HOURS)
    .fill(0)
    .map(() => new Array(DAYS).fill(0).map(() => 0));

  data.forEach((m) => {
    const x = m.time.day;
    const y = m.time.hour;
    prepareMatrix[y][x] = m.numberOfArticles;
  });

  return prepareMatrix;
};

export const subtractMatrixes = (data, avgData) => {
  const matrix = createMatrix(data);
  const avgMatrix = createMatrix(avgData);

  const currentDay = moment().isoWeekday() - 1;
  const currentHour = moment().hour();

  return matrix.map((hoursRows, y) =>
    hoursRows.map((dayColumn, x) => {
      if (x > currentDay || (x === currentDay && y >= currentHour)) {
        return 0;
      }
      return (dayColumn - avgMatrix[y][x]).toFixed(2);
    }),
  );
};
