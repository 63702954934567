import { makeAutoObservable } from 'mobx';
import moment from 'moment';
import API from '@app/api';
import apiRoutes from '@app/apiRoutes';
import { log, TYPE } from '@utils/logger';

export class NewspapersStore {
  isLoadingArticleSources = false;

  articleSources = [];

  isLoadingTable = false;

  tableData = [];

  tableDataCount = 0;

  articleSourcesObj = {};

  excelData = [];

  isLoadingExcel = false;

  constructor() {
    makeAutoObservable(this);
  }

  fetchArticleSources = async () => {
    this.isLoadingArticleSources = true;
    try {
      const { data } = await API.get(apiRoutes.newspapers.articleSources);
      this.articleSourcesObj = {};
      this.articleSources = data.article_sources.map(({ id, title }) => {
        this.articleSourcesObj[id] = title;
        return {
          label: `${title} (${id})`,
          value: id,
        };
      });

      this.isLoadingArticleSources = false;
    } catch (e) {
      log(e.message, e, TYPE.ERROR);
    }
  };

  fetchTable = async ({ limit, page, searchQuery, excel }) => {
    this.isLoadingTable = !excel;
    this.isLoadingExcel = excel;
    const extendedQuery = {
      update_date_from: searchQuery.creation_date_from,
      update_date_to: moment().endOf('day').toDate(),
      ...searchQuery,
    };

    let query;
    if (excel) {
      query = {
        limit: 1000,
        page: 0,
        ...extendedQuery,
      };
    } else {
      query = {
        limit: parseInt(limit ? limit.toString() : 10, 10),
        page: parseInt(page || 0, 10),
        ...extendedQuery,
      };
    }
    try {
      const response = await API.get(apiRoutes.newspapers.index, {
        params: query,
      });

      if (excel) {
        this.excelData = response.data.newspapers;
      } else {
        this.tableData = response.data.newspapers;
        this.tableDataCount = response.data.count;
        this.excelData = [];
      }
    } catch (e) {
      log(e.message, e, TYPE.ERROR);
    } finally {
      this.isLoadingTable = false;
      this.isLoadingExcel = false;
    }
  };
}

export default new NewspapersStore();
