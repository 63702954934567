import { makeAutoObservable } from 'mobx';
import moment from 'moment/moment';
import API from '@app/api';
import apiRoutes from '@app/apiRoutes';

export class UsersStore {
  countersValues = {};

  events = [];

  chartData = [];

  isLoadingBarChart = false;

  isLoadingCounters = false;

  isLoadingEvents = false;

  eventsCount = 0;

  totalPages = 0;

  eventTypes = [];

  isLoadingEventTypes = false;

  mediums = [];

  isLoadingMediums = false;

  constructor() {
    makeAutoObservable(this);
  }

  fetchEvents = async ({ page, searchQuery }) => {
    this.isLoadingEvents = true;
    const query = {
      limit: 20,
      page: parseInt(page ? page.toString() : 1, 10),
      ...searchQuery,
    };

    try {
      const {
        data: { data, itemsTotal, pagesTotal },
      } = await API.get(apiRoutes.users.events, {
        params: query,
      });
      this.events = data;
      this.eventsCount = itemsTotal;
      this.totalPages = pagesTotal;
    } catch (e) {
      this.error = e;
    } finally {
      this.isLoadingEvents = false;
    }
  };

  fetchCounters = async ({ searchQuery }) => {
    this.isLoadingCounters = true;
    const localSearchQuery = {
      ...searchQuery,
      from: moment(searchQuery.from).format('LLL'),
      to: moment(searchQuery.to).format('LLL'),
    };
    try {
      const { data } = await API.get(apiRoutes.users.counters, {
        params: localSearchQuery,
      });
      data.uniqueEmailsOpen = `${data.uniqueEmailsOpen} (${
        data.emailsSent !== 0
          ? `${((data.uniqueEmailsOpen / data.emailsSent) * 100).toFixed(2)}%`
          : 'N/A'
      })`;
      this.countersValues = data;
    } catch (e) {
      this.error = e;
    } finally {
      this.isLoadingCounters = false;
    }
  };

  fetchBarChart = async ({ searchQuery }) => {
    this.isLoadingBarChart = true;
    const localSearchQuery = {
      ...searchQuery,
      from: moment(searchQuery.from).format('LLL'),
      to: moment(searchQuery.to).format('LLL'),
    };
    try {
      const { data } = await API.get(apiRoutes.users.chart, {
        params: localSearchQuery,
      });
      this.chartData = data.map(({ total, name }) => ({ total, date: name }));
    } catch (e) {
      this.error = e;
    } finally {
      this.isLoadingBarChart = false;
    }
  };

  fetchUserEmails = async (email) => {
    try {
      const { data } = await API.get(apiRoutes.users.emails, {
        params: { email },
      });
      return data.map((value) => ({ value, label: value }));
    } catch (e) {
      this.error = e;
    }
    return [];
  };

  fetchSubscriptions = async (subscription) => {
    try {
      const { data } = await API.get(apiRoutes.users.subscriptions, {
        params: { subscription },
      });
      return data.map((value) => ({ value: value.trim(), label: value }));
    } catch (e) {
      this.error = e;
    }
    return [];
  };

  fetchEventTypes = async () => {
    this.isLoadingEventTypes = true;
    try {
      const { data } = await API.get(apiRoutes.users.types);
      this.eventTypes = data;
    } catch (e) {
      this.error = e;
    } finally {
      this.isLoadingEventTypes = false;
    }
  };

  fetchMediums = async () => {
    this.isLoadingMediums = true;
    try {
      const { data } = await API.get(apiRoutes.users.mediums);
      this.mediums = data;
    } catch (e) {
      this.error = e;
    } finally {
      this.isLoadingMediums = false;
    }
  };
}

export default new UsersStore();
