import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';

import {
  Container,
  LogoContainer,
  LogoIcon,
  LogoText,
  ScrollableContent,
} from '@components/SideBar/styles';
import logo from '@app/assets/logo.svg';
import MenuItem from '@components/SideBar/components/MenuItem';
import MenuItemToggable from '@components/SideBar/components/MenuItemToggable';
import OpenCloseMenu from '@components/SideBar/components/OpenCloseMenu';

const DesktopSideBar = ({
  menuItems,
  sidebarStore: { menuCollapsed, setMenuCollapsed },
}) => (
  <Container menuCollapsed={menuCollapsed}>
    <LogoContainer>
      <LogoIcon>
        <img alt="logo" src={logo} />
      </LogoIcon>
      <LogoText>Popcorn</LogoText>
    </LogoContainer>

    <ScrollableContent autoHeight autoHeightMin={300} autoHeightMax="100%">
      {menuItems.map(({ label, key, icon, path, submenuItemsKey }) =>
        path ? (
          <MenuItem
            key={`menu-item_${key}`}
            path={path}
            label={label}
            icon={icon}
            parent={key}
          />
        ) : (
          <MenuItemToggable
            key={`menu-item_${key}`}
            label={label}
            submenuItemsKey={submenuItemsKey}
            icon={icon}
            parent={key}
          />
        ),
      )}
      <OpenCloseMenu
        setMenuCollapsed={setMenuCollapsed}
        menuCollapsed={menuCollapsed}
      />
    </ScrollableContent>
  </Container>
);

DesktopSideBar.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      key: PropTypes.string,
      icon: PropTypes.any,
      submenuItemsKey: PropTypes.string,
    }),
  ).isRequired,
  sidebarStore: PropTypes.shape({
    menuCollapsed: PropTypes.bool,
    setMenuCollapsed: PropTypes.func,
  }).isRequired,
};

export default inject('sidebarStore')(observer(DesktopSideBar));
