/* eslint-disable import/no-cycle */
import React, { useEffect, useMemo } from 'react';
import Graph from 'react-vis-network-graph';
import { inject, observer } from 'mobx-react';
import { ArrowBigRight } from 'tabler-icons-react';
import {
  graphOptions,
  relations,
} from '@containers/CompaniesRelations/constants';
import {
  GraphContainer,
  LegendItem,
  Circle,
  LegendContainer,
} from '@containers/CompaniesRelations/styles';
import Preloader from '@components/Preloader';

const NetworkGraph = ({
  companiesRelationsStore: { nodes, edges, fetchGraph, groups, isLoading },
}) => {
  useEffect(() => {
    fetchGraph();
  }, []);

  const options = useMemo(() => graphOptions(groups), [groups]);

  return (
    <GraphContainer>
      {isLoading ? <Preloader overlay /> : null}
      <LegendContainer>
        {relations.map(({ label, color }) => (
          <LegendItem>
            <ArrowBigRight fill={color} stroke={color} />
            {label} Company
          </LegendItem>
        ))}
        {Object.keys(groups).map((key) => (
          <LegendItem>
            <Circle color={groups[key].color} />
            {key}
          </LegendItem>
        ))}
      </LegendContainer>
      <Graph
        graph={{
          nodes,
          edges,
        }}
        options={options}
      />
    </GraphContainer>
  );
};

export default inject('companiesRelationsStore')(observer(NetworkGraph));
