import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { ContentWrapper } from '@styles/globalStyles';
import Table from '@components/Table';
import Preloader from '@components/Preloader';
import { tableColumns } from '@containers/BriefingTypesReport/constants';

const BriefingTypesReport = ({
  briefingTypesReportStore: {
    fetchTable,
    tableData,
    isLoadingTable,
  },
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  useEffect(() => {
    handleChangePage(0);
    fetchTable();
  }, []);

  const handleChangePage = async (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = async (value) => {
    setRowsPerPage(value);
    setPage(0);
  };

  const offset = rowsPerPage * page
  const dataPart = tableData.slice(offset, offset + rowsPerPage)

  return (
    <ContentWrapper>
      {isLoadingTable && <Preloader overlay />}
      <Table
        count={tableData.length}
        rowsPerPageOptions={[15, 50, 100]}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        columns={tableColumns}
        data={dataPart}
        withExcel
        excelProps={{
          excelData: tableData,
          excelFileName: 'BriefingTypesReport',
          isLoading: isLoadingTable,
          onExcelDownload: () => {},
        }}
      />
    </ContentWrapper>
  );
};

export default inject('briefingTypesReportStore')(observer(BriefingTypesReport));
