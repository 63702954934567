import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import Input from '@components/Input';
import { CollapsibleFilters } from '@components/CollapsibleFilters';
import Select from '@components/Select';
import DateRangeSelector from '@components/DateRangeSelector';
import AsyncSelect from '@components/AsyncSelect';
import Button from '@components/Button';

export const SelectContainer = styled.div`
  min-width: 150px;
  height: 65px;

  &.big {
    min-width: 300px;
  }

  & > button {
    margin-top: 19px;
  }
`;

const Filters = ({
  setSearchQuery: setExternalSearchQuery,
  searchQuery,
  search,
  clear,
  isOpen,
  setIsOpen,
  filters,
  containerClass,
  autoFilter = false,
}) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      search();
    }
  };

  const setSearchQuery = (value) => {
    setExternalSearchQuery(value);
    if (autoFilter) {
      search(value);
    }
  };

  return (
    <CollapsibleFilters
      clear={clear}
      search={search}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      containerClass={containerClass}
      hideButtons={autoFilter}
    >
      {filters.map((filter, idx) => (
        <Fragment key={`filter-${idx}`}>
          {filter.type === 'input' ? (
            <Input
              value={searchQuery[filter.id] || ''}
              onChange={(value) => setSearchQuery({ [filter.id]: value })}
              label={filter.label}
              placeholder={`${filter.placeholder || filter.label}...`}
              noMargin
              maxWidth={150}
              onKeyDown={handleKeyDown}
              inputType={filter.inputType === 'number' ? 'number' : 'text'}
            />
          ) : null}
          {filter.type === 'select' ? (
            <SelectContainer className={filter.className}>
              <Select
                isMulti={filter.isMulti}
                isLoading={filter.isLoading}
                isClearable={filter.isClearable}
                label={filter.label}
                placeholder={`${filter.placeholder || filter.label}...`}
                onChange={(option) =>
                  setSearchQuery({
                    [filter.id]: filter.isMulti ? option : option?.value,
                    [`${filter.id}_select_option`]: option,
                  })
                }
                value={searchQuery[`${filter.id}_select_option`]}
                options={filter.options}
                onMenuOpen={() => setIsOpen(true)}
              />
            </SelectContainer>
          ) : null}
          {filter.type === 'dateRange' ? (
            <DateRangeSelector
              label={filter.label}
              startDate={searchQuery[filter.startDateId]}
              endDate={searchQuery[filter.endDateId]}
              setStartDate={(value) =>
                setSearchQuery({ [filter.startDateId]: value })
              }
              setEndDate={(value) =>
                setSearchQuery({ [filter.endDateId]: value })
              }
              showTimeSelect={filter.showTime}
            />
          ) : null}
          {filter.type === 'asyncSelect' ? (
            <SelectContainer className={filter.className}>
              <AsyncSelect
                isClearable={filter.isClearable}
                isLoading={filter.isLoading}
                label={filter.label}
                placeholder={`${filter.placeholder || filter.label}...`}
                onChange={(option) =>
                  setSearchQuery({
                    [filter.id]: option?.value,
                    [`${filter.id}_select_option`]: option,
                  })
                }
                value={searchQuery[`${filter.id}_select_option`]}
                loadFunc={filter.loadFunc}
                onMenuOpen={() => setIsOpen(true)}
              />
            </SelectContainer>
          ) : null}
          {filter.type === 'button' ? (
            <SelectContainer className={filter.className}>
              <Button classes="no-min" onClick={filter.onClick}>
                {filter.label}
              </Button>
            </SelectContainer>
          ) : null}
        </Fragment>
      ))}
    </CollapsibleFilters>
  );
};

export default Filters;
