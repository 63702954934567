export const tableColumns = [
  {
    id: 'id',
    label: 'Id',
    maxWidth: 80,
  },
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'follow_count',
    label: 'Follows',
  },
  {
    id: 'push_notifications_count',
    label: 'Push notifications',
  },
  {
    id: 'email_notifications_count',
    label: 'Email notifications',
  },
];
