import moment from 'moment';

const ascSymbol = '\u2191';
const descSymbol = '\u2193';

export const orders = [
  { label: `${descSymbol} Creation`, value: 'creation_date_desc' },
  { label: `${ascSymbol} Creation`, value: 'creation_date_asc' },
  { label: `${descSymbol} Publication`, value: 'publication_date_desc' },
  { label: `${ascSymbol} Publication`, value: 'publication_date_asc' },
];

export const statuses = [
  { label: 'Published', value: 'published' },
  { label: 'Unpublished', value: 'unpublished' },
  { label: 'Rejected', value: 'rejected' },
  { label: 'Deleted', value: 'deleted' },
];

export const tableColumns = [
  {
    id: 'id',
    label: 'Id',
    maxWidth: 80,
  },
  {
    id: 'creation_date',
    label: 'Creation date',
    format: (value) => moment(value).format('LLL').toString(),
    excelFormat: 'dd-mm-yyyy hh:mm',
    maxWidth: 120,
  },
  {
    id: 'publication_date',
    label: 'Publication date',
    format: (value) => (value ? moment(value).format('LLL').toString() : '-'),
    excelFormat: 'dd-mm-yyyy hh:mm',
    maxWidth: 120,
  },
  {
    id: 'title',
    label: 'Title',
    className: 'text-cut',
    toolTip: true,
  },
  {
    id: 'content_container_name',
    label: 'Content container',
    maxWidth: 200,
  },
  {
    id: 'status',
    label: 'Status',
    maxWidth: 100,
  },
  {
    id: 'publication_type',
    label: 'Publication type',
    maxWidth: 130,
  },
  {
    id: 'author',
    label: 'Author',
    maxWidth: 130,
  },
];

export const detailsFields = [
  {
    id: 'id',
  },
  {
    id: 'creation_date',
  },
  {
    id: 'publication_date',
  },
  {
    id: 'title',
  },
  {
    id: 'content_container_name',
  },
  {
    id: 'status',
  },
  {
    id: 'publication_type',
  },
  {
    id: 'author',
  },
];

export const defaultSearchQuery = {
  creation_date_from: moment().startOf('day').subtract(1, 'month').toDate(),
  creation_date_to: moment().endOf('day').toDate(),
  order: 'creation_date_desc',
  order_select_option: orders[0],
  container: '',
};

export const filtersFunc = (
  filterContentContainers,
  isLoadingContentContainers,
) => [
  {
    startDateId: 'creation_date_from',
    endDateId: 'creation_date_to',
    label: 'Creation date',
    showTime: false,
    type: 'dateRange',
  },
  {
    startDateId: 'publication_date_from',
    endDateId: 'publication_date_to',
    label: 'Publication date',
    showTime: false,
    type: 'dateRange',
  },
  {
    id: 'order',
    label: 'Order',
    type: 'select',
    options: orders,
    isClearable: false,
  },
  {
    id: 'status',
    label: 'Status',
    type: 'select',
    options: statuses,
    isClearable: false,
  },
  {
    id: 'container',
    label: 'Content container',
    type: 'asyncSelect',
    loadFunc: filterContentContainers,
    isLoading: isLoadingContentContainers,
    isClearable: false,
  },
];
