import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {
  DateRangeSelectorContainer,
  Label,
  Wrapper,
} from '@components/DateRangeSelector/styles';

const DateRangeSelector = ({
  label,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  maxDate,
  minDate,
  showTimeSelect,
}) => (
  <DateRangeSelectorContainer>
    {label ? <Label>{label}</Label> : null}
    <Wrapper showTime={showTimeSelect}>
      <DatePicker
        showTimeSelect={showTimeSelect}
        dateFormat={showTimeSelect ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd'}
        placeholderText="From..."
        selected={startDate}
        onChange={(date) =>
          setStartDate(date === null ? moment().toDate() : date)
        }
        selectsStart
        startDate={startDate}
        endDate={endDate}
        minDate={minDate}
      />
      <DatePicker
        showTimeSelect={showTimeSelect}
        dateFormat={showTimeSelect ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd'}
        placeholderText="To..."
        selected={endDate}
        onChange={(date) =>
          setEndDate(date === null ? moment().toDate() : date)
        }
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        maxDate={maxDate}
      />
    </Wrapper>
  </DateRangeSelectorContainer>
);

export default DateRangeSelector;
