import moment from 'moment';

export const defaultSearchQuery = {
  created_at_from: moment().subtract(3, 'month').toDate(),
  created_at_to: moment().toDate(),
};

export const tableColumns = [
  {
    id: 'id',
    label: 'Id',
    maxWidth: 80,
  },
  {
    id: 'created_at',
    label: 'Created at',
    format: (value) => moment(value).format('LLL').toString(),
    hide: true,
    excelFormat: 'dd-mm-yyyy hh:mm',
  },
  {
    id: 'published_at',
    label: 'Publication date',
    format: (value) =>
      value === 'not published'
        ? value
        : moment(value).format('LLL').toString(),
    excelFormat: 'dd-mm-yyyy hh:mm',
  },
  {
    id: 'analyst',
    label: 'Analyst',
  },
  {
    id: 'source',
    label: 'Source',
  },
  {
    id: 'article_url',
    label: 'Article URL',
    className: 'text-cut',
  },
];

export const detailsFields = [
  { id: 'title' },
  { id: 'body' },
  { id: 'created_at' },
  { id: 'updated_at' },
  { id: 'author' },
  {
    id: 'rich_text_in_body',
    format: (value) => (value === true ? 'Yes' : 'No'),
  },
  { id: 'analyst_sentiment' },
  { id: 'content_type' },
  { id: 'url' },
  { id: 'article_id' },
  { id: 'acast_url' },
  { id: 'spotify_url' },
  { id: 'apple_music_url' },
];

export const filtersFunc = (analysts, isLoadingAnalysts) => [
  {
    startDateId: 'created_at_from',
    endDateId: 'created_at_to',
    label: 'Creation date',
    showTime: false,
    type: 'dateRange',
  },
  {
    startDateId: 'published_at_from',
    endDateId: 'published_at_to',
    label: 'Publication date',
    showTime: false,
    type: 'dateRange',
  },
  {
    id: 'analyst_id',
    label: 'Analyst',
    type: 'select',
    options: analysts,
    isLoading: isLoadingAnalysts,
    isClearable: false,
  },
];
