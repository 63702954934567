import React, { useEffect, useMemo, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { ContentWrapper } from '@styles/globalStyles';
import Preloader from '@components/Preloader';
import { ChartContainer } from '@containers/Users/styles';
import LineChart from '@components/LineChart';
import Filters from '@components/Filters';
import {
  defaultSearchQuery,
  excelColumns,
  filtersFunc,
  keys,
} from '@containers/AnalystsStatistics/constants';
import ExcelDownload from '@components/ExcelDownload';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';

const AnalystsStatistics = ({
  analystsStatisticsStore: {
    isLoading,
    isLoadingAnalysts,
    analysts,
    chartData,
    fetchPublicationsCount,
    fetchAnalysts,
    getAnalystName,
  },
}) => {
  const [searchQuery, setSearchQuery] =
    useCustomSearchParams(defaultSearchQuery);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  useEffect(() => {
    fetchAnalysts();
    if (searchQuery.analystId) {
      search(searchQuery);
    }
  }, []);

  const search = async (searchQueryValue) => {
    await fetchPublicationsCount({ ...searchQuery, ...searchQueryValue });
  };

  const filters = filtersFunc(analysts, isLoadingAnalysts);
  const analystName = useMemo(() => {
    if (searchQuery.analystId) {
      return getAnalystName(searchQuery.analystId);
    }
    return null;
  }, [searchQuery.analystId]);

  return (
    <ContentWrapper>
      {isLoading && <Preloader overlay />}
      <Filters
        clear={() => {}}
        search={search}
        searchQuery={searchQuery}
        setSearchQuery={(valueObj) => {
          setSearchQuery({ ...searchQuery, ...valueObj });
        }}
        isOpen={isFiltersOpen}
        setIsOpen={setIsFiltersOpen}
        filters={filters}
        containerClass="no-margin"
        autoFilter
      />
      {searchQuery.analystId && chartData.length ? (
        <ChartContainer>
          <LineChart data={chartData} keys={keys} xAxisKey="date" />
        </ChartContainer>
      ) : null}
      {chartData.length && analystName ? (
        <ExcelDownload
          buttonDisabled={isLoading}
          name={`Statistics for ${analystName || '(no analyst name)'}`}
          data={chartData}
          keys={excelColumns}
        />
      ) : null}
    </ContentWrapper>
  );
};

export default inject('analystsStatisticsStore')(observer(AnalystsStatistics));
