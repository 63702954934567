import { makeAutoObservable } from 'mobx';
import moment from 'moment';
import apiRoutes from '@app/apiRoutes';
import API from '@app/api';
import { log, TYPE } from '@utils/logger';

export class ArticleAggregatesStore {
  isLoadingKeys = false;

  isLoadingChart = false;

  keys = [];

  chartData = [];

  chartKeys = [];

  constructor() {
    makeAutoObservable(this);
  }

  fetchDistinctKeys = async ({ fromDate, toDate, type }) => {
    this.isLoadingKeys = true;
    this.keys = [];

    const query = {
      fromDate: moment(fromDate).toISOString(),
      toDate: moment(toDate).toISOString(),
      type,
    };

    try {
      const {
        data: { data },
      } = await API.get(apiRoutes.articleAggregates.distinctSources, {
        params: query,
      });

      this.keys = data.map((key) => ({ label: key, value: key }));
    } catch (e) {
      log(e.message, TYPE.ERROR);
    } finally {
      this.isLoadingKeys = false;
    }
  };

  fetchAggregates = async ({ fromDate, toDate, type, keys }) => {
    this.isLoadingChart = true;

    const keysValues = keys.map(({ value }) => value);

    const query = {
      fromDate: moment(fromDate).toISOString(),
      toDate: moment(toDate).toISOString(),
      type,
      keys: keysValues.join(','),
    };

    try {
      const {
        data: { data: aggregate },
      } = await API.get(apiRoutes.articleAggregates.chart, { params: query });

      this.chartData = this.prepareData(
        aggregate,
        keysValues,
        fromDate,
        toDate,
      );

      this.isLoadingChart = false;
    } catch (e) {
      log(e.message, TYPE.ERROR);
    }
  };

  // eslint-disable-next-line class-methods-use-this
  prepareData = (data, keys, startDate, endDate) => {
    const result = [];

    let count = moment(endDate).diff(moment(startDate), 'months');
    const emptyKeysObj = {};
    keys.forEach((key) => {
      emptyKeysObj[key] = 0;
    });

    while (count >= 0) {
      const date = moment(endDate)
        .subtract(count, 'month')
        .format('YYYY-MM')
        .toString();
      if (data[date]) {
        result.push({ ...emptyKeysObj, ...data[date] });
      } else {
        result.push({
          date: date.toString(),
          ...emptyKeysObj,
        });
      }
      count -= 1;
    }

    return result;
  };
}

export default new ArticleAggregatesStore();
