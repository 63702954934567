import React from 'react';
import { useTheme } from '@emotion/react';
import { Box, Container, Text } from '@components/IconWithText/style';
import useBreakpoint from '@root/hooks/useBreakpoint';

const IconWithText = ({ icon: Icon, mainText, text }) => {
  const theme = useTheme();
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);
  const size = isMobile ? 50 : 60;
  return (
    <Container>
      <Icon
        width={size}
        height={size}
        stroke={theme.font.default}
        strokeWidth={1}
      />
      <Box>
        <Text className="main">{mainText}</Text>
        <Text>{text}</Text>
      </Box>
    </Container>
  );
};
export default IconWithText;
