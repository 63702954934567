import React, { useEffect, useMemo, useState } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment/moment';
import { ContentWrapper, SubTitle } from '@styles/globalStyles';
import { ChartContainer } from '@containers/Users/styles';
import LineChart from '@components/LineChart';
import Filters from '@components/Filters';
import {
  defaultSearchQuery,
  filtersFunc,
  subtitles,
} from '@containers/ArticleAggregates/constants';
import { CHART_COLORS } from '@root/constants/common';
import Preloader from '@components/Preloader';
import ExcelDownload from '@components/ExcelDownload';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';
import Text, { TAGS } from '@components/Text';

const ArticleAggregates = ({
  articleAggregatesStore: {
    fetchDistinctKeys,
    isLoadingKeys,
    chartData,
    fetchAggregates,
    keys,
    isLoadingChart,
  },
}) => {
  const [searchQuery, setSearchQuery] =
    useCustomSearchParams(defaultSearchQuery);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const search = async (searchQueryValue) => {
    if (searchQueryValue.type && !searchQueryValue.keys) {
      fetchDistinctKeys({ ...searchQuery, ...searchQueryValue });
      setSearchQuery({ ...searchQuery, ...searchQueryValue, keys: [] });
    }

    if (searchQueryValue.keys && searchQueryValue.keys.length) {
      await fetchAggregates({ ...searchQuery, ...searchQueryValue });
    }
  };

  const filters = filtersFunc(searchQuery.type, keys, isLoadingKeys);

  const chartKeys = useMemo(() => {
    if (searchQuery.keys) {
      return searchQuery.keys.map(({ label }, index) => ({
        key: label,
        label,
        color: CHART_COLORS[index % 33],
        show: true,
      }));
    }
    return [];
  }, [searchQuery]);

  useEffect(() => {
    search(searchQuery);
  }, []);

  useEffect(() => {
    setSearchQuery({ ...searchQuery, keys: [], keys_select_option: null });
  }, [searchQuery.type]);

  return (
    <ContentWrapper>
      <Filters
        clear={() => {}}
        search={search}
        searchQuery={searchQuery}
        setSearchQuery={(valueObj) => {
          setSearchQuery({ ...searchQuery, ...valueObj });
        }}
        isOpen={isFiltersOpen}
        setIsOpen={setIsFiltersOpen}
        filters={filters}
        containerClass="no-margin"
        autoFilter
      />
      {isLoadingChart && <Preloader overlay />}
      {searchQuery.type ? (
        <SubTitle>
          <Text tag={TAGS.H2}>{subtitles[searchQuery.type]}</Text>
        </SubTitle>
      ) : null}
      {searchQuery.keys && searchQuery.keys.length && chartData.length ? (
        <ChartContainer>
          <LineChart
            tickFormatter={(value) =>
              moment(value).format('MM-YYYY').toString()
            }
            data={chartData}
            keys={chartKeys}
            xAxisKey="date"
            yTickFormatter={(value) => `${value}%`}
          />
        </ChartContainer>
      ) : null}
      {searchQuery.keys && searchQuery.keys.length && chartData.length ? (
        <ExcelDownload
          name="Article aggregates"
          data={chartData}
          keys={[
            { key: 'date', label: 'Date', excelFormat: 'dd-mm-yyyy hh:mm' },
            ...chartKeys,
          ]}
        />
      ) : null}
    </ContentWrapper>
  );
};

export default inject('articleAggregatesStore')(observer(ArticleAggregates));
