import React from 'react';
import { X } from 'tabler-icons-react';
import Dialog from './Dialog';
import { ContentBox, ContentTitle } from './styles';

function ContentDialog({
  value,
  title,
  isOpen,
  closeHandler,
  smallModal,
  backdropClass,
}) {
  return (
    <Dialog
      isOpen={isOpen}
      closeHandler={closeHandler}
      smallModal={smallModal}
      backdropClass={backdropClass || 'html-backdrop'}
    >
      <ContentTitle>
        {title}
        <X onClick={closeHandler} />
      </ContentTitle>
      <ContentBox className="text">{value}</ContentBox>
    </Dialog>
  );
}

export default ContentDialog;
