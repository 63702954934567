import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useParams } from 'react-router';
import { SubTitle, ContentWrapper } from '@styles/globalStyles';
import Text, { TAGS } from '@components/Text';
import DetailsMobile from '@components/DetailsMobile';
import Details from '@components/Details';
import useBreakpoint from '@root/hooks/useBreakpoint';
import Preloader from '@components/Preloader';
import { detailsFields } from '@containers/NlaUsageAggregates/constants';

const NlaAggregate = ({
  nlaAggregatesStore: {
    nlaAggregate,
    fetchNlaAggregate,
    isLoadingNlaAggregate,
    clearNlaAggregate,
  },
}) => {
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchNlaAggregate(id);
    }
    return () => clearNlaAggregate();
  }, []);

  if (isLoadingNlaAggregate) {
    return <Preloader overlay />;
  }

  return (
    <ContentWrapper>
      <SubTitle>
        <Text tag={TAGS.H2}>Content Publication: {nlaAggregate.id}</Text>
      </SubTitle>
      {isMobile ? (
        <DetailsMobile data={nlaAggregate} fields={detailsFields} big />
      ) : (
        <Details data={nlaAggregate} fields={detailsFields} />
      )}
    </ContentWrapper>
  );
};

export default inject('nlaAggregatesStore')(observer(NlaAggregate));
