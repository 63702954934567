export const tableColumns = [
  {
    id: 'id',
    label: 'Id',
    maxWidth: 80,
  },
  {
    id: 'fetch_type',
    label: 'Type',
    image: true,
    className: 'column--fetch-type-',
    maxWidth: 60,
  },
  {
    id: 'title',
    label: 'Source',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'last_article_date',
    label: 'Last article date (created_at)',
  },
];

export const defaultSearchQuery = {
  title: '',
};

export const filtersFunc = () => [
  {
    id: 'title',
    label: 'Search title',
    type: 'input',
  },
];
