import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { ContentWrapper, SubTitle } from '@styles/globalStyles';
import Filters from '@components/Filters';
import {
  filters,
  defaultSearchQuery,
  tableColumns,
  chartKeys,
  pieChartKeys,
} from '@containers/Dashboard/constants';
import Preloader from '@components/Preloader';
import Table from '@components/Table';
import { ChartContainer } from '@containers/Users/styles';
import LineChart from '@components/LineChart';
import PieChart from '@components/PieChart';
import useBreakpoint from '@root/hooks/useBreakpoint';
import { LinkContainer, LinkButton } from '@containers/Dashboard/styles';
import routes from '@routes';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';
import Text, { TAGS } from '@components/Text';

const Dashboard = ({
  dashboardStore: {
    fetchTraffic,
    trafficData,
    recentEvents,
    isLoading,
    fetchRecentEvents,
  },
}) => {
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] =
    useCustomSearchParams(defaultSearchQuery);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  useEffect(() => {
    fetchTraffic(searchQuery);
    fetchRecentEvents(searchQuery);
  }, []);

  const search = (searchQueryValue) => {
    fetchTraffic({ ...searchQuery, ...searchQueryValue });
    fetchRecentEvents({ ...searchQuery, ...searchQueryValue });
  };

  return (
    <ContentWrapper>
      {isLoading ? <Preloader overlay /> : null}
      <Filters
        clear={() => {}}
        search={search}
        searchQuery={searchQuery}
        setSearchQuery={(valueObj) => {
          setSearchQuery({ ...searchQuery, ...valueObj });
        }}
        isOpen={isFiltersOpen}
        setIsOpen={setIsFiltersOpen}
        filters={filters}
        containerClass="no-margin"
        autoFilter
      />
      <SubTitle>
        <Text tag={TAGS.H1}>Daily traffic</Text>
      </SubTitle>
      {trafficData.traffic && trafficData.traffic.length ? (
        <ChartContainer className={isMobile ? '' : 'flex'}>
          <LineChart
            dateFormatTooltip="YYYY-MM-DD HH:mm"
            width={isMobile ? '100%' : '60%'}
            data={trafficData.traffic}
            keys={chartKeys}
            xAxisKey="date"
            tickFormatter={(value) =>
              moment(value).format('YYYY-MM-DD').toString()
            }
          />
          <PieChart
            data={trafficData.devices}
            keys={pieChartKeys}
            width={isMobile ? '100%' : '40%'}
          />
        </ChartContainer>
      ) : null}
      <SubTitle>
        <Text tag={TAGS.H1}>Events</Text>
      </SubTitle>
      <Table columns={tableColumns} data={recentEvents} disablePagination />
      <LinkContainer>
        <LinkButton onClick={() => navigate(routes.users)}>
          See more details...
        </LinkButton>
      </LinkContainer>
    </ContentWrapper>
  );
};

export default inject('dashboardStore')(observer(Dashboard));
