import { makeAutoObservable } from 'mobx';
import moment from 'moment';
import API from '@app/api';
import apiRoutes from '@app/apiRoutes';
import { log, TYPE } from '@utils/logger';

export class AnalystsStatisticsStore {
  isLoading = false;

  isLoadingAnalysts = false;

  analysts = [];

  analystNamesObj = {};

  chartData = [];

  constructor() {
    makeAutoObservable(this);
  }

  getAnalystName = (analystId) =>
    this.analystNamesObj[analystId] || analystId.toString();

  fetchPublicationsCount = async (params) => {
    const { startDate, endDate, analystId } = params;
    this.isLoading = true;
    this.chartData = [];
    const query = {
      from: moment(startDate).toISOString(),
      to: moment(endDate).toISOString(),
      analyst_id: analystId,
    };

    try {
      const {
        data: {
          data: { publications },
        },
      } = await API.get(apiRoutes.analystsStatistics.analystsPublications, {
        params: query,
      });

      this.chartData = this.prepareData(publications, startDate, endDate);
      this.isLoading = false;
    } catch (e) {
      log(e.message, e.message, TYPE.ERROR);
    }
  };

  fetchAnalysts = async () => {
    this.isLoadingAnalysts = true;

    try {
      const {
        data: { analysts },
      } = await API.get(apiRoutes.analystsStatistics.analysts);

      const analystObject = {};
      this.analysts = analysts.map((item) => {
        const label =
          item.first_name && item.last_name
            ? `${item.first_name} ${item.last_name}`
            : item.email;
        analystObject[item.id] = label;
        return {
          value: item.id,
          label,
        };
      });
      this.analystNamesObj = analystObject;
      this.isLoadingAnalysts = false;
    } catch (e) {
      log(e.message, e.message, TYPE.ERROR);
    }
  };

  // eslint-disable-next-line class-methods-use-this
  prepareData = (countOfAnalystPublications, startDate, endDate) => {
    const data = [];

    let count = moment(endDate).diff(moment(startDate), 'days');

    while (count >= 0) {
      const date = moment(endDate)
        .subtract(count, 'days')
        .format('YYYY-MM-DD')
        .toString();
      const publicationsCount = countOfAnalystPublications[date] || 0;
      data.push({
        date,
        publicationsCount,
      });

      count -= 1;
    }
    return data;
  };
}

export default new AnalystsStatisticsStore();
