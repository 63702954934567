import moment from 'moment';

export const defaultSearchQuery = {
  creation_date_from: moment().subtract(3, 'month').toDate(),
  creation_date_to: moment().toDate(),
};

export const tableColumnsFunc = (articleSourcesObj) => [
  {
    id: 'id',
    label: 'Id',
    maxWidth: 80,
  },
  {
    id: 'title',
    label: 'Title',
  },
  {
    id: 'article_sources',
    label: 'Article sources',
    format: (value) =>
      value.map((id) => articleSourcesObj[id] || id).join(', '),
    className: 'text-cut',
    toolTip: true,
  },
  {
    id: 'contact_email',
    label: 'Contact_email',
    hide: true,
    maxWidth: 150,
  },
  {
    id: 'created_at',
    label: 'Created at',
    format: (value) => moment(value).format('LLL').toString(),
    hide: true,
  },
  {
    id: 'updated_at',
    label: 'Updated at',
    format: (value) =>
      value === 'not published'
        ? value
        : moment(value).format('LLL').toString(),
    hide: true,
  },
];

const ascSymbol = '\u2191';
const descSymbol = '\u2193';
export const orders = [
  { label: `${descSymbol} Creation`, value: 'creation_date_desc' },
  { label: `${ascSymbol} Creation`, value: 'creation_date_asc' },
  { label: `${descSymbol} Update`, value: 'update_date_desc' },
  { label: `${ascSymbol} Update`, value: 'update_date_asc' },
];

export const filtersFunc = (articleSources, isLoadingArticleSources) => [
  {
    startDateId: 'creation_date_from',
    endDateId: 'creation_date_to',
    label: 'Creation date',
    showTime: false,
    type: 'dateRange',
  },
  {
    startDateId: 'update_date_from',
    endDateId: 'update_date_to',
    label: 'Update date',
    showTime: false,
    type: 'dateRange',
  },
  {
    id: 'order',
    label: 'Order',
    type: 'select',
    options: orders,
  },
  {
    id: 'title',
    label: 'Title',
    type: 'input',
  },
  {
    id: 'contact_email',
    label: 'Contact email',
    type: 'input',
  },
  {
    id: 'article_source',
    label: 'Article source',
    type: 'select',
    options: articleSources,
    isLoading: isLoadingArticleSources,
  },
];
