import { makeAutoObservable } from 'mobx';
import moment from 'moment';
import API from '@app/api';
import apiRoutes from '@app/apiRoutes';

export class ContentPublicationsStore {
  contentPublications = [];

  contentPublicationsCount = 0;

  contentPublication = {};

  isLoading = false;

  contentContainers = [];

  isLoadingContentContainers = false;

  excelData = [];

  isLoadingExcel = false;

  constructor() {
    makeAutoObservable(this);
  }

  fetchContentPublication = async (id) => {
    this.isLoading = true;
    try {
      const {
        data: { report },
      } = await API.get(apiRoutes.contentPublications.show, {
        params: { id },
      });
      [this.contentPublication] = report;
    } catch (e) {
      this.error = e;
    } finally {
      this.isLoading = false;
    }
  };

  fetchContentPublications = async ({ limit, page, searchQuery, excel }) => {
    this.isLoading = !excel;
    this.isLoadingExcel = excel;
    const extendedQuery = {
      publication_date_from: searchQuery.creation_date_from,
      publication_date_to: moment().endOf('day').toDate(),
      ...searchQuery,
    };

    let query;
    if (excel) {
      query = {
        limit: 1000,
        page: 0,
        ...extendedQuery,
      };
    } else {
      query = {
        limit: parseInt(limit ? limit.toString() : 10, 10),
        page: parseInt(page || 0, 10),
        publication_date_from: null,
        publication_date_to: null,
        ...extendedQuery,
      };
    }

    try {
      const {
        data: { report, count },
      } = await API.get(apiRoutes.contentPublications.index, {
        params: query,
      });
      if (excel) {
        this.excelData = report;
      } else {
        this.contentPublications = report;
        this.contentPublicationsCount = count;
        this.excelData = [];
      }
    } catch (e) {
      this.error = e;
    } finally {
      this.isLoading = false;
      this.isLoadingExcel = false;
    }
  };

  fetchContentContainers = async () => {
    this.isLoadingContentContainers = true;
    try {
      const {
        data: { containers },
      } = await API.get(apiRoutes.contentPublications.contentContainers);
      this.contentContainers = containers.map((container) => ({
        label: `${container.name} - ${container.c_name} - ${
          container.c_disabled ? 'OFF' : 'ON'
        }`,
        value: container.c_id,
      }));
    } catch (e) {
      this.error = e;
    } finally {
      this.isLoadingContentContainers = false;
    }
  };

  clearContentPublication = () => {
    this.contentPublication = {};
  };

  filterContentContainers = async (inputValue) =>
    inputValue.length > 1
      ? this.contentContainers
          .filter((i) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase()),
          )
          .slice(0, 1000)
      : [];
}

export default new ContentPublicationsStore();
