import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useParams } from 'react-router';
import { detailsFields } from '@containers/ContentReports/constants';
import { SubTitle, ContentWrapper } from '@styles/globalStyles';
import Text, { TAGS } from '@components/Text';
import DetailsMobile from '@components/DetailsMobile';
import Details from '@components/Details';
import useBreakpoint from '@root/hooks/useBreakpoint';
import Preloader from '@components/Preloader';

const ContentReport = ({
  contentReportsStore: {
    contentReport,
    fetchContentReport,
    isLoadingContentReport,
    clearContentReport,
  },
}) => {
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchContentReport(id);
    }
    return () => clearContentReport();
  }, []);

  if (isLoadingContentReport) {
    return <Preloader overlay />;
  }

  return (
    <ContentWrapper>
      <SubTitle>
        <Text tag={TAGS.H2}>Content report: {contentReport.id}</Text>
      </SubTitle>
      {isMobile ? (
        <DetailsMobile data={contentReport} fields={detailsFields} big />
      ) : (
        <Details data={contentReport} fields={detailsFields} />
      )}
    </ContentWrapper>
  );
};

export default inject('contentReportsStore')(observer(ContentReport));
