import React, { useState } from 'react';
import { TabsList, StyledTab } from '@components/Tabs/styles';

export const Tab = ({ children, tabNumber, chosenTab, onClick, tabWidth }) => {
  const className = tabNumber === chosenTab ? 'selected' : '';
  return (
    <StyledTab
      tabWidth={tabWidth}
      className={className}
      onClick={() => onClick(tabNumber)}
    >
      {children}
    </StyledTab>
  );
};

export const TabContent = ({ tabNumber, chosenTab, children }) =>
  tabNumber === chosenTab ? children : null;

const Tabs = ({ tabs }) => {
  const [chosenTab, setChosenTab] = useState(0);
  const tabWidth = 100 / tabs.length;
  return (
    <>
      <TabsList>
        {tabs.map(({ label }, index) => (
          <Tab
            key={`tab-${index}`}
            tabWidth={tabWidth}
            tabNumber={index}
            chosenTab={chosenTab}
            onClick={setChosenTab}
          >
            {label}
          </Tab>
        ))}
      </TabsList>
      {tabs.map(({ content }, index) => (
        <TabContent
          key={`tabcontent-${index}`}
          tabNumber={index}
          chosenTab={chosenTab}
        >
          {content}
        </TabContent>
      ))}
    </>
  );
};

export default Tabs;
