import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useParams } from 'react-router';
import { detailsFields } from '@containers/SummaryReports/constants';
import { SubTitle, ContentWrapper } from '@styles/globalStyles';
import Text, { TAGS } from '@components/Text';
import DetailsMobile from '@components/DetailsMobile';
import Details from '@components/Details';
import useBreakpoint from '@root/hooks/useBreakpoint';
import Preloader from '@components/Preloader';

const SummaryReport = ({
  summaryReportsStore: {
    summaryReport,
    fetchSummaryReport,
    isLoadingSummaryReport,
    clearSummaryReport,
  },
}) => {
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchSummaryReport(id);
    }
    return () => clearSummaryReport();
  }, []);

  if (isLoadingSummaryReport) {
    return <Preloader overlay />;
  }

  return (
    <ContentWrapper>
      <SubTitle>
        <Text tag={TAGS.H2}>Summary report: {summaryReport.id}</Text>
      </SubTitle>
      {isMobile ? (
        <DetailsMobile data={summaryReport} fields={detailsFields} big />
      ) : (
        <Details data={summaryReport} fields={detailsFields} />
      )}
    </ContentWrapper>
  );
};

export default inject('summaryReportsStore')(observer(SummaryReport));
