import React from 'react';
import { Container } from '@components/StatsIcons/styles';
import IconWithText from '@components/IconWithText';

const StatsIcons = ({ counters, data }) => (
  <Container>
    {counters.map(({ icon, id, label }, index) => (
      <IconWithText
        key={`icon-with-text-${index}`}
        icon={icon}
        mainText={data[id]}
        text={label}
      />
    ))}
  </Container>
);

export default StatsIcons;
