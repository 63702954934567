import styled from '@emotion/styled';

export const LegendContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;
  margin-top: 20px;
`;

export const List = styled.ul`
  padding: 0;
  margin: 0;
  text-align: center;
  list-style: none;
  display: inline-block;
`;

export const LegendButton = styled.div`
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 13px;
  cursor: pointer;
  user-select: none;
`;

export const ListItem = styled.li`
  display: inline-block;
  min-width: max-content;
  font-size: 15px;
`;

export const Svg = styled.svg`
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
`;
