import React, { useRef, useEffect, useCallback } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Backdrop, Modal, ModalInner } from '@components/Modal/styles';
import ReactPortal from '@components/ReactPortal';

function Dialog({ children, isOpen, closeHandler, smallModal, backdropClass }) {
  const nodeRef = useRef(null);
  useEffect(() => {
    const closeOnEscapeKey = (e) =>
      e.key === 'Escape' ? closeHandler() : null;
    document.body.addEventListener('keydown', closeOnEscapeKey);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey);
    };
  }, [closeHandler]);

  const clickingOnModalHandler = useCallback((event) => {
    event.stopPropagation();
  }, []);

  return (
    <ReactPortal>
      <CSSTransition
        in={isOpen}
        timeout={300}
        classNames="modal"
        ref={nodeRef}
        unmountOnExit
      >
        <Backdrop onClick={closeHandler} className={backdropClass}>
          <Modal
            onClick={clickingOnModalHandler}
            ref={nodeRef}
            className="modal"
            smallModal={smallModal}
            height={window.innerHeight}
          >
            <ModalInner smallModal={smallModal}>{children}</ModalInner>
          </Modal>
        </Backdrop>
      </CSSTransition>
    </ReactPortal>
  );
}

export default Dialog;
