import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { ContentWrapper } from '@styles/globalStyles';
import Filters from '@components/Filters';
import useBreakpoint from '@root/hooks/useBreakpoint';
import List from '@components/List';
import Preloader from '@components/Preloader';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';
import { SentimentalListWrapper } from './styles';
import { defaultSearchQuery, fields, filtersFunc } from './constants';

const Sentiments = ({
  sentimentsStore: {
    all,
    isLoadingAll,
    fetchAll,
    totalCount,
    fetchCompanies,
    fetchArticleSources,
  },
}) => {
  const [page, setPage] = useState(1);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [searchQuery, setSearchQuery] =
    useCustomSearchParams(defaultSearchQuery);

  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  const search = async (newPage = 1, clear = false) => {
    const query = clear ? defaultSearchQuery : searchQuery;
    if (clear) {
      setSearchQuery(query);
    }
    setPage(newPage);
    await fetchAll({
      page: newPage,
      searchQuery: query,
    });
  };

  useEffect(() => {
    search(1);
  }, []);

  const filters = filtersFunc(fetchCompanies, fetchArticleSources);

  return (
    <ContentWrapper>
      {isLoadingAll ? <Preloader overlay /> : null}
      <Filters
        clear={() => search(1, true)}
        search={search}
        searchQuery={searchQuery}
        setSearchQuery={(valueObj) =>
          setSearchQuery({ ...searchQuery, ...valueObj })
        }
        isOpen={isFiltersOpen}
        setIsOpen={setIsFiltersOpen}
        filters={filters}
        containerClass="small-margin"
      />
      <SentimentalListWrapper>
        <List
          isMobile={isMobile}
          data={all}
          fields={fields}
          currentPage={page}
          dataCount={totalCount}
          pageSize={20}
          onPageChange={search}
          listTopOffset={100}
        />
      </SentimentalListWrapper>
    </ContentWrapper>
  );
};

export default inject('sentimentsStore')(observer(Sentiments));
