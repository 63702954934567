import styled from '@emotion/styled';

export const GraphContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh - 200px);
`;

export const LegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-left: 10px;
  gap: 10px;
  overflow: scroll;
  width: 250px;
`;

export const LegendItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  color: ${({ theme, color }) => color || theme.font.default};
`;

export const Circle = styled.div`
  height: 25px;
  width: 25px;
  min-width: 25px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  display: inline-block;
`;
