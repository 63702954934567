import React from 'react';
import { Container, List, ListItem } from '@components/PieChartTooltip/style';

const CustomPieTooltip = ({ labels, chartData, colors, separator }) => (
  <Container>
    <List>
      {chartData &&
        chartData.map((record) => {
          if (record.total !== undefined) {
            return (
              <ListItem
                key={`custom-tooltip_${record.name}`}
                color={colors[record.name]}
              >
                {labels[record.name] || record.name}
                {separator.trim()} {record.total}
              </ListItem>
            );
          }
          return null;
        })}
    </List>
  </Container>
);

export default CustomPieTooltip;
