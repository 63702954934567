import { makeAutoObservable } from 'mobx';
import API from '@app/api';
import apiRoutes from '@app/apiRoutes';
import { log, TYPE } from '@utils/logger';

export class NlaAggregatesStore {
  isLoadingTable = false;

  tableData = [];

  tableDataCount = 0;

  isLoadingNlaAggregate = false;

  nlaAggregate = {};

  excelData = [];

  isLoadingExcel = false;

  constructor() {
    makeAutoObservable(this);
  }

  fetchNlaAggregate = async (id) => {
    this.isLoadingNlaAggregate = true;
    try {
      const {
        data: { data },
      } = await API.get(apiRoutes.nlaAggregates.show, {
        params: { id },
      });

      this.nlaAggregate = data;
    } catch (e) {
      log(e.message, e, TYPE.ERROR);
    } finally {
      this.isLoadingNlaAggregate = false;
    }
  };

  fetchTable = async ({ limit, page, searchQuery, excel }) => {
    this.isLoadingTable = !excel;
    this.isLoadingExcel = excel;

    let query;
    if (excel) {
      query = {
        limit: 1000,
        skip: 0,
        ...searchQuery,
      };
    } else {
      query = {
        limit: parseInt(limit ? limit.toString() : 10, 10),
        skip: parseInt(page ? (page * limit).toString() : 0, 10),
        ...searchQuery,
      };
    }

    try {
      const {
        data: { data },
      } = await API.get(apiRoutes.nlaAggregates.index, {
        params: query,
      });
      if (excel) {
        this.excelData = data.nla_usage_aggregates;
      } else {
        this.tableData = data.nla_usage_aggregates;
        this.tableDataCount = data.nla_usage_aggregates_count;
        this.excelData = [];
      }
    } catch (e) {
      log(e.message, e, TYPE.ERROR);
    } finally {
      this.isLoadingTable = false;
      this.isLoadingExcel = false;
    }
  };

  clearNlaAggregate = () => {
    this.nlaAggregate = {};
  };
}

export default new NlaAggregatesStore();
