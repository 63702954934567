import React from 'react';
import { inject, observer } from 'mobx-react';
import { ContentWrapper } from '@styles/globalStyles';
import Tabs from '@components/Tabs';
import { tabs } from '@containers/CompaniesRelations/constants';

const CompaniesRelations = () => (
  <ContentWrapper>
    <Tabs tabs={tabs} />
  </ContentWrapper>
);

export default inject('companiesRelationsStore')(observer(CompaniesRelations));
