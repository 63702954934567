import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useParams } from 'react-router';
import { SubTitle, ContentWrapper } from '@styles/globalStyles';
import Text, { TAGS } from '@components/Text';
import DetailsMobile from '@components/DetailsMobile';
import Details from '@components/Details';
import useBreakpoint from '@root/hooks/useBreakpoint';
import Preloader from '@components/Preloader';
import { detailsFields } from '@containers/ContentPublications/constants';

const ContentPublication = ({
  contentPublicationsStore: {
    contentPublication,
    fetchContentPublication,
    isLoading,
    clearContentPublication,
  },
}) => {
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchContentPublication(id);
    }
    return () => clearContentPublication();
  }, []);

  if (isLoading) {
    return <Preloader overlay />;
  }

  return (
    <ContentWrapper>
      <SubTitle>
        <Text tag={TAGS.H2}>Content Publication: {contentPublication.id}</Text>
      </SubTitle>
      {isMobile ? (
        <DetailsMobile data={contentPublication} fields={detailsFields} big />
      ) : (
        <Details data={contentPublication} fields={detailsFields} />
      )}
    </ContentWrapper>
  );
};

export default inject('contentPublicationsStore')(observer(ContentPublication));
