import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { ContentWrapper } from '@styles/globalStyles';
import Table from '@components/Table';
import Preloader from '@components/Preloader';
import Filters from '@components/Filters';
import {
  filters,
  tableColumns,
  defaultSearchQuery,
} from '@containers/NlaUsageAggregates/constants';
import routes from '@routes';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';

const NlaUsageAggregates = ({
  nlaAggregatesStore: {
    fetchTable,
    tableData,
    isLoadingTable,
    tableDataCount,
    excelData,
    isLoadingExcel,
  },
}) => {
  const [page, setPage] = useState(0);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [searchQuery, setSearchQuery, getCleanSearchQuery] =
    useCustomSearchParams(defaultSearchQuery);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const navigate = useNavigate();

  const columnActions = {
    id: (row) => navigate(routes.nlaAggregates.show(row.id)),
  };

  useEffect(() => {
    handleChangePage(0);
  }, []);

  const handleChangePage = async (newPage) => {
    setPage(newPage);
    await fetchTable({
      limit: rowsPerPage,
      page: newPage,
      searchQuery: getCleanSearchQuery(),
    });
  };

  const handleChangeRowsPerPage = async (value) => {
    setRowsPerPage(value);
    setPage(0);
    await fetchTable({
      limit: value,
      page: 0,
      searchQuery: getCleanSearchQuery(),
    });
  };

  const clear = async () => {
    setSearchQuery(defaultSearchQuery);
    setPage(0);
    await fetchTable({
      limit: rowsPerPage,
      page: 0,
      searchQuery: defaultSearchQuery,
    });
  };

  return (
    <ContentWrapper>
      {isLoadingTable && <Preloader overlay />}
      <Filters
        clear={clear}
        search={() => handleChangePage(0)}
        searchQuery={searchQuery}
        setSearchQuery={(valueObj) => {
          setSearchQuery({ ...searchQuery, ...valueObj });
        }}
        isOpen={isFiltersOpen}
        setIsOpen={setIsFiltersOpen}
        filters={filters}
        containerClass="no-margin"
      />
      <Table
        count={tableDataCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        columns={tableColumns}
        data={tableData}
        columnActions={columnActions}
        withExcel
        excelProps={{
          excelData,
          isLoading: isLoadingExcel,
          excelFileName: 'NlaUsageReport',
          onExcelDownload: () => fetchTable({ searchQuery, excel: true }),
        }}
      />
    </ContentWrapper>
  );
};

export default inject('nlaAggregatesStore')(observer(NlaUsageAggregates));
