import React, { useState, useEffect, useCallback } from 'react';
import { inject, observer } from 'mobx-react';
import { ContentWrapper, SubTitle } from '@styles/globalStyles';
import Table from '@components/Table';
import Preloader from '@components/Preloader';
import Filters from '@components/Filters';
import {
  tableColumns,
  filtersFunc,
  defaultSearchQuery,
} from '@containers/SourcesAttachedToPhrases/constants';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';
import Text from '@components/Text';

const SourcesAttachedToPhrases = ({
  sourcesAttachedToPhrasesStore: {
    fetchTable,
    tableData,
    isLoadingTable,
    clearTableData,
    resultText,
    tableDataCount
  },
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const [searchQuery, setSearchQuery] =
    useCustomSearchParams(defaultSearchQuery);

  useEffect(() => {
    setSearchQuery(defaultSearchQuery);
    clearTableData();
    setRowsPerPage(15);
    setPage(0);
  }, []);

  const handleChangePage = async (newPage) => {
    setPage(newPage);
    fetchTable({
      search_phrase_id: parseInt(searchQuery.search_phrase_id, 10),
      page: newPage,
      limit: rowsPerPage,
    });
  };

  const handleChangeRowsPerPage = async (value) => {
    setRowsPerPage(value);
    setPage(0);
    fetchTable({
      search_phrase_id: parseInt(searchQuery.search_phrase_id, 10),
      page: 0,
      limit: value,
    });
  };


  const clear = () => {
    clearTableData();
    setSearchQuery(defaultSearchQuery);
    setPage(0);
  };

  const filters = filtersFunc();

  const fetchDataOnSearch = useCallback(() => {
    fetchTable({
      search_phrase_id: parseInt(searchQuery.search_phrase_id, 10),
      page,
      limit: rowsPerPage,
    });
  }, [searchQuery, rowsPerPage, page]);

  return (
    <ContentWrapper>
      {isLoadingTable && <Preloader overlay />}
      <Filters
        clear={clear}
        search={() => {
          handleChangePage(0);
          fetchDataOnSearch();
        }}
        searchQuery={searchQuery}
        setSearchQuery={(valueObj) =>
          setSearchQuery({ ...searchQuery, ...valueObj })
        }
        isOpen={isFiltersOpen}
        setIsOpen={setIsFiltersOpen}
        filters={filters}
        containerClass="no-margin"
      />
      {tableData.length === 0 ? (
        <SubTitle>
          <Text>{resultText}</Text>
        </SubTitle>
      ) : (
        <Table
          count={tableDataCount}
          rowsPerPageOptions={[15, 50, 100]}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          columns={tableColumns}
          data={tableData}
        />
      )}
    </ContentWrapper>
  );
};

export default inject('sourcesAttachedToPhrasesStore')(
  observer(SourcesAttachedToPhrases),
);
