import React, { useMemo } from 'react';
import { createMatrix } from '@utils/articleSourceMatrix';
import BaseHeatMap from './BaseHeatMap';
import { legendValue } from './HeatMapLegend';

const HeatMap = ({ data = [], label, isLegend }) => {
  const matrix = useMemo(() => createMatrix(data), [data]);

  const colorsForCells = (x, y) => {
    const articlePerHour = matrix[x][y];
    const { color } = legendValue.find(({ val }) => articlePerHour < val);

    return color;
  };

  return (
    <BaseHeatMap
      data={matrix}
      label={label}
      colorsForCells={colorsForCells}
      isLegend={isLegend}
    />
  );
};

export default HeatMap;
