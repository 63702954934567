import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { ContentWrapper } from '@styles/globalStyles';
import Filters from '@components/Filters';
import useBreakpoint from '@root/hooks/useBreakpoint';
import List from '@components/List';
import Preloader from '@components/Preloader';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';
import { defaultSearchQuery, fields, filtersFunc } from './constants';

const archivedArticles = ({
  archivedArticlesStore: { all, isLoadingAll, fetchAll, totalCount },
}) => {
  const [page, setPage] = useState(1);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [searchQuery, setSearchQuery, getCleanSearchQuery] =
    useCustomSearchParams(defaultSearchQuery);

  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  const search = async (newPage = 1, clear = false) => {
    const query = clear ? defaultSearchQuery : getCleanSearchQuery();
    setSearchQuery(query);
    setPage(newPage);
    await fetchAll({
      page: newPage,
      searchQuery: query,
    });
  };

  useEffect(() => {
    search(1, true);
  }, []);

  const filters = filtersFunc();

  return (
    <ContentWrapper>
      {isLoadingAll ? <Preloader overlay /> : null}
      <Filters
        clear={() => search(1, true)}
        search={search}
        searchQuery={searchQuery}
        setSearchQuery={(valueObj) =>
          setSearchQuery({ ...searchQuery, ...valueObj })
        }
        isOpen={isFiltersOpen}
        setIsOpen={setIsFiltersOpen}
        filters={filters}
        containerClass="small-margin"
      />
      <List
        isMobile={isMobile}
        data={all}
        fields={fields}
        currentPage={page}
        dataCount={totalCount}
        pageSize={20}
        onPageChange={search}
        listTopOffset={100}
      />
    </ContentWrapper>
  );
};

export default inject('archivedArticlesStore')(observer(archivedArticles));
