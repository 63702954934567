import React, { useState } from 'react';
import moment from 'moment';
import { Mail, MailOpened } from 'tabler-icons-react';
import HTMLDialog from '@components/Modal/HTMLDialog';
import { ShowContent } from './styles';

const orders = [
  { value: 'desc', label: 'Descending' },
  { value: 'asc', label: 'Ascending' },
];

export const filtersFunc = (fetchEmails) => [
  {
    startDateId: 'from',
    endDateId: 'to',
    showTime: false,
    label: 'Date range',
    type: 'dateRange',
  },
  {
    id: 'order',
    label: 'Order',
    type: 'select',
    options: orders,
  },
  {
    id: 'user_email',
    label: 'Email',
    type: 'asyncSelect',
    loadFunc: fetchEmails,
    className: 'big',
  },
  {
    id: 'title',
    label: 'Title',
    type: 'input',
    className: 'big',
  },
];

export const defaultSearchQuery = {
  from: moment().startOf('day').subtract(1, 'month').toDate(),
  to: moment().endOf('day').toDate(),
  order: 'desc',
  order_select_option: orders[0],
};

export const counters = [
  { id: 'emailsSent', label: 'Emails sent', icon: Mail },
  { id: 'emailsOpened', label: 'Emails opened', icon: MailOpened },
  { id: 'uniqueEmailsOpen', label: 'Unique emails opened', icon: MailOpened },
];

export const fields = [
  { id: '_id' },
  { id: 'action' },
  { id: 'category' },
  { id: 'medium' },
  { id: 'type' },
  { id: 'user_email' },
  { id: 'object_name' },
  { id: 'path' },
  { id: 'title' },
  { id: 'timestamp' },
  { id: 'version' },
  {
    id: 'content',
    format: (value) => {
      const [dialogOpen, setDialogOpen] = useState(false);

      return (
        <ShowContent onClick={() => setDialogOpen(true)}>
          Show email content
          <HTMLDialog
            value={value}
            title="Email content"
            isOpen={dialogOpen}
            closeHandler={() => setDialogOpen(false)}
            backdropClass="email-backdrop"
          />
        </ShowContent>
      );
    },
  },
];
