import React from 'react';
import { Paper } from '@styles/globalStyles';
import { ExportContainer, Title } from '@containers/ArticlesAndClicks/styles';
import DateRangeSelector from '@components/DateRangeSelector';
import ExcelDownload from '@root/components/ExcelDownload';
import Preloader, { POSITIONS } from '@components/Preloader';

const ExportExcel = ({
  isLoading,
  searchQuery,
  setSearchQuery,
  data,
  keys,
  fileName,
  rowKey = 'date',
}) => (
  <Paper>
    <ExportContainer>
      <Title className="right-margin-auto">Export date range</Title>
      <DateRangeSelector
        startDate={searchQuery.startDate}
        endDate={searchQuery.endDate}
        setStartDate={(value) => setSearchQuery({ startDate: value })}
        setEndDate={(value) => setSearchQuery({ endDate: value })}
      />
      {isLoading && <Preloader display={POSITIONS.HORIZONRAL} />}
      <ExcelDownload
        buttonDisabled={isLoading}
        name={fileName}
        data={data}
        keys={keys}
        rowKey={rowKey}
      />
    </ExportContainer>
  </Paper>
);

export default ExportExcel;
