import moment from 'moment';

export const defaultSearchQuery = {
  dateFrom: moment().subtract(1, 'month').toDate(),
  dateTo: moment().toDate(),
};

export const tableColumns = [
  {
    id: 'email',
    label: 'Email',
  },
  {
    id: 'content_orders',
    label: 'Active content orders',
    className: 'cut-text',
    toolTip: true,
  },
  {
    id: 'created_at',
    label: 'Created at',
    format: (value) => moment(value).format('LLL'),
    hide: true,
    excelFormat: 'dd-mm-yyyy hh:mm',
  },
  {
    id: 'current_sign_in_at',
    label: 'Current sign in at',
    format: (value) => (value ? moment(value).format('LLL') : '(no data)'),
    excelFormat: 'dd-mm-yyyy hh:mm',
    hide: true,
  },
  {
    id: 'last_email_opened_at',
    label: 'Last email opened at',
    format: (value) => (value ? moment(value).format('LLL') : '(no data)'),
    excelFormat: 'dd-mm-yyyy hh:mm',
    hide: true,
  },
  {
    id: 'all_email_notifications_enabled',
    label: 'All email notifications enabled',
    format: (value) => (value ? 'on' : 'off'),
  },
  {
    id: 'all_push_notifications_enabled',
    label: 'All push notifications enabled',
    format: (value) => (value ? 'on' : 'off'),
  },
  {
    id: 'emails_sent',
    label: 'Total emails sent',
    maxWidth: 80,
  },
  {
    id: 'emails_opened',
    label: 'Total emails opened',
    maxWidth: 80,
  },
  {
    id: 'unique_emails_opened',
    label: 'Unique emails opened',
    maxWidth: 80,
  },
  {
    id: 'percent_unique_emails',
    label: 'Unique emails opened %',
    format: (value) => parseFloat(value.toFixed()),
    maxWidth: 80,
  },
  {
    id: 'web_opened',
    label: 'Web app opened',
    maxWidth: 80,
  },
  {
    id: 'ios_app_opened',
    label: 'iOS app opened',
    maxWidth: 80,
  },
];

export const filtersFunc = (fetchSubscriptions) => [
  {
    startDateId: 'dateFrom',
    endDateId: 'dateTo',
    label: 'Date range',
    showTime: false,
    type: 'dateRange',
  },
  {
    id: 'subscriptionId',
    label: 'Subscription',
    type: 'asyncSelect',
    className: 'big',
    loadFunc: fetchSubscriptions,
  },
];
