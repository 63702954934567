import React, { useMemo } from 'react';
import { subtractMatrixes } from '@utils/articleSourceMatrix';
import BaseHeatMap from './BaseHeatMap';
import { subLegendValue } from './HeatMapLegend';

const SubtractHeatMap = ({ data, avgData, label }) => {
  const subMatrix = useMemo(
    () => subtractMatrixes(data, avgData),
    [data, avgData],
  );
  const colorsForCells = (x, y) => {
    const subArticles = subMatrix[x][y];

    return subArticles < subLegendValue[0].val
      ? subLegendValue[0].color
      : subLegendValue[1].color;
  };

  return (
    <BaseHeatMap
      label={label}
      data={subMatrix}
      colorsForCells={colorsForCells}
    />
  );
};

export default SubtractHeatMap;
