import styled from '@emotion/styled';

export const MatrixContainer = styled.div`
  margin: 30px 0 0 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
`;

export const MatrixLabel = styled.p`
  padding-left: 8px;
  max-width: 200px;
  font-size: ${({ theme }) => theme.defaults.fontSizeXL}px;
  text-align: center;
  min-height: 50px;
  color: ${({ theme }) => theme.font.default};
`;

export const HeatMapsContainer = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const LegendTableData = styled.td`
  background-color: ${(props) => props.bg};
  text-align: center;
  padding: 3px;
`;

export const LegendTable = styled.table`
  width: 160px;
  border-collapse: separate;
  margin-top: 82px;
  border: 1px solid ${({ theme }) => theme.colors.quinary};
  border-radius: 4px;
`;

export const LegendTableHead = styled.th`
  padding: 3px;
  color: ${({ theme }) => theme.font.default};
`;
