import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { ContentWrapper, Paper } from '@styles/globalStyles';
import Table from '@components/Table';
import Preloader from '@components/Preloader';
import Filters from '@components/Filters';
import {
  filtersFunc,
  tableColumns,
  defaultSearchQuery,
} from '@containers/NotificationReports/constants';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';
import Button from '@components/Button';
import { ExportContainer, Title } from '@containers/ArticlesAndClicks/styles';
import DateRangeSelector from '@components/DateRangeSelector';

const NotificationReports = ({
  notificationReportsStore: {
    fetchTable,
    tableData,
    isLoadingTable,
    fetchSubscriptions,
    emailContractsStatistics,
    isLoadingEmailStatistics,
  },
}) => {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useCustomSearchParams({
    order: 'creation_date_desc',
  });
  const [searchQueryExcel, setSearchQueryExcel] = useState({});

  useEffect(() => {
    search();
  }, []);

  const search = async () => {
    await fetchTable({ searchQuery });
  };

  const clear = async () => {
    setSearchQuery(defaultSearchQuery);
    await fetchTable({
      searchQuery: defaultSearchQuery,
    });
  };

  const filters = filtersFunc(fetchSubscriptions);

  const minDateExcel = moment(searchQueryExcel.endDate)
    .subtract(30, 'days')
    .startOf('day')
    .toDate();
  const maxDateExcel = moment(searchQueryExcel.startDate)
    .add(30, 'days')
    .endOf('day')
    .toDate();

  return (
    <ContentWrapper>
      {isLoadingTable && <Preloader overlay />}
      <Filters
        clear={clear}
        search={() => search(0)}
        searchQuery={searchQuery}
        setSearchQuery={(valueObj) => {
          setSearchQuery({ ...searchQuery, ...valueObj });
        }}
        isOpen={isFiltersOpen}
        setIsOpen={setIsFiltersOpen}
        filters={filters}
        containerClass="no-margin"
      />
      <Paper className="margin-top-10">
        <ExportContainer>
          <Title className="right-margin-auto">Export date range</Title>
          <DateRangeSelector
            minDate={minDateExcel}
            maxDate={maxDateExcel}
            startDate={searchQueryExcel.startDate}
            endDate={searchQueryExcel.endDate}
            setStartDate={(value) =>
              setSearchQueryExcel({ ...searchQueryExcel, startDate: value })
            }
            setEndDate={(value) =>
              setSearchQueryExcel({ ...searchQueryExcel, endDate: value })
            }
          />
          <Button
            loading={isLoadingEmailStatistics}
            onClick={() => emailContractsStatistics(searchQuery)}
          >
            All contracts statistics (email)
          </Button>
        </ExportContainer>
      </Paper>
      <Table
        showHideColumns
        big
        disablePagination
        columns={tableColumns}
        data={tableData}
        withExcel
        excelProps={{
          excelData: tableData,
          isLoading: isLoadingTable,
          excelFileName: 'NotificationsReport',
          onExcelDownload: () => {},
        }}
      />
    </ContentWrapper>
  );
};

export default inject('notificationReportsStore')(
  observer(NotificationReports),
);
